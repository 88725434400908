import "./actionListPage.scss";

import { isPhaseResponse } from "helium-source-repo";
import { anchor, br, div, h1, h3 } from "helium-ui";
import { App } from "MapApp/state/AppState";


export function renderActionListPage() {
	return div("ActionListPage", () => {
		const actions = App.ents.act.getAllPhased();
		if (isPhaseResponse(actions)) {
			return "Loading...";
		}
		return [
			h1(["Actions"]),
			div("ActionItemList cardList", actions.map((act) =>
				anchor("ActionItem cardList-Item cardList-addHoverArrow", {
					href: () => `${App.route.getUrlForRoute("action-details")}/${act.id}`
				},[
					h3([act.title]),
					br(),
					act.briefDesc
				]))
			)
		]
	})
}
