import { PickRequired } from "../PickRequired";

export const LocationUseTypeList = [
	"branch",
	"main-hq",
	"local-hq",
	"general-center",
	"rally-point"
] as const;

export type LocationUseType = typeof LocationUseTypeList[number];


export interface ILocationUse {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	targetId: string;  // org, act
	locationId: string;
	type: LocationUseType;
}

export type ILocationUseArgs = PickRequired<ILocationUse, "targetId" | "type" | "locationId">;
