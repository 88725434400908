import { DotEarthApiPath, ISignInRequest, ISignInResponse, MassTagUseJoinUpdate } from "dot-earth-middleware";
import { deriveAnchorless, noDeps, Source, Sourcify } from "helium-sdx";
import { RepoDataSource } from "helium-source-repo";
import { DotEarthClient } from "../Client";
import { UserEntity } from "../entities";

export class DotEarthApi<USR_ENT extends UserEntity = UserEntity> {
	// public static singleton: DotEarthApi;
	// public static get(): DotEarthApi {
	// 	return this.singleton;
	// }

	// constructor() {
	// 	DotEarthApi.singleton = this as any;
	// }

	constructor() {
		deriveAnchorless(() => {
			const { userId } = this.loginState;
			const user = userId ? DotEarthClient.get().ents.user.get(userId) as any : null;
			this._currentUser.set(user);
		});

		RepoDataSource.MODIFY_HEADERS.push((_, init) => {
			const token = noDeps(() => this.loginState.token);
			token && (init.headers!['Authorization'] = `Bearer ${token}`);
		})
	}

	public paths = DotEarthApiPath;

	public loginState = Sourcify({
		userId: null as string | null,
		token: null as string | null,
	});

	protected _currentUser = new Source<USR_ENT | null>()
	public get currentUser() { return this._currentUser.get(); }

	public hasToken() { return !!this.loginState.token; }

	public static get hostMode() {
		return location.href.includes("boulder.earth") ? "live" : "local";
	}

	public get hostMode() { return DotEarthApi.hostMode; }

	public static get host() {
		if (typeof location === "undefined") {
			console.warn("Using dev server");
			return "http://localhost:3132";
		}
		return this.hostMode === "live" ? "https://dev-api.boulder.earth" : "http://localhost:3132";
	}

	public massTagUseJoinUpdate(body: MassTagUseJoinUpdate) {
		return this.post(this.paths.tagUse.massJoinUpdate, body);
	}

	public url(suffix: string) {
		if (suffix.startsWith("http") === false) {
			suffix = DotEarthApi.host + suffix;
		}
		return suffix;
	}

	public async post<T extends object = object>(url: string, body: any) {
		url = this.url(url);
		const resp = await fetch(url, {
			method: "POST",
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body),
		});
		if (resp.ok === false) {
			throw new Error(await resp.text());
		}
		return resp.json() as T;
	}

	public async signIn(body: ISignInRequest): Promise<ISignInResponse> {
		const resp: ISignInResponse = await this.post("/sign-in", body);
		const { userId, token } = resp;
		if (userId && token) {
			this.loginState.upsert({
				userId,
				token
			});
		}
		return resp;
	}

	public logout() {
		this.loginState.upsert({
			token: null,
			userId: null,
		})
	}
}
