import "./mapApp.scss";

import { div, anchor, Source } from "helium-ui";
import { App } from "./state/AppState";
import { renderActionPage } from "./components/ActionPage/ActionPage";
import { RepoDataSource } from "helium-source-repo";
import { renderOrgPage } from "./components/OrgPage/OrgPage";
import { renderEditModePanel } from "./components/Editors/EditModePanel";
import { buildMapExplorePageKit } from "./components/MapExplorePage/MapExplorePage";
import { renderAdminPage } from "./components/AdminPage/AdminPage";
import { renderSignInPage } from "./components/SignInPage/SignInPage";
import { renderCurrentUserWidget } from "./components/CurrentUser/CurrentUser";
import { renderUserPage } from "./components/UserPage/UserPage";
import { Innards } from "helium-ui";
import { renderRouteNavigator } from "./components/RouteNavigator/RouteNavigator";
import { renderOrgListPage } from "./components/OrgListPage/OrgListPage";
import { renderActionListPage } from "./components/ActionListPage/ActionListPage";


(window)["RobinHood-License for helium-sdx @ 50M/2K"] = "resolved";
(window)["Robinhood-License for helium-sdx"] = "resolved";


RepoDataSource.GLOBAL_CACHE_USE_DEFAULT = { maxAge: 8000 }
// showLoggingType("treeMutations")

// const test = Sourcify<{ a?: any}>({});

// deriveAnchorless(() => {
// 	aLog(test.a)
// });

// deriveAnchorless(() => {
// 	test.set("a", { b: 3});
// });



export function renderMapApp() {
	const sidebarSrc = new Source<Innards>();
	const mainContentSrc = new Source<Innards>();
	const showSidebar = new Source(true);

	return div("MapApp", {
		ddxClass: () => showSidebar.get() && "--showSidebar",
		ddx: () => {
			const routeId = App.route.id;

			const { sidebar, content } = (() => {
				if (routeId === "action-details") {
					if (!App.route.getPathSlice(1)) {
						return { content: renderActionListPage() };
					}
					return { content: renderActionPage() };

				} else if (routeId === "org-details") {
					console.log(App.route.getPathSlice(1));
					if (!App.route.getPathSlice(1)) {
						return { content: renderOrgListPage() };
					}
					return { content: renderOrgPage() }

				} else if (routeId === "sign-in") {
					return { content: renderSignInPage() };

				} else if (routeId === "admin") {
					return { content: renderAdminPage() };

				} else if (routeId.startsWith("map")) {
					const kit = buildMapExplorePageKit();
					return { sidebar: kit.sidebar(), content: kit.contentArea }

				} else if (routeId === "root") {
					return { content: App.route.replaceUrlToRoute("map") };

				} else if (routeId === "user") {
					return { content: renderUserPage() };

				} else {
					return { content: "404" };
				}
			})()

			sidebarSrc.set(sidebar);
			mainContentSrc.set(content);
		}
	},[
		div("MainSidebar",
			div("MainSidebarContainer", {
				onPush: () => showSidebar.set(true),
			},[
				div("PullTabColumn",
					div("PullTab"),
				),
				() => sidebarSrc.get()
			])
		),
		div("MainContent", {
			onPush: () => showSidebar.set(false),
			innards: () => mainContentSrc.get()
		}),
		App.coachMarks.domNode,
		App.modals.domNode,
		// renderLangChooser(),
		renderCurrentUserWidget(),
		renderEditModePanel(),
		renderRouteNavigator(),
	])
}
