import "./map.scss";

import * as mapboxgl from "mapbox-gl";
import { div, derive, noDeps, deriveAnchorless, DerivationManager, showLoggingType, onPush } from "helium-ui";
import { jit } from "helium-source-repo";
// import { App } from "MapApp/state/AppState";
import { Polyline } from "./MapboxPolyline";
import { OrganizationEntity } from "MapApp/state/entities/Organization";
import { LocationEntity, TagEntity } from "dot-earth-client";
import { ConnectionState } from "MapApp/state/ConnectionState";
import { deriveDomAnchored } from "helium-ui";
import { App } from "MapApp/state/AppState";
import { createMarker, ICreateMarkerArgs } from "./createMarker";
import { createPopup, ICreatePopupArgs } from "./createPopup";

export interface IDotEarthMapArgs<T> {
	connections: ConnectionState<T, any>;
	getLoc: (item: T) => LocationEntity | undefined
	createMarker?: ((item: T, connections: ConnectionState<T>) => mapboxgl.Marker)
		| Omit<ICreateMarkerArgs, "location">
	createPopup?: ((item: T, connections: ConnectionState<T>) => mapboxgl.Popup)
		| (
			Omit<ICreatePopupArgs, "location" | "renderPopup"> & {
				renderPopup: (item: T, connections: ConnectionState<T>) => HTMLElement
			}
		)
}

export class DotEarthMap<T> {
	public get connections() { return this.args.connections; }

	constructor(protected args: IDotEarthMapArgs<T>) {
		(mapboxgl as any).accessToken = 'pk.eyJ1IjoiZG90ZWFydGh0ZWNoIiwiYSI6ImNraGNjaG90eTAwODYyenJ5YTlld2U4d2kifQ.ntvcNnwvURRQKtJ_2QQnOQ';
	}

	public get mapbox() {
		return jit(this, "mapbox", () => {
			const map = new mapboxgl.Map({
				container: this.domNode,
				style: 'mapbox://styles/mapbox/streets-v11',
				// style: `mapbox://styles/mapbox/navigation-night-v1`,
				center: {lng: -105.28007380298136, lat: 40.01677028874002},
				zoom: 12.5
			});

			map.on("load", () => {
				// this.addPois();
				this.setupMarkerDeriver();
				this.setupConnectionLinesDeriver();
				// this.initPopupFocusDeriver();
				// this.initTagWebDeriver();
				this.initLangDeriver();
			});

			return map;
		})
	}

	public get domNode() {
		setTimeout(() => void this.mapbox, 0); // assert mapbox exists
		setTimeout(() => this.mapbox.resize(), 750);
		return jit(this, "domNome", () => div("MapboxContainer", {
			// ddxClass: () => this.connections.state.outOfWeb === "hide" ? "hideUnconnected" : ""
		}))
	}

	protected markers = new Map<T, {
		marker: mapboxgl.Marker,
		popup?: mapboxgl.Popup,
	}>();
	public setupMarkerDeriver() {
		deriveDomAnchored(this.domNode, () => {
			const { items } = this.connections;
			const currentItemMap = new Map<T, void>();
			items.forEach((it) => {
				currentItemMap.set(it);
				if (this.markers.has(it)) { return; }

				const popupCreate = this.args.createPopup;
				let popup: mapboxgl.Popup | undefined;
				if (typeof popupCreate === "function") {
					popup = popupCreate(it, this.connections);
				} else if(popupCreate) {
					popup = createPopup({
						...popupCreate,
						location: () => this.args.getLoc(it),
						renderPopup: () => popupCreate.renderPopup(it, this.connections)
					});
				}

				const markerCreate = this.args.createMarker;
				let marker: mapboxgl.Marker;
				if (typeof markerCreate === "function") {
					marker = markerCreate(it, this.connections);
				} else {
					marker = createMarker({
						location: () => this.args.getLoc(it),
						onPush: () => this.connections.focus = it,
						ddxClass: () => [
							this.connections.focus === it && "--focused",
							this.connections.isSelected(it) && "--selected",
							this.connections.isConnected(it) && "--connected",
						]
					})
					deriveDomAnchored(marker.getElement(), () => {
						this.connections.focus === it && this.mapbox.panTo(marker.getLngLat())
					})
				}

				this.markers.set(it, {marker, popup});
				marker.addTo(this.mapbox);
				if (popup) {
					onPush(marker.getElement(), () => {
						popup!.addTo(this.mapbox);
						// if (document.body.contains(popup!.getElement())) {
						// 	popup!.remove();
						// } else {
						// }
						return null as any;
					});
				}
			})

			for (const [item, parts] of this.markers.entries()) {
				if (currentItemMap.has(item) === false) {
					const { marker, popup } = parts;
					marker.remove();
					popup && popup.remove();
					this.markers.delete(item);
				}
			}
		})


		let lastPopup: mapboxgl.Popup | undefined;
		deriveDomAnchored(this.domNode, () => {
			const { focus } = this.connections;
			const markerParts = focus && this.markers.get(focus);
			const { popup } = markerParts || {}
			if (popup !== lastPopup) {
				lastPopup && lastPopup.remove();
				lastPopup = popup;
			}
			console.log("focus change");
			setTimeout(() => {
				popup && popup.addTo(this.mapbox);
			}, 0);
		})
	}


	protected webLines = new Map<T, {
		line: Polyline;
		hold: boolean;
	}>();
	protected lastFocus: T | undefined;
	protected setupConnectionLinesDeriver() {
		derive(() => {
			const { focus } = this.connections;
			if (focus !== this.lastFocus) {
				this.clearWebLines("all")
				this.lastFocus = focus;
			}
			if (!focus) { return; }
			const webItems = this.connections.connectedItems;
			console.log(webItems);
			webItems.forEach((item) => {
				if (this.webLines.has(item)) {
					this.webLines.get(item)!.hold = true;
				} else {
					derive(() => {
						const focusLoc = this.args.getLoc(focus);
						const itemLoc = this.args.getLoc(item);
						if (
							!focusLoc || focusLoc.lat === undefined || focusLoc.lng === undefined
							|| !itemLoc || itemLoc.lat === undefined || itemLoc.lng === undefined
						) { return; }
						if (this.webLines.has(item)) {
							this.webLines.get(item)!.line.remove();
							this.webLines.delete(item);
						}
						const line = new Polyline({
							// id: `${args.org!.name}->${target.name}`,
							id: `${Date.now()}-${Math.random()}`,
							// points: [args.org, target],
							points: [focusLoc, itemLoc] as any,
							paint: {
								"line-color": "#0b72bd"
							}
						});
						line.addTo(this.mapbox);
						this.webLines.set(item, {
							hold: true,
							line
						})
					})
				}
			})
			this.clearWebLines("unheld");
		})
	}

	protected clearWebLines(mode: "all" | "unheld") {
		for (const [item, state] of this.webLines.entries()) {
			if (mode === "all" || (state.hold !== true)) {
				state.line.remove();
				this.webLines.delete(item);
			}
			if (mode === "unheld") {
				state.hold = false;
			}
		}
	}





	// protected webLines: Array<{line: Polyline | undefined}>;
	// public async updateWeb(args: null | {
	// 	org: OrganizationEntity | undefined,
	// 	tag: TagEntity | undefined
	// }) {
	// 	console.log("updateWeb");
	// 	this.webLines && this.webLines.forEach(({line}) => line && line.remove());
	// 	this.webLines = [];
	// 	if (args === null || !args.org || args.org.connected === false) { return; }

	// 	const orgs = App.ents.org.getAllPhased();
	// 	if (orgs === "PENDING" || "error" in orgs) {
	// 		return;
	// 	}

	// 	this.webLines = orgs.map((target) => {
	// 		if (target === args.org) { return; }
	// 		const out = { line: undefined as undefined | Polyline };
	// 		derive(() => {
	// 			const { connected } = target;
	// 			if (!connected) { return; }
	// 			const orgPos = args.org!.lngLat;
	// 			const targetPos = target.lngLat;
	// 			if (!orgPos || !targetPos) { return; }
	// 			if (out.line) { out.line.remove(); }
	// 			out.line = new Polyline({
	// 				id: `${args.org!.name}->${target.name}`,
	// 				// points: [args.org, target],
	// 				points: [orgPos, targetPos],
	// 				paint: {
	// 					"line-color": "#0b72bd"
	// 				}
	// 			});
	// 			out.line.addTo(this.mapbox);
	// 		})

	// 		return out;
	// 	}).filter((it) => !!it) as any;
	// }


	// protected initPopupFocusDeriver() {
	// 	let lastOrg: OrganizationEntity | undefined;
	// 	derive({
	// 		allowEqualValueUpdates: true,
	// 		fn: () => {
	// 			const org = App.ents.org.focused;
	// 			org && org.popup.addTo(this.mapbox);

	// 			if (lastOrg === org) { return; }
	// 			lastOrg && lastOrg.popup.remove();
	// 			lastOrg = org;
	// 		}
	// 	});
	// }

	// protected initTagWebDeriver() {
	// 	derive(() => {
	// 		const org = App.ents.org.focused;
	// 		const tag = App.connections.focusedTag;
	// 		this.updateWeb({
	// 			org, tag
	// 		});
	// 	});
	// }

	// protected addPois() {
	// 	// deriveAnchorless(() => {
	// 	// 	App.ents.org.find.cache("only")({}).forEach((org) => {
	// 	// 		org!.marker.addTo(this.mapbox);
	// 	// 	});
	// 	// });
	// 	deriveAnchorless(() => {
	// 		App.ents.loc.find.cache("only")({}).forEach((loc) => {
	// 			loc!.marker.addTo(this.mapbox);
	// 		});
	// 	});
	// }

	protected initLangDeriver() {
		deriveAnchorless(() => {
			const lang = App.textContent.lang;
			const langCode = lang === "en_US" ? "en" : lang;

			const layers = (this.mapbox.getStyle().layers || []).filter((it) => it.id.includes("label"));
			// const layers = [ "country-label", "state-label"];
			layers.forEach((layer) => {
				this.mapbox.setLayoutProperty(layer.id, "text-field", [
					"coalesce",
					["get", `name_${langCode}`],
					["get", "name_en"],
					["get", "name"]
				])
			});
		})
	}
}
