import { IUser, IUserArgs, LanguageCode } from "dot-earth-middleware";
import { SourceRepo, SourceRepoController } from "helium-source-repo";
import { SourceRepoControllersManager } from "helium-source-repo";
import { repoApiArgs } from "./EntityCommon";


export class UserManager<TYPE extends UserEntity = UserEntity> extends SourceRepoControllersManager<TYPE> {
	protected getDefaultArgs() {
		return {
			repo: new SourceRepo<IUser, "id", IUserArgs>({
				repoId: "User",
				idPropName: "id",
				...repoApiArgs("user"),
			}),
			createController: (data) => new UserEntity(data),
		} as any;
	}
}


export class UserEntity
	extends SourceRepoController<IUser, "id">
	implements Record<keyof IUser, any>
{
	public _noHeliumProxy = true;

	protected get lang(): LanguageCode { return "en"; }

	public get id() { return this.getId(); }
	public get createdAt(){ return this.getProp("createdAt"); }
	public get updatedAt(){ return this.getProp("updatedAt"); }
	public get email() { return this.getProp("email"); }
	public get name() { return this.getProp("name"); }
	public get loginToken() { return this.getProp("loginToken"); }

	public get displayName() {
		if (this.name) {
			if (this.name.alias) {
				return this.name.alias
			} else if (this.name.first && this.name.last) {
				return this.name.first + " " + this.name.last;
			}
		}
		return this.email;
	}
}
