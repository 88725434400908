import "./translate.scss";

import { button, div, el, h2, h3 } from "helium-ui";
import { App } from "MapApp/state/AppState";
import { SourceRepoController } from "helium-source-repo";
import { MultiLang } from "dot-earth-middleware";

export function shouldHaveTranslateButton(item: any) {
	if (App.custom.state.translatorTooltips !== true) { return false; }
	return item && typeof item === "object" && item.en;
}

export interface ITranslateEntProp {
	ent: SourceRepoController<any, any>;
	prop: string;
}

export type TranslateArg = ITranslateEntProp;

export function renderTranslateButton(arg: TranslateArg) {
	return div("TranslateButton", {
		onPush: () => {
			const modal = App.modals.add({
				domNode: () => renderTranslatePanel({
					...arg,
					closeModal: () => modal.close()
				}),
				container: { type: "tightCard" },
			})
		},
		innards: App.icon.getIconSpan("multi-lang"),
	})
}

export function renderTranslatePanel(arg: TranslateArg & { closeModal?: () => any }) {
	let get: () => MultiLang;
	let set: (val: string) => any;
	let clear: () => any;
	let save: () => Promise<any>;
	if ("ent" in arg) {
		get = () => arg.ent.getProp(arg.prop);
		set = (val) => arg.ent.stageEdits({[arg.prop]: { es: val }});
		clear = () => arg.ent.unstageEdits({[arg.prop]: { es: undefined }});
		save = () => arg.ent.save();
	}

	return div("TranslatePanel", [
		h2(["Translator Panel"]),
		div("Texts", [
			div("Source", [
				h3(["English"]),
				div("Value", () => get().en)
			]),
			div("Translation", [
				h3(["Spanish"]),
				el("textarea", {
					class: "Value",
					ddx: (ref) => {
						ref.value = get().es || "";
					},
					on: {
						input: (ev) => {
							const target = ev.target as HTMLTextAreaElement
							console.log(target.value);
							set(target.value)
						}
					}
				})
			])
		]),
		div("Buttons", [
			button({
				innards: "Cancel",
				onPush: () => {
					clear();
					arg.closeModal && arg.closeModal();
				}
			}),
			button({
				innards: "Save",
				onPush: async () => {
					await save();
					arg.closeModal && arg.closeModal();
				}
			}),
		])
	])
}
