import { SourceRepoController } from "helium-source-repo";
import { span } from "helium-ui";
import { EditableTextArgs, EditableText } from "./EditableText";

export type EntTextFieldEditArgs<CONT extends SourceRepoController<any, string, any>> =
	Pick<EditableTextArgs, "editLock" | "register" | "validation" | "placeholder">
	& {
		item: CONT,
		prop: keyof CONT,
		multiLang?: boolean;
		getEditor: () => CONT | false;
	}


export function createEntTextFieldEditor<CONT extends SourceRepoController<any, string, any>>(
	args: EntTextFieldEditArgs<CONT>
) {
	const { item, prop, multiLang, getEditor, placeholder, editLock, validation, register } = args;
	const getCurrentValue = () => {
		const out = item.getProp(prop);
		if (out && typeof out === "object") {
			if (!multiLang) {
				throw new Error(`Got an object type for text area, but multiLang was not specified`);
			}
			return out["en"];
		}
		return out;
	}
	const editor = getEditor();
	if (!editor) {
		return span(getCurrentValue);
	}
	return new EditableText({
		getCurrentValue,
		placeholder,
		register,
		editLock,
		validation,
		storage: {
			get: () => {
				const out = editor.getProp(prop);
				return (out && typeof out === "object" && multiLang) ? out["en"] : out;
			},
			set: (val) => {
				(editor as any).stageEdits({
					[prop]: !multiLang ? val : {
						en: val
					}
				});
			}
		}
	});
}



export function renderEntTextEditor<CONT extends SourceRepoController<any, string, any>>(
	args: EntTextFieldEditArgs<CONT>
) {
	const editor = createEntTextFieldEditor(args);
	if (editor instanceof EditableText) {
		return span([editor.domNode(), editor.editIcon()]);
	}
	return editor;
}
