import { EditContextOutline } from "helium-source-repo";
import { noDeps, span } from "helium-ui";

export interface IEditIconArgs {
	context: EditContextOutline;
	noHide?: boolean;
	type?: "trashOnly" | "neverTrash",
	onPush?: () => any,
}

export function renderEditIcon(args: IEditIconArgs) {
	return span("EditIcon", {
		class: [
			args.type,
			args.noHide && "noHide",
		].filter(it => !!it).join(" "),
		ddxClass: () => [
			args.context.editing ? "--enabled" : "",
			args.context.hasEdits ? "--edited" : ""
		],
		onPush: args.onPush || (() => {
			if (noDeps(() => args.context.editing)) {
				args.context.cancelEdits();
			} else {
				args.type !== "trashOnly" && (args.context.editing = true);
			}
		}),
	})
}
