import { matchesRequirements } from "../Utils/match";

export type ISelectionFilter<T extends object> = {
	where?: WhereFilter<T>;
	limit?: number;
}

export type FilterLogic<DATA extends object> = (WhereFilter<DATA> | ISelectionFilter<DATA>);
export type WhereFilter<DATA extends object> = Partial<DATA>;



export function matchesFilter<T extends object>(item: T, filter: ISelectionFilter<T>): boolean {
	return !filter.where || matchesRequirements(item, filter.where);
}
