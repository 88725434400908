import { SourceRepoController, SourceRepoControllersManager, SourceRepo, jit } from "helium-source-repo";
import { IOrganization, IOrganizationArgs, RoleType } from "dot-earth-middleware";
import { LanguageCode } from "../../../middleware/consts/LanguageCodes";
import { TagEntity } from "./Tag.ents";
import { ActionEntity } from "./Action.ents";
import { DotEarthClient } from "../Client";
import { repoApiArgs } from "./EntityCommon";


export class OrganizationManager<TYPE extends OrganizationEntity>
	extends SourceRepoControllersManager<TYPE>
{
	protected getDefaultArgs() {
		return {
			repo: new SourceRepo<IOrganization, "id", IOrganizationArgs>({
				repoId: "Organization",
				idPropName: "id",
				...repoApiArgs("organization"),
			}),
			createController: (data) => new OrganizationEntity(data),
		} as any;
	}
}


export class OrganizationEntity
	extends SourceRepoController<IOrganization, "id">
	implements Record<keyof IOrganization, any>
{
	public _noHeliumProxy = true;

	// protected get lang(): LanguageCode { return "en"; }

	// protected getMultiLang<KEY extends keyof IOrganization, T = string>(key: KEY): undefined | null | T {
	// 	const value = this.getProp(key);
	// 	if (!value) { return value as any; }
	// 	return value[this.lang];
	// }

	// public get idNoPrefix() { return this.id.replace("org-", ""); }

	public get id() { return this.getId(); }
	public get createdAt() { return this.getProp("createdAt"); }
	public get type() { return this.getProp("type"); }
	public get updatedAt() { return this.getProp("updatedAt"); }
	public get name() { return this.getProp("name"); }
	public get defaultLang() { return this.getProp("defaultLang"); }
	public get shortDesc() { return this.getProp("shortDesc"); }
	public get website() { return this.getProp("website"); }
	public get logoUrl() { return this.getProp("logoUrl"); }
	public get nameIsInLogo() { return this.getProp("nameIsInLogo"); }


	// protected _lngLat = new Source<undefined | [number, number]>();
	// public get lngLat() {
	// 	this.setupLngLatDeriver();
	// 	return this._lngLat.get();
	// }

	// protected setupLngLatDeriver() {
	// 	jit(this, "posDeriver", () => {
	// 		deriveAnchorless(() => {
	// 			const locUse = this.locUses.find.one({});
	// 			if (locUse) {
	// 				const loc = locUse.locEnt.get()
	// 				loc && loc.lng && loc.lat && this._lngLat.set([loc.lng, loc.lat]);
	// 			}
	// 		})
	// 	})
	// }

	public get tagUses() {
		return jit(this, "tagUses", () => DotEarthClient.get().ents.tagUse.createSlice({targetId: this.id}));
	}

	public get roles() {
		return jit(this, "roles", () => DotEarthClient.get().ents.role.createSlice({holderId: this.id}));
	}

	public get locUses() {
		return jit(this, "locUses", () => DotEarthClient.get().ents.locUse.createSlice({targetId: this.id}));
	}

	public findOneLoc() {
		const locUse = this.locUses.find.one({});
		return locUse ? locUse.locEnt.get() : undefined;
	}

	public hasTag(tag: TagEntity | string) {
		const out = this.tagUses.find.one({ where: {tagId: typeof tag === "string" ? tag : tag.id }});
		return !!out;
	}

	public hasActionRole(act: ActionEntity | string, type: RoleType) {
		const out = this.roles.find.one({ where: {
			contextId: typeof act === "string" ? act : act.id,
			type
		}});
		return !!out;
	}
}
