import "./orgListPage.scss";

import { isPhaseResponse } from "helium-source-repo";
import { anchor, div, h1 } from "helium-ui";
import { App } from "MapApp/state/AppState";
import { OrganizationEntity } from "MapApp/state/entities/Organization";
import { inLang } from "../InLang/InLang";


export function renderOrgListPage() {
	return div("OrgListPage", () => {
		const orgs = App.ents.org.getAllPhased();
		if (isPhaseResponse(orgs)) {
			return "Loading..."
		}
		return [
			h1(["Organizations"]),
			div("OrgItemList cardList", orgs.map((org) => renderOrgItem(org)))
		]
	})

	function renderOrgItem(org: OrganizationEntity) {
		return anchor("OrgItem cardList-Item cardList-addHoverArrow", {
			href: () => org.detailPageUrl
		},[
			org.renderTitleBox(),
			div("InfoArea", [
				`"${inLang(org.shortDesc)}"`,
			])
		]);
	}
}
