import { SourceRepo } from "../Repos/SourceRepo";
import { DetailedPhaseResponse } from "../SelectionArgs";
import { ISelector, SelectionLogicChain } from "../SelectionLogicChain";
import { ResponseHandler } from "../Selections/ResponseHandler";

export interface IComplexSelectorArgs {
	gqlPath: string;
}

export type ComplexSelector<FROM extends {}, TO extends {}> = {
	join: {
		from: {
			repo: SourceRepo<FROM, any>,
			key: keyof FROM,
			where?: Partial<FROM>
		},
		to: {
			repo: SourceRepo<TO, any>,
			key?: keyof TO,
		}
	}
}

export class RepoComplexTool {
	constructor(protected args: IComplexSelectorArgs) {
		// this.selectRaw.async.cache({ maxAge: 1500})("")
	}

	public select<F, T>(sel: ComplexSelector<F, T>) {

	}

	public get selectRaw() {
		return SelectionLogicChain<any, [string]>((logic, rawGql) => {
			const handler = ResponseHandler.get(rawGql, () => ({
				request: { async: async () => {
					const resp = await fetch(this.args.gqlPath, {
						method: "POST",
						headers: {
							'Content-Type': 'application/json',
							'Accept': 'application/json',
						},
						body: rawGql
					});
					return resp.json();
				}},
				logic,
			}));

			switch (logic.mode) {
				case "async": return handler.promise;
				case "phase-detailed": return handler;
				case "phased": return handler.phaseResponse;
				default: return handler.source.get();
			}
		}) as Pick<ISelector<any, [string]>, "async" | "phased">
	}
}
