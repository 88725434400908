import { EditingState } from "helium-source-repo"
import { UrlState } from "helium-ui";

export class AppEditingState extends EditingState {
	constructor() {
		super();
		console.log("constructing editing state")
		UrlState.onUrlChange((ev) => {
			if (this.countOpenEdits() > 0) {
				const loseEdits = confirm("You have unsaved edits which will be lost if you change the page.  Continue?")
				if (!loseEdits) {
					ev.stopImmediatePropagation();
					ev.stopPropagation();
					ev.preventDefault();
					return false;
				} else {
					this.openEdits.clear();
				}
			}
		});
	}
}
