import "./tagUseList.scss";

import { EditContext, EditLockType, IEditContextArgs, isPhaseResponse, SourceRepoPhaseResponse } from "helium-source-repo";
import { ddxClass, deriveDomAnchored, div, noDeps, setInnards, span } from "helium-ui";
import { OrganizationEntity } from "MapApp/state/entities/Organization";
import { App } from "MapApp/state/AppState";
import { SourceMap } from "helium-ui";
import { Source } from "helium-sdx";
import { IEditIconArgs, renderEditIcon } from "../Editors/EditIcon";
import { TagEntity, TagUseEntity } from "dot-earth-client";
import { ConnectionState } from "MapApp/state/ConnectionState";
import { inLang } from "../InLang/InLang";

export type OnTagSelectArg = "set-connection-filter" | ((tag: TagEntity ) => any);

export function renderTagUseList(args: {
	target: string | OrganizationEntity;
	connections?: ConnectionState<any, { tag: TagEntity }>;
	onTagSelect?: OnTagSelectArg;
	edits?: {
		allowEditing: () => boolean;
		editLock?: () => EditLockType;
		register?: {
			group: string;
			name?: string;
		}
	}
}) {

	let _editor: TagUseEditContext;

	const { target, connections } = args;
	const targetId = typeof target === "string" ? target : target.getId();
	const out = div("TagUseList");

	console.log("tag use", args);
	ddxClass(out, () => getEditor() && getEditor()!.editing && "--editing");
	setInnards(out, [
		() => {
			const edit = getEditor();
			const tagUseList = edit?.editing ? edit!.tagUseMap.keys().filter((key) => edit.tagUseMap.get(key) !== "DROP") : getTagUseList()
			if (isPhaseResponse(tagUseList)) { return; }
			return tagUseList.length === 0 ? span("NoTags", "no tags") : tagUseList.map((tagUse) => {
				const tag = tagUse.tag;
				return tag && span("Tag inUse", {
					innards: () => [
						inLang(tag.name),
						edit?.editing && span("RemoveTag", {
							innards: "X",
							onPush: () => {
								edit.tagUseMap.get(tagUse) ? edit.tagUseMap.delete(tagUse) : edit.tagUseMap.set(tagUse, "DROP");
							}
						})
					],
					ddxClass: () => [
						connections && connections.filter.tag === tag && "--selected",
					],
					onPush: () => {
						const { onTagSelect } = args;
						if (!onTagSelect) { return; }
						if (onTagSelect === "set-connection-filter") {
							if (!connections) {
								throw new Error(`Can not set connection filter without ConnectionState prop filled`);
							}
							if (connections.filter.tag !== tag) {
								connections.filter.tag = tag
							} else {
								connections.filter.tag = undefined;
							}
						} else {
							onTagSelect(tag);
						}
					}
				});
			});
		},
		() => {
			const edit = getEditor();
			return edit && edit.editIcon();
		},
		() => {
			const edit = getEditor();
			return edit?.editing && div("AddTag", () => {
				const allTags = App.ents.tag.getAll();
				return allTags.map((tag) => () => {
					if (edit.hasTag(tag, "ownership")) { return; }
					return span("Tag bubble", {
						innards: () => inLang(tag.name),
						onPush: () => edit.addTag(tag)
					})
				})
			});
		},
	]);
	return out;

	function getEditor() {
		const allowEditing = !!(args.edits?.allowEditing && args.edits.allowEditing());
		if (!allowEditing) { return; }
		if (!_editor) {
			const register = args.edits?.register;
			_editor = new TagUseEditContext({
				getTagUseList,
				register: register ? {
					...register,
					name: register.name || "tagUseList"
				} : undefined,
				editLock: args.edits!.editLock,
				anchor: out,
			})
		}
		return _editor;
	}

	function getTagUseList() {
		return App.ents.tagUse.find.phased({ where: { targetId }});
		// const tagUses = App.ents.tagUse.find.phased({ where: { targetId }});
		// console.log(tagUses);
		// if (isPhaseResponse(tagUses)) { return tagUses; }
		// return tagUses.map((it) => it?.tagId!);
	}
}








// -------------------
// Edit Context Class
// -------------------


export type TagUseEditArgs = IEditContextArgs & {
	getTagUseList: () => TagUseEntity[] | SourceRepoPhaseResponse;
	anchor: HTMLElement;
}

export class TagUseEditContext extends EditContext<
	TagUseEntity[]
> {

	constructor(protected args: TagUseEditArgs) {
		super(args);
		deriveDomAnchored(args.anchor, () => {
			if (this.doCancel.get()) { this.doCancel.set(false); }
			const tagUseList = args.getTagUseList();
			if (isPhaseResponse(tagUseList)) { return; }
			!this.editing && noDeps(() => this.tagUseMap.clear());

			tagUseList.forEach((id) => {
				this.tagUseMap.set(id, null)
			});
		});

		deriveDomAnchored(args.anchor,() => {
			this.updateHasEdits();
			// this.hasEdits = this.tagUseMap.keys().some((key) => {
			// 	const value = this.tagUseMap.get(key)
			// 	return value === "ADD" || value === "DROP";
			// });
		})

		args.anchor.setAttribute("tabIndex", "0");
		deriveDomAnchored(args.anchor,() => {
			this.editing && args.anchor.focus();
		});

		args.anchor.addEventListener("blur", () => {
			console.log("BLUR", this.hasEdits);
			!this.hasEdits && (this.editing = false);
		})
	}

	public updateHasEdits(): void {
		this.hasEdits = this.tagUseMap.keys().some((key) => {
			const value = this.tagUseMap.get(key)
			return value === "ADD" || value === "DROP";
		});
		console.log("update has edits", this.hasEdits);
	}

	public tagUseMap = new SourceMap<TagUseEntity, null | "DROP" | "ADD">();
	public get currentValue() {
		return this.tagUseMap.keys();
	}

	protected doCancel = new Source(false);
	public _cancelEdits(): boolean {
		this.tagUseMap.clear();
		this.doCancel.set(true);
		return true;
	}
	public save = undefined;

	public async pushUpdatesForCtx(targetId: string) {
		const drops = this.tagUseMap.keys().filter((key) => this.tagUseMap.get(key) === "DROP");
		await Promise.all(drops.map((tagUse) => tagUse.delete.confirm()));

		const adds = this.tagUseMap.keys().filter((key) => this.tagUseMap.get(key) === "ADD");
		adds.forEach((tagUse) => tagUse.stageEdits({ targetId }));
		await Promise.all(adds.map((tagUse) => tagUse.save()));

		// const out = await noDeps(() => App.api.massTagUseJoinUpdate({
		// 	contextId,
		// 	drops: this.tagIdMap.keys().filter((key) => this.tagIdMap.get(key) === "DROP"),
		// 	joins: this.tagIdMap.keys().filter((key) => this.tagIdMap.get(key) === "ADD"),
		// }));

		// console.log(out);
		// return out;
	}

	public editIcon(args: Omit<IEditIconArgs, "context"> = {}) {
		return renderEditIcon({
			...args,
			context: this
		});
	}

	public addTag(tagOrId: string | TagEntity) {
		const tagUse = this.getTagUseForTag(tagOrId);
		if (!tagUse) {
			const template = App.ents.tagUse.createTemplateController();
			const tagId = typeof tagOrId === "string" ? tagOrId : tagOrId.id;
			template.stageEdits({tagId});
			this.tagUseMap.set(template, "ADD");
			return;
		}
		switch (this.tagUseMap.get(tagUse)) {
			case null:
			case "ADD": return;
			case "DROP": return this.tagUseMap.set(tagUse, null);
		}
	}

	public hasTag(tagOrId: string | TagEntity, mode: "ownership") {
		const usageState = this.getUsageStateForTag(tagOrId);
		if (mode === "ownership") {
			return usageState === "ADD" || usageState === null;
		}
	}

	public getUsageStateForTag(tagOrId: string | TagEntity) {
		const target = this.getTagUseForTag(tagOrId);
		return !target ? undefined : this.tagUseMap.get(target);
	}

	public getTagUseForTag(tagOrId: string | TagEntity) {
		const tagId = typeof tagOrId === "string" ? tagOrId : tagOrId.id;
		const target = noDeps(() => this.tagUseMap.keys().find((tagUse) => tagUse.tagId === tagId));
		target && (void this.tagUseMap.has(target));
		return target;
	}
}
