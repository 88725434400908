import { Sourcify, derive, noDeps, deriveAnchorless, DeriverScope } from "helium-sdx";
import { EditContext, EditorRegistration } from "../Editing";
import { EditContextOutline, EditingState } from "../Editing/EditsState";
import { SourceRepoSlice } from "../Repos/SourceRepoSlice";
import { IdType, SourceRepoPhaseResponse } from "../SelectionArgs";
import { matchesRequirements } from "../Utils/match";
import { SourceRepoControllersManager } from "./SourceRepoControllersManager";



export type ISourceRepoControllerArgs<
  T extends object,
  ID_NAME extends keyof T,
  INSERTABLE extends Partial<T>,
> = Record<ID_NAME, IdType<T, ID_NAME>> & {
	manager?: SourceRepoControllersManager<any>
  repo: SourceRepoSlice<T, ID_NAME, INSERTABLE>;
	editor?: {
		for: "NEW" | SourceRepoController<T, ID_NAME, INSERTABLE>;
		register?: EditorRegistration;
	}
}



export class SourceRepoController<
  T extends object,
  ID_NAME extends keyof T,
  INSERTABLE extends Partial<T> = T
> extends EditContext<T> {

	public _noHeliumProxy = true;

  public readonly state = Sourcify({
    loading: true,
    detachedFromRepo: false,
    data: "NEW" as any as T | "DELETED" | "NEW"
  });

  constructor(protected args: ISourceRepoControllerArgs<T, ID_NAME, INSERTABLE>) {
		super({
			register: args.editor?.register
		});
    this.state.detachedFromRepo = noDeps(() => !!this.editorFor);
		if (args.editor?.for && args.editor.for !== "NEW" && (args.editor.for instanceof this.constructor === false)) {
			throw new Error(`editorFor must be instance of this class type`)
		}

		if (this.args.editor?.for === "NEW") {
			this.state.data = "NEW";
			this.state.loading = false;
		} else {
			this.setDataToBaseline()
		}
    this.setupDeriver();
  }

	protected setDataToBaseline() {
		noDeps(() => this.state.data = {
			[this.idPropName]: this.args[this.idPropName]
		} as any)
	}

  public getId(): IdType<T, ID_NAME> {
		if (noDeps(() => typeof this.state.data === "object")) {
			return this.state.data[this.idPropName];
		}
    return this.args[this.idPropName];
  }

  public get idPropName() { return this.args.repo.idPropName; }
  public get repo() { return this.args.repo; }
	public get data() {
		const out = this.state.data;
		return typeof out === "string" ? undefined : out;
	}

  public isLoading() { return this.state.loading; }
	public isTemplate() { return this.editorFor === "NEW"; }
	public isEditor() { return !!this.editorFor }
	public isDeleted() { return this.state.data === "DELETED"; }

  public getProp<KEY extends keyof T, FILL = undefined>(key: KEY, fillIn?: FILL): T[KEY] | FILL {
    if (key === this.idPropName) { return this.getId() as any; }
    const data = this.state.data;
		const out = data ? (data as any)[key] : undefined;
		if (out !== undefined) { return out; }
		const { editorFor } = this;
		return editorFor && editorFor !== "NEW" ? editorFor.getProp(key, fillIn) : (fillIn as any);
  }

	public reload() {
    if (this.state.detachedFromRepo) {
      throw new Error(`Can not reload a stand-alone controller which has no repo`);
    }
    this.repo.get.cache("ignore")(this.getId());
  }

	protected _updateDeriver: DeriverScope;
  protected setupDeriver() {
    this._updateDeriver = derive({
      anchor: "NO_ANCHOR",
      batching: "none",
      fn: () => {
				if (this.state.detachedFromRepo) { return; }
        const id = this.getId();
        const cached = this.repo!.get(id);
        if (cached) {
          this.state.loading = false;
					const data = noDeps(() => this.state.data);
					(!data || typeof data === "string") ? this.state.set("data", cached): data.upsert(cached);
        } else if (noDeps(() => this.state.loading === false && !this.state.data)) {
					this.state.set("data", "DELETED");
				}
        // data = this.args.repo.get.phased(this.getIdFromArgs());
        // if (isPhaseResponse(data)) {
        //   this.onPhaseResponse(data);
        // } else {
        //   this.state.loading = false;
        //   noDeps(() =>
        //     (data && this.state.data?.upsert(data))
        //     || this.state.set("data", null)
        //   )
        // }
      }
    });
  }

  protected onPhaseResponse(
    phaseResponse: SourceRepoPhaseResponse
  ) {
    if (phaseResponse === "PENDING" ) {
      this.state.loading = true;
    } else if (typeof phaseResponse === "object" && "error" in phaseResponse) {
      this.state.loading = false;
    }
  }

  // protected _subControllerManagers = new Map<string, SourceRepoControllersManager<any, any, any>>();
  // protected getSubManager(
  //   id: string,
  //   creationCb: () => any
  // ) {
  //   if (this._subControllerManagers.has(id) === false) {
  //     this._subControllerManagers.set(id, creationCb());
  //   }
  //   return this._subControllerManagers.get(id)!;
  // }

	// -------------------------
	//   EDITOR FOR
	// -------------------------


	public updateHasEdits(): void {
		const { data } = this;
		this.state.detachedFromRepo;
		const editorFor = this.editorFor;
		if (editorFor && data) {
			if (editorFor === "NEW") {
				this.hasEdits = true;
				return;
			}

			const compareTo = editorFor.state.data;
			this.hasEdits = !compareTo || Object.entries(data).some(([key, value]) => {
				const target = compareTo[key];
				if (value && target && typeof value === "object" && typeof target === "object") {
					return !matchesRequirements(target, value, {
						equality: "null-is-undefined"
					});
				}
				return value !== target;
			});
			return;
		}
		this.hasEdits = false;
	}

	protected editors = new Map<string | undefined, this>();
	public getEditor(groupOrRegister?: string | EditorRegistration) {
		const register = typeof groupOrRegister === "string" ? {
			group: groupOrRegister
		} : groupOrRegister;
		if (this.isEditor()) {
			if (this.batching?.group !== register?.group) {
				throw	new Error(`Can not create an editor for an editor`);
			}
			return this;
		}
		if (this.editors.has(register?.group) === false) {
			const args: ISourceRepoControllerArgs<T, ID_NAME, INSERTABLE> = {
				...this.args,
				editor: {
					for: this,
					register: register ? {
						name: this.repo.repoId,
						...register
					} : undefined,
				}
			}
			this.editors.set(register?.group, new (this.constructor as any)(args)) ;
		}
		return this.editors.get(register?.group)!;
	}

	public get editorFor() {
		void this.state.detachedFromRepo;
		return this.args.editor?.for;
	}

	protected demandEditorFor() {
		const out = this.editorFor;
		if (!out) {
			throw new Error(`Can not use function.  Entity is not an editor instance.`)
		}
		return out;
	}

	// public get batching() {
	// 	const { group } = this.args.editor || {};
	// 	return group ? { group, name: this.repo.repoId } : undefined;
	// }

	public get currentValue() {
		return this.demandEditorFor() && this.data?.toObject() as any;
	}

	protected _cancelEdits(): boolean {
		if (this.isTemplate()) {
			this.state.set("data", "DELETED");
		} else {
			this.state.set("data", {} as any);
		}
		return true;
	}

	public stageEdits(edits: Partial<T>, mode: "overwrite-all" | "overwrite-props" | "merge-objects" = "merge-objects") {
		noDeps(() => {
			const editorFor = this.demandEditorFor();
			const { data } = this;
			if (!data || mode === "overwrite-all") {
				this.state.set("data", edits as any);
				return;
			}
			if (mode === "overwrite-props") {
				for (const key in edits) {
					data[key as any] = edits[key]
				}
				return;
			}
			// make sure all merged fields are filled before upsert
			if (editorFor !== "NEW") {
				for (const key in edits) {
					const addMe = edits[key];
					if (!addMe || typeof addMe !== "object") {
						data[key as any] = addMe;
					} else if (!data[key as any] && editorFor.data) {
						const value = editorFor.data[key as any];
						if (value !== null && typeof value === "object") {
							data[key as any] = value.toObject();
						}
					}
				}
			}
			data.upsert(edits);
		})
	}

	public unstageEdits(edits?: Partial<T>) {
		noDeps(() => {
			if (!this.data) { return; }
			if (!edits) {
				return this.setDataToBaseline();
			}
			const permeate = (target: object, changes: object) => {
				Object.keys(changes).forEach((prop) => {
					if (!target[prop]) { return; }
					const value = changes[prop];
					if (value === undefined) {
						delete target[prop];
					} else {
						const emptied = permeate(target[prop], changes[prop]);
						if (emptied) {
							delete target[prop];
						}
					}
				})
				return Object.keys(target).length === 0;
			}
			const noData = permeate(this.data, edits);
			if (noData) {
				this.setDataToBaseline();
			}
		})
	}

	public save = () => {
		return noDeps(async () => {
			this.demandEditorFor();
			const data = this.data?.toObject();
			if (!data) { return false; }
			if (this.isTemplate()) {
				// create
				const newSelf = await this.repo.dataSource.syncToServer({ create: [ data as any ] });
				if (newSelf.length > 1) {
					throw new Error(`Should only be one`);
				}
				// new id needs to be synced
				this.state.data[this.idPropName] = this.args[this.idPropName] = newSelf[0][this.idPropName];
				this.args.manager?.addController(this);
				this.args.editor = undefined;
				this.state.detachedFromRepo = false;
			} else {
				// sync
				await this.repo.dataSource.syncToServer([{
					[this.idPropName]: this.getId(),
					...(data as any)
				}]);
				this.state.set("data", {} as any);
			}
			this._updateDeriver.runDdx();
			return true;
		})
	}

	public delete = {
		confirm: async () => {
			if (this.editorFor) {
				throw new Error(`Can not delete a template or editor`);
			}
			const id = this.getId();
			await this.repo.dataSource.delete(id);
			// this.args.manager?.dropController(id);
			this.state.data = "DELETED";
			this.state.detachedFromRepo = true;
			return "DELETED";
		}
	}
}
