export function matchesRequirements<T extends object>(
	item: T | undefined,
	requirements: Partial<T> | undefined,
	props: {
		equality?: "explicit" | "basic" | "null-is-undefined",
		itemKeys?: "allow-extra" | "no-extras"
	} = {}
) {
	if (!requirements) {
		return true;
	} else if (!item) {
		return false;
	}

	const requirementsMatched = Object.entries(requirements).every(([key, value]) => {
		const matchTo = item[key];
		if (value && typeof value === "object") {
			return matchTo && typeof matchTo === "object" && matchesRequirements(value, matchTo, props);
		}
		const { equality } = props;
		switch (equality) {
			case "null-is-undefined":
				return (matchTo === null ? undefined : matchTo) === (value === null ? undefined : value);
			case "basic":
				return matchTo == value;
			case "explicit":
			default:
				return matchTo === value;
		}
	});
	if (!requirementsMatched) { return false; }
	if (props.itemKeys === "no-extras") {
		return Object.keys(item).every((key) => key in requirements);
	}
	return true;
}
