import { ITagUse, ITagUseArgs, LanguageCode } from "dot-earth-middleware";
import { SourceRepo, SourceRepoController } from "helium-source-repo";
import { SourceRepoControllersManager } from "helium-source-repo";
import { DotEarthClient } from "../Client";
import { repoApiArgs } from "./EntityCommon";


export class TagUseManager<TYPE extends TagUseEntity = TagUseEntity> extends SourceRepoControllersManager<TYPE> {
	protected getDefaultArgs() {
		return {
			repo: new SourceRepo<ITagUse, "id", ITagUseArgs>({
				repoId: "TagUse",
				idPropName: "id",
				...repoApiArgs("tag-use"),
			}),
			createController: (data) => new TagUseEntity(data),
		} as any;
	}
}


export class TagUseEntity
	extends SourceRepoController<ITagUse, "id">
	implements Record<keyof ITagUse, any>
{
	public _noHeliumProxy = true;

	protected get lang(): LanguageCode { return "en"; }

	public get id() { return this.getProp("id"); }
	public get targetId() { return this.getProp("targetId"); }
	public get tagId() { return this.getProp("tagId"); }
	public get createdAt(){ return this.getProp("createdAt"); }

	public get tag() { return this.tagId ? DotEarthClient.get().ents.tag.get(this.tagId) : undefined; }
}
