import { SourceRepo } from "./Repos";
import { DetailedPhaseResponse, SourceRepoResponse } from "./SelectionArgs";

export const CacheUseTypeList = [
  "default",    // whatever the default of the RepoDataSource is
  "ignore",     // use no caching
  "only",       // only use caching, never fetch
  "if-fresh",   // check syncing dates, then fetch if old
  "if-query",   // if the query (filter) has been used before, don't sync
  "if-items",   // if there are any items to match filter, don't sync
  "if-any",     // combination if-query if-items
] as const;
export type CacheUse = { maxAge: number } | typeof CacheUseTypeList[number];

export type ComplexSelectArg<MODEL extends {}> = {
	join: {
		repo: SourceRepo<MODEL, any>,
		from: keyof MODEL,
		where: Partial<MODEL>
	}
}

export interface EndBranch<OUT, F extends Array<any>> {
  (...F: F): OUT;
  cache(mode: CacheUse): (...F: F) => OUT;
}

export type PhasedBranch<OUT, F extends Array<any>> = EndBranch<SourceRepoResponse<OUT>, F> & {
  live: EndBranch<SourceRepoResponse<OUT>, F>;
  detailed: EndBranch<DetailedPhaseResponse<OUT>, F> & {
    live: EndBranch<DetailedPhaseResponse<OUT>, F>;
  }
}

export interface ISelector<
  OUT,
  F extends Array<any>
> {
  withLogic(logic: ISelectionLogic): (...F: F) => any;

  (...F: F): OUT[];
  cache(mode: CacheUse): (...F: F) => OUT[];
  async: EndBranch<Promise<OUT[]>, F>,
  live: EndBranch<OUT[], F>,
  phased: PhasedBranch<OUT[], F>,

  one: EndBranch<OUT | null, F> & {
    async: EndBranch<Promise<OUT | null>, F>,
    live: EndBranch<OUT | null, F>,
    phased: PhasedBranch<OUT | null, F>,
  };

	// complex<M>(arg: ComplexSelectArg<M>): EndBranch<OUT[], F> & {
	// 	async: EndBranch<Promise<OUT[]>, F>,
	// 	live: EndBranch<OUT[], F>,
	// 	phased: PhasedBranch<OUT[], F>,
	// }
}

// const find: ISelector<"a",[boolean]> = null as any;
// find.one.phased.detailed.live(true)



export interface ISelectionLogic {
  mode?: "phased" | "phase-detailed" | "async";
  singular: boolean;
  liveSync: boolean;
  cacheUse: CacheUse;
	complex?: ComplexSelectArg<any>;
  // fetchMode: FetchMode;
}

// export type FetchMode = "none" | "lazy" | "smart" | "hard";

export function SelectionLogicChain<
  OUT,
  F extends Array<any>
>(
  cb: (logic: ISelectionLogic, ...filter: F) => void
) {
  let logic: ISelectionLogic = {
    singular: false,
    liveSync: false,
    cacheUse: "default",
    // fetchMode: "smart",
  }

  const out = new Proxy(
    (...args: F) => {
      return cb(logic, ...args);
    }, {
      get: (_, key) => {
        if (key === "phased") {
          if (logic.mode) { throw new Error(`can not call phased() on chain with mode "${logic.mode}" set`); }
          logic.mode = "phased";
          return out;

        } else if (key === "detailed") {
          if (logic.mode !== "phased") { throw new Error(`detailed() must be called after phased()`); }
          logic.mode = "phase-detailed";
          return out;

        } else if (key === "async") {
          if (logic.mode) { throw new Error(`can not call async() on chain with mode "${logic.mode}" set`); }
          logic.mode = "async";
          logic.cacheUse = "ignore";
          return out;

        } else if (key === "one") {
          logic.singular = true;
          return out;

        } else if (key === "live") {
          logic.liveSync = true;
          return out;

        } else if (key === "cache") {
          return (cacheUse: CacheUse) => {
            logic.cacheUse = cacheUse;
            return out;
          }

        } else if (key === "withLogic") {
          return (newLogic: ISelectionLogic) => {
            logic = {
              ...logic,
              ...newLogic,
            };
            return out;
          }

        } else if (key === "complex") {
					return (complex: ComplexSelectArg<any>) => {
            logic.complex = complex;
            return out;
          }
				}
      }
    }
  );
  return out as ISelector<OUT, F>;
}
