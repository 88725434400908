// import { IRepoDataSourceArgs } from "helium-source-repo";
import { DotEarthApi } from "../tools/Api";


export function repoApiArgs(entPath: string) {

	return {
		getIdList: `${DotEarthApi.host}/${entPath}/find-ids`,
		getItems: `${DotEarthApi.host}/${entPath}/get-items`,
		createItems: `${DotEarthApi.host}/${entPath}/create`,
		syncItemsToServer: `${DotEarthApi.host}/${entPath}/sync`,
		deleteIds: `${DotEarthApi.host}/${entPath}/delete`,
	}
}
