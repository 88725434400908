import { MultiLang } from "../consts/LanguageCodes";
import { PickRequired } from "../PickRequired";

// export interface IAction {
// 	id: string;
// 	title: string;
// 	brief_desc: string;
// 	organizations: string[];
// 	benchmarksOld: string[];
// 	contact: string;
// 	mission: string;
// 	vision: string;
// 	gaps: string[];
// }

// export type IdentifiableAction = Partial<IAction> & { id: string; }



export const ActionTypeList = [
	"collaboration"
] as const;

export type ActionType = typeof ActionTypeList[number];

export interface IAction {
	id: string;
	type: ActionType;
	createdAt: Date;
	updatedAt: Date;
	title: MultiLang;
	briefDesc: null | MultiLang;
	// contact: string;
	mission: null | MultiLang;
	vision: null | MultiLang;
	gaps: null | MultiLang;
}

export type IActionArgs = PickRequired<IAction, "title" | "type">
