import { SourceRepoResponse, ISourceRepoErrorResponse, IdType } from '../SelectionArgs';
import { RepoDataSource } from './RepoDataSource';
import { Source, derive, DerivationManager, DeriverScope } from 'helium-sdx';
import { ISelectionLogic, SelectionLogicChain } from '../SelectionLogicChain';
import { FilterLogic, ISelectionFilter } from '../Selections/SelectionFilter';
import { ParseItem, GqlLayer, gqlRequest, GqlRequestMap } from '../Complex/gqlTemplator';


export class SourceRepoSlice<
	T extends Object,
	ID_NAME extends keyof T,
	INSERTABLE extends Partial<T>,
> {
	public readonly dataSource: RepoDataSource<T, ID_NAME, INSERTABLE>;
	protected whereRules: null | Partial<T>;


  constructor(args: {
		dataSource: RepoDataSource<T, ID_NAME, INSERTABLE>,
		whereRules: Partial<T> | null,
  }) {
		this.dataSource = args.dataSource;
		this.whereRules = args.whereRules;
	}

	public get idPropName() { return this.dataSource.idPropName; }
	public get repoId() { return this.dataSource.repoId; }


	// ---------------------------


	protected standardizeFilterArg(FilterLogic: FilterLogic<T>) {
		const filter: ISelectionFilter<T> = FilterLogic ? (
			("where" in FilterLogic || "limit" in FilterLogic) ? (
				FilterLogic
			):(
				{ where: FilterLogic as Partial<T>}
			)
		) : { where: {} };
		return filter;
	}

	protected normalizeFilter(filter: ISelectionFilter<T>) {
		if (this.whereRules) {
			filter.where = {
				...filter.where,
				...this.whereRules,
			};
		}
	}


	public spawnSlice(where: Partial<T>) {
		if (this.whereRules) {
			where = {
				...where,
				...this.whereRules
			};
		}
		return new SourceRepoSlice<T, ID_NAME, INSERTABLE>({
			dataSource: this.dataSource,
			whereRules: where,
		})
	}

	// public addItem(item: T) {
	// 	this.dataSource.addItem(item)
	// }

	// public addItems(items: T[]) {
	// 	this.dataSource.addItems(items);
	// }

	// public upsertItem(id: IdType<T, ID_NAME>, updates: Partial<T>) {
	// 	const existingData = this.dataSource.getItems()
	// }


	// ---------------------------


	public get get() {
		const logicChain = SelectionLogicChain<T, [IdType<T, ID_NAME>]>((
			args,
			id: IdType<T, ID_NAME>
		) => {
			const filter = { where: {
				[this.dataSource.idPropName]: id
			}} as any;
			switch(args.mode) {
				case "async": return this._findOneAsync(filter, args);
				case "phased": return this._findOnePhased(filter, args);
				default: return this._findOne(filter, args);
			}
		});
		return logicChain.one;
	}

	// ---------------------------

	public get getAll() {
		const logicChain = SelectionLogicChain<T, [number] | []>((
			args,
			limit: number = -1
		) => {
			switch(args.mode) {
				case "async": return this._findAsync({limit}, args);
				case "phased": return this._findPhased({limit}, args);
				default: return this._find({limit}, args);
			}
		});
		return logicChain;// as Omit<typeof logicChain, "one">;
	}


	// ---------------------------


	public get find() {
		return SelectionLogicChain<T, [FilterLogic<T>]>((args, filterOrWhere) => {
			const filter = this.standardizeFilterArg(filterOrWhere);
			if (args.singular) {
				switch (args.mode) {
					case "async": return this._findOneAsync(filter, args);
					case "phased": return this._findOnePhased(filter, args);
					default: return this._findOne(filter, args);
				}
			}
			switch (args.mode) {
				case "async": return this._findAsync(filter, args);
				case "phased": return this._findPhased(filter, args);
				default: return this._find(filter, args);
			}
		})
	}


	protected async _findAsync(
		filter: ISelectionFilter<T>,
		args: Partial<ISelectionLogic>
	) {
		await this.dataSource.syncFromServer(filter, args);
		return this.dataSource.getItems(filter);
	}

	protected async _findOneAsync(
		filter: ISelectionFilter<T>,
		args: Partial<ISelectionLogic>
	) {
		filter.limit = 1;
		const out = await this._findAsync(filter, args);
		return out && out.length ? out[0] : undefined;
	}


	protected _find(
    filter: ISelectionFilter<T>,
		args: Partial<ISelectionLogic>
  ) {
		this.normalizeFilter(filter);
		this.dataSource.syncFromServer(filter, args);
		return this.dataSource.getItems(filter);
  }

	protected _findOne(
		filter: ISelectionFilter<T>,
		args: Partial<ISelectionLogic>
	) {
		filter.limit = 1;
		const out = this._find(filter, args);
		return out && out.length ? out[0] : undefined;
	}


	/** Phasing works by:
	 * 1.
	 */
  protected _findPhased(
    filter: ISelectionFilter<T>,
		args: Partial<ISelectionLogic>
  ) {
		this.normalizeFilter(filter);

		const findId = JSON.stringify(filter);
		const scope = DerivationManager._getCurrentDeriver();
		if (!scope) {
			return this._find(filter, { cacheUse: "only" });
		}

		const ddxStore = (scope as any).store as {
			repoFind: {
				findId: string,
				outSource: Source<SourceRepoResponse<T[]>>,
				deriver: DeriverScope,
			}
		};

		// if this same request has been used in the same loop, return the same source dep
		if ("repoFind" in ddxStore && ddxStore.repoFind.findId === findId) {
			ddxStore.repoFind.deriver.unfreeze();
			return ddxStore.repoFind.outSource.get();
		}

		const outSource = new Source<SourceRepoResponse<T[]>>();
		const fetchStateSource = new Source<
			"PENDING" | "COMPLETE" | ISourceRepoErrorResponse
		>("PENDING");

		ddxStore.repoFind = {
			findId,
			outSource,
			deriver: derive(() => {
				const fetchState = fetchStateSource.get();
				if (fetchState !== "COMPLETE") {
					return outSource.set(fetchState);
				}
				outSource.set(this._find(filter, { cacheUse: "only" }));
			})
		};

		// once complete, the deriver will keep the source updated
		this.dataSource.syncFromServer(filter, args).then(() => {
			fetchStateSource.set("COMPLETE");
		}).catch((err) => {
			fetchStateSource.set({ error: "NO_AUTH" });
		});

		return outSource.get();
	}

	protected _findOnePhased(
		filter: ISelectionFilter<T>,
		args: Partial<ISelectionLogic>
	) {
		filter.limit = 1;
		const out = this._findPhased(filter, args);
		return Array.isArray(out) ? out[0] : out;
	}

	// ---------------------------

	// public get update() {
	// 	return upsertLogicChain<
	// 		Identifiable<T, ID_NAME>
	// 	>("never", (logic, items) => {
	// 		if (Array.isArray(items) !== !!logic.multi) {
	// 			throw new Error(`Mismatch between items arg, and logic chain`)
	// 		}
	// 		if (Array.isArray(items)) {
	// 			switch (logic.mode) {
	// 				case "async": return this.dataSource.syncToServer(items, logic);
	// 				// case "phased": return this._findOnePhased(filter, args);
	// 				// default: return this._findOne(filter, args);
	// 			}
	// 		} else {
	// 			switch (logic.mode) {
	// 				case "async": return this.dataSource.syncToServer([items], logic).then((it) => it[0]);
	// 				// case "phased": return this._findOnePhased(filter, args);
	// 				// default: return this._findOne(filter, args);
	// 			}
	// 		}
	// 	})
	// }

	// public get create() {
	// 	return upsertLogicChain<INSERTABLE>("only", (logic, items) => {
	// 		if (Array.isArray(items) !== !!logic.multi) {
	// 			throw new Error(`Mismatch between items arg, and logic chain`)
	// 		}
	// 		if (Array.isArray(items)) {
	// 			switch (logic.mode) {
	// 				case "async": return this.dataSource.syncToServer(items, logic);
	// 				// case "phased": return this._findOnePhased(filter, args);
	// 				// default: return this._findOne(filter, args);
	// 			}
	// 		} else {
	// 			switch (logic.mode) {
	// 				case "async": return this.dataSource.syncToServer([items], logic).then((it) => it[0]);
	// 				// case "phased": return this._findOnePhased(filter, args);
	// 				// default: return this._findOne(filter, args);
	// 			}
	// 		}
	// 	})
	// }

	// public get upsert() {
	// 	return upsertLogicChain<
	// 		Identifiable<T, ID_NAME> | INSERTABLE
	// 	>("as-needed", (logic, items) => {
	// 		if (Array.isArray(items) !== !!logic.multi) {
	// 			throw new Error(`Mismatch between items arg, and logic chain`)
	// 		}
	// 		if (Array.isArray(items)) {
	// 			switch (logic.mode) {
	// 				case "async": return this.dataSource.syncToServer(items, logic);
	// 				// case "phased": return this._findOnePhased(filter, args);
	// 				// default: return this._findOne(filter, args);
	// 			}
	// 		} else {
	// 			switch (logic.mode) {
	// 				case "async": return this.dataSource.syncToServer([items], logic).then((it) => it[0]);
	// 				// case "phased": return this._findOnePhased(filter, args);
	// 				// default: return this._findOne(filter, args);
	// 			}
	// 		}
	// 	})
	// }


	// public GqlWhere

	// public gql(
	// 	filter: ISelectionFilter<T>,
	// 	join?: GqlRequestMap
	// ) {
	// 	const layer = this.gqlJoin(filter, join);
	// 	gqlRequest({ [this.repoId]: })
	// }

	public gqlJoin(
		filter: ISelectionFilter<T>,
		join?: GqlRequestMap
	): GqlLayer<T, ParseItem<T>> {
		return {
			props: [this.idPropName],
			...filter,
			parseItem: async (items) => {
				const ids = items.map((it) => it[this.idPropName]);
				await this.dataSource.runItemFetch({}, async () => ids);
				return this.dataSource.getItemsByIds(ids);
			},
			join
		}
	}
}
