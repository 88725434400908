import { url } from "inspector";


export const Validators = {
	maxLength(num: number) { return {
		test: new RegExp(`^.{0,${num}}$`),
		message: `Max length ${num} characters`
	}},

	minLength(num: number) { return {
		test: new RegExp(`^.{${num},}$`),
		message: `Min length ${num} characters`
	}},

	lengthRange(min: number, max: number) {
		return [
			Validators.minLength(min),
			Validators.maxLength(max)
		]
	},

	titleChars() { return {
		test: new RegExp(`^[\w\d\s]*$`)
	}},

	url() { return [
		{ test: /^https?:\/\//, message: "URL must begin with http:// or https://"},
		{ test: /\.[\w\d]{2,6}(?=(\/|$|\$|#))/, message: "URL must include a top-level domain such as: .com, .org, .edu"},
	]}
} as const;




export interface ITextValidation {
	minLength?: number;
	maxLength?: number;
	tests?: Array<{
		test: RegExp;
		message: string;
	}>
}
