import { LocationEntity as _ent, LocationManager as _manager } from "dot-earth-client";
import { App } from "../AppState";
import { ISourceRepoControllerArgs, jit, jitHas } from "helium-source-repo";
import { ILocation } from "dot-earth-middleware";
import * as mapboxgl from "mapbox-gl";
import { deriveAnchorless, haveClass, noDeps } from "helium-ui";


export class LocationManager extends _manager<LocationEntity> {
	protected getDefaultArgs() {
		return {
			repo: () => super.getDefaultArgs().repo,
			createController: (args) => new LocationEntity(args)
		}
	}

	// public get focused() {
	// 	return App.connections.focusedLocation;
	// }
}


export class LocationEntity extends _ent {
	constructor(args: ISourceRepoControllerArgs<ILocation, "id", ILocation>) {
		super(args);
	}

	// public get focused() {
	// 	return this === App.connections.focusedLocation;
	// }

	// public get connected() {
	// 	return this.getId() in App.connections.web;
	// }

	// public focus() {
	// 	App.connections.focusedLocation = this;
	// }

	// public get marker(): mapboxgl.Marker {
	// 	return jit(this, "marker", () => {
	// 		const marker = new mapboxgl.Marker({
	// 			scale: 0.5,
	// 			color: "inherit",
	// 		});
	// 		noDeps(() => {
	// 			haveClass(marker.getElement(), "--focused", this.focused);
	// 			haveClass(marker.getElement(), "--connected", this.connected);
	// 		});
	// 		marker.setLngLat([0,0]);
	// 		deriveAnchorless(() => {
	// 			const { lng, lat } = this;
	// 			lng && lat && marker.setLngLat({ lng, lat });
	// 		})
	// 		const markerEl = marker.getElement();
	// 		markerEl.style.cursor = "pointer";
	// 		markerEl.addEventListener("click", () => this.focus())
	// 		return marker;
	// 	})
	// }
}
