import { ITag, ITagArgs, LanguageCode } from "dot-earth-middleware";
import { SourceRepo, SourceRepoController } from "helium-source-repo";
import { SourceRepoControllersManager } from "helium-source-repo";
import { repoApiArgs } from "./EntityCommon";


export class TagManager<TYPE extends TagEntity = TagEntity> extends SourceRepoControllersManager<TYPE> {
	protected getDefaultArgs() {
		return {
			repo: new SourceRepo<ITag, "id", ITagArgs>({
				repoId: "Tag",
				idPropName: "id",
				...repoApiArgs("tag"),
			}),
			createController: (data) => new TagEntity(data),
		} as any;
	}
}


export class TagEntity
	extends SourceRepoController<ITag, "id">
	implements Record<keyof ITag, any>
{
	public _noHeliumProxy = true;

	// protected get lang(): LanguageCode { return "en"; }

	public get id() { return this.getId(); }
	public get createdAt(){ return this.getProp("createdAt"); }
	public get updatedAt(){ return this.getProp("updatedAt"); }
	public get desc(){ return this.getProp("desc"); }
	public get name() {
		// console.log(this.getProp("name"));
		return this.getProp("name");
	}
}
