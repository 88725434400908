import "./actionPage.scss";

import { ActionEntity } from "dot-earth-client";
import { div, br, h2, anchor, span, Source, noDeps } from "helium-ui";
import { App } from "MapApp/state/AppState";
import { Timeline } from "timeline"
import { renderOrgCard } from "../OrgCard/OrgCard";
import { renderCommonPage } from "../CommonPage/CommonPage";
import { renderPageEditButton } from "../PageEditMenu/PageEditMenu";
import { EditableText } from "../Editors/EditableText";
import { inLang } from "../InLang/InLang";
import { DotEarthMap } from "../Map/Map";
import { ConnectionState } from "MapApp/state/ConnectionState";
import { OrganizationEntity } from "MapApp/state/entities/Organization";


export interface IActionPanelProps {
	action?: ActionEntity | string;
}

export function renderActionPage(props: IActionPanelProps = {}) {
	return renderCommonPage({
		innards: renderActionPanel(props),
		back: { lastPage: true },
		// back: anchor("Back", { href: App.route.getUrlForRoute("map.actions") }, "<- Back to Map"),
	})
}

export function renderActionPanel(props: IActionPanelProps) {
	const txt = App.txt.actionPage;
	const act = getAct();
	console.log(act);

	const groupingId = () => `act-page-${act.id}`;

	const editLock = () => act.isTemplate() ? "ON" : null;
	const editMode = new Source(noDeps(editLock) === "ON");

	let _editor: ActionEntity | undefined;
	const editor = () => {
		return _editor = _editor || (act.isTemplate() ? act : act.getEditor(groupingId()));
	}

	return div("ActionDetails", {
		ddx: () => editMode.get() && App.edits.setGroupUpdateFn(groupingId(), async (edits) => {
			for (const [name, item] of edits) {
				if (item instanceof ActionEntity) {
					await item.save();
				}
			}
			return true;
		})
	},() => {
		if (act.isLoading()) { return "Loading..."; }

		// App.edits.setGroupUpdateFn(group, async (edits) => {
		// 	console.log(edits);
		// 	const update = {};
		// 	noDeps(() => {
		// 		edits.forEach(([name, edit]) =>
		// 			update[name] = { en: edit.currentValue }
		// 		)
		// 		console.log(update);
		// 		action.state.data?.upsert(update);
		// 	});
		// 	return true;
		// })

		type EditType = "title" | "briefDesc" | "mission" | "vision" | "gaps";
		function editableText(type: EditType, text?: () => string) {
			if (editMode.get() !== true) {
				return span([act[type]]);
			}
			return EditableText.renderNodeAndEditIcon({
				getUnmodifiedValue: () => inLang(act.getProp(type)),
				storage: {
					get: () => inLang(editor().getProp(type)),
					set: (val) => editor().stageEdits({[type]: {
						[App.custom.state.lang]: val
					}})
				},
				editLock: () => editLock(),
			})
			// const editMe = new EditableText({
			// 	getCurrentValue: text || (() => action[type]!),
			// 	group,
			// 	name: type
			// });
			// return [editMe.domNode(), editMe.editIcon()]
		}

		let timeline: Timeline;
		return [
			() => App.api.hasToken() && editLock() !== "ON" && renderPageEditButton({
				items: [
					{ type: "edit",
						storage: editMode
					}
				]
			}),
			h2(editableText("title")),
			br(0.5),
			div("BriefDesc", editableText("briefDesc")),
			br(1.5),
			div("Title", txt.label.collabList),
			br(0.5),
			div("OrgList", () => {
				const orgs = getOrgListDdx();
				return orgs.map((org) =>  div(`Org ${org.id}`, [
					div("Icon", {
						style: {
							backgroundImage: `url("${inLang(org.logoUrl)}")`,
						},
						innards: () => !org.logoUrl && (inLang(org.name) || "").charAt(0),
						ref: (target) => {
							App.coachMarks.add({
								target,
								mark: () => renderOrgCard({ org }),
								displayMode: "hover",
								grouping: "ActionDetails > OrgCard"
							})
						}
					}),
					div("Name", () => {
						const name = org.name;
						if (!org.name && !org.isLoading()) {
							console.error(`Org of id "${org.id}" has no name`)
						}
						return inLang(name);
					})
				]))
			}),

			div("OrgList", () => {
				const orgs = getOrgListDdx();
				return orgs.map((org) =>  div(`Org ${org.id}`, [
					div("Icon", {
						style: {
							backgroundImage: `url("${inLang(org.logoUrl)}")`,
						},
						innards: () => !org.logoUrl && (inLang(org.name) || "").charAt(0),
						ref: (target) => {
							App.coachMarks.add({
								target,
								mark: () => renderOrgCard({ org }),
								displayMode: "hover",
								grouping: "ActionDetails > OrgCard"
							})
						}
					}),
					div("Name", () => {
						const name = org.name;
						if (!org.name && !org.isLoading()) {
							console.error(`Org of id "${org.id}" has no name`)
						}
						return inLang(name);
					})
				]))
			}),

			// br(2),
			// div("Title", txt.label.benchmarks),
			// br(0.5),
			// div("BenchmarkTimeline", () => {
			// 	const benchmarks = action.benchmarksPhased;
			// 	if (benchmarks === "PENDING") { return "Loading Timeline..."}
			// 	if ("error" in benchmarks) { throw benchmarks.error; }


			// 	if (!benchmarks.length) {
			// 		const out = new Timeline({
			// 			items: action.benchmarksOld.map((it) => {
			// 				const [date, shortTitle] = it.split(/ *:: */);
			// 				return {
			// 					id: it,
			// 					shortTitle,
			// 					date: new Date(date)
			// 				}
			// 			})
			// 		});
			// 		return out.domNode;
			// 	}


			// 	if (!timeline) { timeline = new Timeline({items: []}) }
			// 	benchmarks.forEach((bench) => bench.id && timeline.upsertItem({
			// 		id: bench.id,
			// 		shortTitle: bench.shortTitle,
			// 		date: new Date(bench.endDate),
			// 		details: div("Benchmark", () => [
			// 			div("ItemTitle em-1-25", bench.shortTitle),
			// 			br(0.5),
			// 			div("Date em-0-9", `Goal Date: ${timeline.dateToString(new Date(bench.endDate))}`),
			// 			div("Status em-0-9", `Status: ${bench.completionStatus || "unknown"}`),
			// 			br(2),
			// 			div("Description", (bench.description as Innards) || "This item has no description")
			// 		]),
			// 	}));
			// 	return timeline.domNode;
			// }),

			br(2),
			div("Title", txt.label.mission),
			div("Mission",  editableText("mission")),

			br(2),
			div("Title", txt.label.vision),
			div("Vision", editableText("vision")),

			br(2),
			div("Title", txt.label.gaps),
			div("Gaps", editableText("gaps")),

			// br(2),
			// div("Title", txt.label.contact),
			// div("Contact", action.contact),
			br(2),
			div("Title", txt.label.map),
			div("MapArea", () => {
				const map = new DotEarthMap({
					connections: new ConnectionState<OrganizationEntity>({
						getItemsDdx: () => getOrgListDdx(),
						deriveConnections: "ALL_ITEMS"
					}),
					getLoc: (item) => item.findOneLoc()
				});
				return map.domNode;
			}),
			// div("MapArea", {
			// 	id: "ActionMap",
			// 	ref: () => App.connections.focusedAction = action,
			// 	// ddx: () => {
			// 	// 	const org = App.ents.org.find.one({ where: { name: action.organizations[0] }})
			// 	// 	if (!org) { return; }
			// 	// 	org.focus();
			// 	// 	org.centerMapOn();
			// 	// },
			// 	innards: App.route.id === "action-details" && App.map.domNode
			// }),
			anchor({ href: App.route.getUrlForRoute("map.actions") }, txt.btn.fullMap)
		]
	})


		// ----------------------------
	//		Helper Functions
	// ----------------------------

	function getAct() {
		let idOr = props.action
		if (idOr instanceof ActionEntity) {
			return idOr;
		}
		idOr = idOr ||  App.route.getPathSlice(1);
		if (idOr !== "new") {
			return App.ents.act.get(idOr);
		}
		return App.ents.act.createTemplateController({
			register: { group: `action-page-create`, name: "action" }
		});
	}

	function getOrgListDdx() {
		return act.memberRoles.getAll().map((role) =>
			role.holderId && App.ents.org.get(role.holderId)
		).filter((it) => !!it) as OrganizationEntity[];
	}

}
