import "./locationChooser.scss";

import { br, button, div, noDeps, setInnards, Source } from "helium-ui";
import { App } from "MapApp/state/AppState";
import { inLang } from "../InLang/InLang";
import { LocationEntity } from "MapApp/state/entities/Location";
import { ConnectionState } from "MapApp/state/ConnectionState";
import { DotEarthMap } from "../Map/Map";
import { IEditContextArgs } from "helium-source-repo";
import { renderEntTextEditor } from "../Editors/EntTextfieldEditor";


export interface ILocationChooserArgs {
	onLocationConfirm: (loc: LocationEntity) => any;
	edits?: IEditContextArgs & { allowEditing: () => boolean};
}


export function renderLocationChooser(args: ILocationChooserArgs) {
	let map: DotEarthMap<LocationEntity>;
	const _moveLocSrc = new Source<undefined | LocationEntity>();
	const connections = new ConnectionState<LocationEntity>({
		getItemsDdx: () => {
			const moveLoc = _moveLocSrc.get();
			if (moveLoc) { connections.focus = moveLoc; }
			return moveLoc ? [moveLoc] : App.ents.loc.getAll();
		}
	});
	return div("LocationChooser", {
		// ddx: () => {
		// 	if (connections.focus?.hasEdits) {
		// 		_moveLocSrc.set(connections.focus);
		// 	}
		// }
	},[
		div("SelectedLocation", () => [
			div("LocationDetails", () => {
				const loc = connections.focus;
				if (!loc) { return "No location selected"; }
				const editArgs = {
					item: loc,
					multiLang: true,
					getEditor: () => {
						if (!args.edits || !args.edits.allowEditing || !args.edits.allowEditing()) {
							return false;
						}
						return loc.getEditor(args.edits?.register);
					}
				}
				return [
					() => renderEntTextEditor({...editArgs, prop: "name"}),
					br(1.5),
					() => `type: ${loc.type}`,
					br(1.5),
					() => renderEntTextEditor({...editArgs, prop: "desc"}),
				]
			}),
			div("Actions", [
				button("CreateLocation", {
					onPush: () => {
						let moveLoc = _moveLocSrc.peek();
						if (moveLoc) {
							console.log("cancel edits", moveLoc.cancelEdits());
							_moveLocSrc.set(undefined);
							return;
						}
						moveLoc = App.ents.loc.createTemplateController({
							register: args.edits?.register
						});
						const { lat, lng } = map.mapbox.getCenter();
						moveLoc.stageEdits({
							lat,
							lng,
							type: "building"
						});
						_moveLocSrc.set(moveLoc);
					},
					innards: _moveLocSrc.get() ? "Discard new location" : "Create a new location"
				}),
				button("SelectLocation", {
					ddxClass: () => {
						return connections.focus && "--enabled"
					},
					onPush: () => {
						console.log(connections.focus?.data?.toObject())
						connections.focus && args.onLocationConfirm(connections.focus)
					},
					innards: "Confirm Location Choice"
				}),
			])
		]),
		div("Map", {
			ref: (ref) => {
				map = new DotEarthMap({
					connections,
					getLoc: (loc) => loc
				});
				map.mapbox.on("move", () => {
					const loc = _moveLocSrc.peek();
					if (loc && loc.editing) {
						const { lat, lng } = map.mapbox.getCenter();
						loc.stageEdits({ lat, lng });
					}
				})
				setInnards(ref, map.domNode);
				map.mapbox.resize();
			}
		})
	])
}
