import "./routeNavigator.scss";

import { anchor, br, div, span } from "helium-ui";
import { App } from "MapApp/state/AppState";
import { RouteId } from "MapApp/state/RouteState";


export function renderRouteNavigator() {
	return div("RouteNavigator", {
		ref: (ref) => {
			App.coachMarks.add({
				target: ref,
				displayMode: "hover",
				positioning: "bottom",
				mark: () => div("RouteNavPanelContainer", renderRouteNavigatorPanel()),
			})
		}
	})
}

export function renderRouteNavigatorPanel() {
	return div("RouteNavigatorPanel", [
		renderRouteLink({ routeId: "map" }),
		renderRouteLink({ routeId: "action-details", label: "actions" }),
		renderRouteLink({ routeId: "org-details", label: "organizations" }),
		App.api.currentUser && renderRouteLink({ routeId: "admin" }),
		// renderRouteLink({ routeId: "user" }),
	])

	function renderRouteLink(args: {
		routeId: RouteId,
		label?: string,
	}) {
		const {routeId, label} = args;
		const config = App.route.getConfig(routeId);
		// const config = App.route.getRouteConfigForUrl(args.href);
		return anchor("RouteLink", {
			href: App.route.getUrlForRoute(routeId),
			ddxClass: () => App.route.id.startsWith(routeId) && "--selected",
			innards: [
				() => config?.icon ? App.icon.getIconSpan(config.icon) : "A",
				span("em-0-8", label || routeId)
			]
		})
	}
}
