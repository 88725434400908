import { EditContext, IEditContextArgs, isPhaseResponse, SourceRepoPhaseResponse } from "helium-source-repo";
import { deriveDomAnchored, noDeps, SourceMap } from "helium-ui";
import { IEditIconArgs, renderEditIcon } from "./EditIcon";



export type ListEditArgs<T> = IEditContextArgs & {
	getItems: () => T[] | SourceRepoPhaseResponse;
	anchor: HTMLElement;
}

export abstract class ListEditContext<T, IDABLE = T> extends EditContext<T[]> {

	public abstract findItem(idOr: IDABLE): T | undefined;
	public abstract createItem(idOr: IDABLE): T;

	public itemMap = new SourceMap<T, "LEAVE" | "DROP" | "ADD">();

	constructor(protected args: ListEditArgs<T>) {
		super(args);
		const { anchor } = args;
		deriveDomAnchored(anchor, () => this.resyncToReality());

		anchor.setAttribute("tabIndex", "0");

		let blurListener: EventListener | undefined;
		deriveDomAnchored(anchor,() => {
			if (this.editing) {
				anchor.focus();

				if (!blurListener) {
					document.addEventListener("mousedown", blurListener = ({target}) => {
						if (noDeps(() => this.hasEdits)) { return; }
						if (target instanceof Element && anchor.contains(target)) { return; }
						this.editing = false;
					})
				}
			} else if (blurListener) {
				document.removeEventListener("mousedown", blurListener);
				blurListener = undefined;
			}
		});

		// anchor.addEventListener("blur", () => {
		// 	!this.hasEdits && (this.editing = false);
		// })
	}

	public updateHasEdits() {
		this.hasEdits = this.itemMap.keys().some((key) => {
			const value = this.itemMap.get(key)
			return value === "ADD" || value === "DROP";
		});
	}

	public resyncToReality() {
		const itemList = this.args.getItems();
		if (isPhaseResponse(itemList)) { return; }
		!this.editing && noDeps(() => this.itemMap.clear());

		itemList.forEach((it) => this.itemMap.set(it, "LEAVE"));
	}

	public get currentValue() {
		return this.itemMap.keys();
	}

	public get heldItemList() {
		return this.currentValue.filter((it) => this.itemMap.get(it) !== "DROP");
	}

	public _cancelEdits(): boolean {
		this.itemMap.clear();
		noDeps(() => this.resyncToReality());
		return true;
	}

	public editIcon(args: Omit<IEditIconArgs, "context"> = {}) {
		return renderEditIcon({
			...args,
			context: this
		});
	}

	public addItem(idable: IDABLE) {
		const item = this.findItem(idable);
		if (!item) {
			const template = this.createItem(idable);
			this.itemMap.set(template, "ADD");
			return;
		}
		if (this.itemMap.get(item) === "DROP") {
			this.itemMap.set(item, "LEAVE");
		}
	}

	public dropItem(idable: IDABLE) {
		const item = this.findItem(idable);
		if (!item) { return; }
		switch (this.itemMap.get(item)) {
			case "DROP": return;
			case "ADD": return this.itemMap.delete(item);
			case "LEAVE": return this.itemMap.set(item, "DROP");
		}
	}


	public hasItem(idable: IDABLE, mode: "ownership" | "knowledge" | "added" | "dropped" | "already") {
		const editState = this.getEditStateForItem(idable);
		switch (mode) {
			case "ownership": return editState === "ADD" || editState === "LEAVE";
			case "knowledge": return !!editState;
			case "added": return editState === "ADD";
			case "dropped": return editState === "DROP";
			case "already": return editState === "LEAVE";
		}
	}

	public getEditStateForItem(idable: IDABLE) {
		const target = this.findItem(idable);
		return !target ? undefined : this.itemMap.get(target);
	}
}
