import { DotEarthClient, ActionManager, LocationUseManager, RoleManager, TagManager, UserManager, DotEarthApi, UserEntity } from "dot-earth-client";
import { jit } from "helium-source-repo";
import { RouteState } from "./RouteState";
import { TextContentState } from "./TextContent.state";
import { CoachMarks } from "MapApp/components/CoachMarks/CoachMarks";
import { TagUseManager } from "../../../../client/src/entities/TagUse.ents";
import { OrganizationManager } from "./entities/Organization";
import { AppEditingState } from "./EditingState";
import { ModalManager } from "MapApp/components/Modals/Modals";
import { LocationManager } from "./entities/Location";
import { syncWithLocalStorage } from "helium-ui";
import { CustomizationState } from "./CustomizationState";
import { IconState } from "./Icons";



export class _AppState extends DotEarthClient {
	public get ents() {
		return jit(this, "ents", () => ({
			// poi: new PoiManager(),
			act: new ActionManager(),
			tag: new TagManager(),
			tagUse: new TagUseManager(),
			// benchmark: new BenchmarkManager(),
			org: new OrganizationManager(),
			role: new RoleManager(),
			loc: new LocationManager(),
			locUse: new LocationUseManager(),
			user: new UserManager(),
		} as const));
	}

	public custom = new CustomizationState();

	public icon = new IconState();

	// protected _editor = new Source(true);
	// public get editorMode() { return this._editor.get(); }

	public edits = new AppEditingState();

	// public get map() {
	// 	return jit(this, "map", () => new DotEarthMap());
	// }

	// public get connections() {
	// 	return jit(this, "connections", () => new ConnectionState());
	// }
	public get api() {
		return jit(this, "appState-api", () => {
			const out = super.api as DotEarthApi<UserEntity>;
			syncWithLocalStorage("DotEarth-api-loginState", out.loginState as any);
			return out;
		})
	}

	public get txtRaw() {
		return this.textContent.proxy();
	}

	public get txt() {
		return this.textContent.spanProxy();
	}

	public get textContent() {
		return jit(this, "textContent", () => new TextContentState());
	}

	public route = new RouteState();

	public coachMarks = new CoachMarks();
	public modals = new ModalManager();
}

export const AppState = new _AppState();
export const App = AppState;

(window as any).App = App;
