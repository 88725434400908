

export const DotEarthApiPath = {
	tagUse: {
		massJoinUpdate: "/tag-use/mass-join-update"
	},
	org: {
		create: "/organization/create",
		sync: "/organization/sync"
	}
}
