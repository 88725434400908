import "./modals.scss";

import { noDeps, SourceArray, SourceMap } from "helium-ui";
import { div } from "helium-ui";


export class ModalManager {
	public modalStack = new SourceMap<Modal, void>();

	constructor() {
		document.body.addEventListener("click", ({target}) => {
			const modal = this.currentModal;
			if (modal?.clickOutClose && !modal.domNode.contains(target as any)) {
				modal.close();
			}
		});
		document.body.addEventListener("keydown", ({key}) => {
			key === "Escape" && this.currentModal?.close();
		})
	}

	protected _domNode: HTMLElement;
	public get domNode() {
		return this._domNode = this._domNode || div("ModalManager", {
			ddxClass: () => this.modalStack.keys().length ? "--hasModals" : "",
			innards: () => this.currentModal?.domNode,
		});
	}

	public get currentModal() {
		const modals = this.modalStack.keys();
		return modals[modals.length - 1];
	}

	public add(args: Omit<IModalArgs, "manager">) {
		const modal = new Modal({
			...args,
			manager: this,
		});
		modal.open();
		return modal;
	}
}

export type ContainerType = "tightCard" | "error";

export interface IModalArgs {
	manager: ModalManager;
	domNode: (modal: Modal) => HTMLElement;
	clickOutClose?: boolean | (() => boolean),
	container?: ContainerType | {
		type: ContainerType;
		resizable?: boolean;
		onResize?: () => void;
	},
	onClose?: () => any,
}

export class Modal {
	constructor(protected args: IModalArgs) {

	}

	public get clickOutClose(): boolean {
		const clickOut = this.args.clickOutClose;
		return !!(typeof clickOut === "function" ? clickOut() : clickOut);
	}


	public open() {
		noDeps(() => {
			this.args.manager.modalStack.delete(this);
			this.args.manager.modalStack.set(this);
		})
	}

	public get containerArgs(): undefined | Extract<IModalArgs["container"], object> {
		const out = this.args.container;
		return !out ? undefined : (typeof out === "object" ? out : { type: out })
	}


	protected _domNode: HTMLElement | undefined;
	public get domNode() {
		if (this._domNode) { return this._domNode; }
		this._domNode = this.args.domNode(this);
		const { containerArgs } = this;
		if (containerArgs) {
			const { type, resizable, onResize } = containerArgs;
			if (type === "tightCard" || type === "error") {
				this._domNode = div("ModalContainer", {
					class: [
						type,
						resizable ? "resizable" : ""
					],
					innards: this._domNode,
				});

				onResize && (new ResizeObserver(onResize)).observe(this._domNode);
			}
		}
		return this._domNode;
	}

	public close() {
		this.args.manager.modalStack.delete(this);
		if (this.args.onClose) { this.args.onClose(); }
	}
}
