import { DerivationManager } from "helium-sdx";



export interface ISourceRepoErrorResponse {
	error: "NO_AUTH" | {
		message: string;
	}
}

export type SourceRepoPhaseResponse = "PENDING" | ISourceRepoErrorResponse;
export type SourceRepoResponse<RESP> = SourceRepoPhaseResponse | RESP;


export function isPhaseResponse<T>(checkMe: SourceRepoResponse<T>): checkMe is SourceRepoPhaseResponse {
	return checkMe && (checkMe === "PENDING" || (typeof checkMe === "object" && "error" in checkMe));
}



export type IdType<T extends Object, ID_NAME extends keyof T> = T[ID_NAME];





export interface DetailedPhaseResponse<T> {
	cache: T | null,
  status: "PENDING" | "VERIFIED" | "REFRESHED",
  data(): T | null,
  err: any,
	promise: Promise<T | null>
}

export function createDetailedPhaseResponse(id: string) {
	const scope = DerivationManager._getCurrentDeriver();
	const store = scope.getStore() as {
		heSdxDetailedPhaseResp: Record<string, DetailedPhaseResponse<any>>
	};
	const map = store.heSdxDetailedPhaseResp = store.heSdxDetailedPhaseResp || {};


}
