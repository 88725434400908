import { DerivationAnchor, DerivationManager, derive, noDeps, Source  } from "helium-sdx";
import { EditingState, EditContextOutline } from "./EditsState";



export type EditLockType = "ON" | "OFF" | null;
export type EditorRegistration = "NO_GROUP" | {
	group: string;
	name?: string;
}

export type IEditContextArgs = {
	editLock?: () => EditLockType;
	register?: EditorRegistration;
	ddxAnchor?: DerivationAnchor;
}

export abstract class EditContext<T = any> implements EditContextOutline<T> {

	protected abstract _cancelEdits(): boolean;
	public abstract updateHasEdits(): void;
	public abstract save: undefined | (() => Promise<boolean>);

	constructor(protected editCtxArgs: IEditContextArgs){
		const currentAnchor = DerivationManager._getCurrentDeriver();
		if (editCtxArgs.editLock) {
			derive({
				anchor: editCtxArgs.ddxAnchor || currentAnchor,
				fn: () => {
					const val = editCtxArgs.editLock!();
					this._editLock.set(val);
					if (val) {
						this.editing = val === "ON";
					}
				}
			})
		}
		setTimeout(() => {
			derive({
				anchor: editCtxArgs.ddxAnchor || currentAnchor,
				fn: () => {
					if (!this.updateHasEdits) {
						console.error(this);
						throw new Error(`this.updateHasEdits() not defined`);
					}
					this.updateHasEdits();
				}
			});
		}, 0)
	}

	public _editLock = new Source<EditLockType>();
	public get editLock() { return this._editLock.get(); }

	protected get register() { return this.editCtxArgs.register }
	public get batching() {
		const {register} = this;
		return typeof register === "object" ? register : undefined;
	}

	// protected _currentValue = new Source<T>();
	public abstract get currentValue(): T | undefined;// { return this._currentValue.get(); }
	// public set currentValue(val) { this._currentValue.set(val); }

	protected _editing = new Source(false);
	public get editing() { return this._editing.get(); }
	public set editing(val) {
		noDeps(() => {
			if (this.hasEdits) {
				this.updateHasEdits();
				if (this.hasEdits) {
					console.warn(`Editing can not be disabled for a context which has live edits.  You must instead use cancelEdits()`, this);
					return;
				}
			}
			if (this.editLock && (this.editLock === "ON" !== val)) {
				return;
			}
			this._editing.set(val);
		})
	}

	protected _hasEditsLocal: Source<boolean>;
	public get hasEdits() {
		if (this.register) {
			return EditingState.get().hasOpenEdit(this);
		}
		this._hasEditsLocal = this._hasEditsLocal || new Source(false);
		return this._hasEditsLocal.get();
	}
	public set hasEdits(hasEdit: boolean) {
		if (this.register) {
			EditingState.get().setOpenEdit(this, hasEdit);
		}
		this._hasEditsLocal = this._hasEditsLocal || new Source();
		this._hasEditsLocal.set(hasEdit);
	}


	public cancelEdits() {
		const worked = this._cancelEdits();
		worked && (this.editing = false);
		return worked;
	}
}
