import { TagEntity, ActionEntity } from "dot-earth-client";
import { derive, deriveAnchorless, noDeps, Source } from "helium-ui";
import { ConnectionState } from "MapApp/state/ConnectionState";
import { OrganizationEntity } from "MapApp/state/entities/Organization";
import { App } from "MapApp/state/AppState";
import { isPhaseResponse } from "helium-source-repo";

export class MapExploreConnectionState extends ConnectionState<
	OrganizationEntity, {
		tag: TagEntity;
		action: ActionEntity
	}
> {
	public filterMode = new Source<"tag" | "action">("tag");

	constructor() {
		super({
			getItemsDdx: () => App.ents.org.getAll(),
		})
		deriveAnchorless(() => {
			const { filter } = this;
			const mode = this.filterMode.get();
			noDeps(() => this.itemStates.forEach((_, item) => {
				this.setConnected(item, false);
			}));
			if (!filter[mode]) { return; }

			setTimeout(() => noDeps(() => {
				if (this.focus && this.isConnected(this.focus) === false) {
					this.focus = undefined
				}
			}), 250);

			derive(() => {
				if (mode === "tag") {
					const uses = App.ents.tagUse.find.phased({where: { tagId: this.filter.tag!.id }});
					if (Array.isArray(uses)) {
						uses.map((it) => derive(() => {
							const org = it!.targetId ? App.ents.org.get(it!.targetId) : undefined;
							org && this.setConnected(org, true);
						}));
					}

				} else if (mode === "action") {
					const members = filter.action!.memberRoles.getAllPhased();
					if (isPhaseResponse(members)) { return; }
					members.forEach((member) => derive(() => {
						const org = member.holderId && App.ents.org.get(member.holderId);
						org && this.setConnected(org, true);
					}));

				} else {
					throw new Error(`Unknown state`)
				}
			})
		});
	}
}
