import { UserEntity } from "dot-earth-client";
import { br, div } from "helium-ui";
import { App } from "MapApp/state/AppState";
import { EditableText } from "../Editors/EditableText";




export function renderUserPage(props: {
	user?: UserEntity | string;
} = {}) {
	// const txt = App.txt.actionPage;
	const user = getUser();
	const group = () => `user-page-${user.id}`;
	let _editor: UserEntity | undefined;

	return div("UserPage", () => {
		if (user.isLoading()) { return "Loading..."; }

		return [
			() => user.email,
			div("Names", [
				["First Name:", "first"],
				["Last Name:", "last"],
				["Alias:", "alias"],
			].map(([label, key]) => [
				label,
				() => EditableText.renderNodeAndEditIcon({
					register: {
						name: key,
						group: group()
					},
					getCurrentValue: () => user.name && user.name[key],
					storage: {
						get: () => editor().name && editor().name![key],
						set: (value: string | undefined) => {
							if (!value) {
								return editor().unstageEdits({name: { [key]: undefined }})
							}
							editor().stageEdits({name: { [key]: value }});
						}
					},
					placeholder: () => " -- ",
				}),
				br(),
			]))

		]
	})


	// ----------------------------
	//		Helper Functions
	// ----------------------------

	function getUser() {
		let idOr = props.user
		if (idOr instanceof UserEntity) {
			return idOr;
		}
		if (!idOr) {
			idOr = App.route.getPathSlice(1);
		}
		return App.ents.user.get(idOr);
		// if (idOr !== "new") {
		// }
		// return App.ents.user.createTemplateController({
		// 	register: { group: `action-page-create`, name: "new-user" }
		// });
	}


	function editor() {
		if (_editor) { return _editor; }
		App.edits.setGroupUpdateFn(group(), async (edits) => {
			for (const [_, edit] of edits) {
				"save" in edit && edit.save && await edit.save();
			}
			return true;
		})
		return _editor = user.getEditor({
			name: "",
			group: group()
		})
	}
}
