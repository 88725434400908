import { SourceRepoController, ISourceRepoControllerArgs } from "./SourceRepoController";
import { IdType, isPhaseResponse, SourceRepoResponse} from "../SelectionArgs"
import { SourceRepoSlice } from "../Repos/SourceRepoSlice";
import { SelectionLogicChain } from "../SelectionLogicChain";
import { noDeps } from "helium-sdx";
import { FilterLogic, ISelectionFilter } from "../Selections/SelectionFilter";
import { EditorRegistration } from "../Editing";



export interface IControllersCollection<ID_TYPE, T> {
  byId: Map<ID_TYPE, T>;
  [key: string]: Map<ID_TYPE, T>;
}


export type DataTypeFromController<B> = B extends SourceRepoController<infer T, any, any> ? T : never;
export type IdNameFromController<B> = B extends SourceRepoController<any, infer T, any> ? T : never;
export type InsertableFromController<B> = B extends SourceRepoController<any, any, infer T> ? T : never;

export interface ISourceRepoControllersManagerArgs<
  T extends SourceRepoController<any, any, any>,
  DATA extends object,
  ID extends keyof DATA,
  INSERTABLE extends Partial<DATA>,
> {
  repo: SourceRepoSlice<DATA, ID, INSERTABLE> | (() => SourceRepoSlice<DATA, ID, INSERTABLE>);
  createController: (args: ISourceRepoControllerArgs<DATA, ID, INSERTABLE>) => T;
  collection?: IControllersCollection<IdType<DATA, ID>, T>
}


export abstract class SourceRepoControllersManager<
  T extends SourceRepoController<any, any, any>,
  DATA extends object = DataTypeFromController<T>,
  ID extends keyof DATA = IdNameFromController<T>,
  INSERTABLE extends Partial<DATA> = InsertableFromController<T>,
> {
  protected abstract getDefaultArgs(): ISourceRepoControllersManagerArgs<T, DATA, ID, INSERTABLE>;

  protected collection: IControllersCollection<IdType<DATA, ID>, T>;
  protected args: ISourceRepoControllersManagerArgs<T, DATA, ID, INSERTABLE>

  constructor (args?: ISourceRepoControllersManagerArgs<T, DATA, ID, INSERTABLE>) {
    this.args = args || this.getDefaultArgs();
    this.collection = this.args.collection || {
      byId: new Map()
    };
  }

  protected _repo: SourceRepoSlice<DATA, ID, INSERTABLE>;
  public get repo(): SourceRepoSlice<DATA, ID, INSERTABLE> {
    if (this._repo) { return this._repo; }
    if (typeof this.args.repo === "function") {
      return this._repo = this.args.repo();
    }
    return this._repo = this.args.repo;
  }

  public get idPropName(): ID {
    return this.repo.idPropName
  }

  public get(id: IdType<DATA, ID>) {
    this.assert(id);
    return this.collection.byId.get(id)!;
  }

  public createTemplateController(args?: {
		idPropValue?: IdType<DATA, ID>;
		register?: EditorRegistration;
	}) {
    return this.args.createController({
			...{[this.idPropName]: args?.idPropValue || "create"} as Record<ID, IdType<DATA, ID>>,
      repo: this.repo as any,
			manager: this as any,
			editor: {
				for: "NEW",
				register: args?.register
			}
    });
  }

	public addController(cont: T) {
		noDeps(() => {
			const id = cont.getId();
			if (this.collection.byId.has(id)) {
				throw new Error(`Can not add controller of id type which already exists: ${id}`);
			}
			this.collection.byId.set(id, cont);
		})
	}

	public dropController(id: IdType<DATA, ID>) {
		if (this.collection.byId.has(id) === false) {
			throw new Error(`Can not drop "${id}", it is not managed by this manager`);
		}
		this.collection.byId.delete(id);
	}

  // public get get_() {
  //   const logicChain = SelectionLogicChain<T, [IdType<DATA, ID>]>((
	// 		args,
	// 		id: IdType<DATA, ID>
	// 	) => {
	// 		const filter = { where: {
	// 			[this.idPropName]: id
	// 		}} as any;
	// 		switch(args.mode) {
	// 			case "async": return this._findOneAsync(filter, args);
	// 			case "phased": return this._findOnePhased(filter, args);
	// 			default: return this._findOne(filter, args);
	// 		}
	// 	});
	// 	return logicChain.one;
  // }

  protected getFromItem(item: DATA) {
    return this.get((item as any)[this.repo.idPropName]);
  }

  public assert(assertMe: IdType<DATA, ID> | ISelectionFilter<DATA>) {
    // throw new Error("Fix me");
    if (typeof assertMe === "object") {
      if (assertMe === null) {
        throw new Error(`'null' is not a valid ID or filter`);
      }
      this.find(assertMe);
    } else {
      if (this.collection.byId.has(assertMe)) { return; }
      this.collection.byId.set(
        assertMe,
        this.args.createController({
          [this.idPropName]: assertMe,
					manager: this,
          repo: this.repo
        } as any),
      );
    }
  }

  // -----------------

  public get find() {
    return SelectionLogicChain<T, [FilterLogic<DATA>]>((args, filter) => {
      const resp = this.repo.find.withLogic(args)(filter);
      if (isPhaseResponse(resp)) {
        return resp as SourceRepoResponse<T>;
      }

      if (args.singular) {
        const getItem = (data: DATA) => !data ? null : this.getFromItem(data);
        if (resp instanceof Promise) {
          return resp.then(getItem);
        }
        return getItem(resp);
      } else {
        if (resp instanceof Promise) {
          return resp.then((items) => items.map(this.getFromItem.bind(this)));
        }
        return resp.map(this.getFromItem.bind(this));
      }
    });
  }

  public getAll(limit: number = -1) {
    const items = this.repo.getAll();//  (limit);
    return items.map((it) => this.get((it as any)[this.repo.idPropName]))
  }

  public getAllPhased(limit: number = -1) {
    const items = this.repo.getAll.phased(limit);
    if (isPhaseResponse(items)) { return items; }
    return items.map((it) => this.get((it as any)[this.repo.idPropName]))
  }

  // -----------------

  public createSlice(where: Partial<DATA>): this {
    const args: ISourceRepoControllersManagerArgs<T, DATA, ID, INSERTABLE> = {
      repo: this.repo.spawnSlice(where),
      createController: this.args.createController,
      collection: this.args.collection,
    }
    const ThisClass = (this as any).__proto__.constructor;
    return new ThisClass(args);
  }

}
