import { DotEarthApi } from "dot-earth-client";
import { ITextContent, TextContentLang } from "dot-earth-middleware";
import { deriveAnchorless, Source, span } from "helium-ui";
import { App } from "./AppState";



export type Permeate<R extends object, L = string> = {
	[key in keyof R]: R[key] extends object ? Permeate<R[key], L> : L;
}


export class TextContentState {
	protected textContentObj = new Source<ITextContent>();
	protected _lang = new Source<TextContentLang>("en_US");

	constructor() {
		this.startLangDeriver();
	}

	public setLang(newVal: TextContentLang) {
		this._lang.set(newVal);
	}

	public get lang() {
		return this._lang.get();
	}


	public proxy(): Permeate<ITextContent, () => string> {
		return this.createProxy([]);
	}

	public spanProxy(): Permeate<ITextContent, () => HTMLSpanElement> {
		return this.createProxy([], (txt, chain) => span({
			innards: txt,
			ddx: (ref) => {
				App.custom.state.translatorTooltips ? (
					ref.setAttribute("title", chain.join("."))
				):(
					ref.removeAttribute("title")
				)
			}
		}));
	}

	protected createProxy(chain: string[], returnFn?: (txt: string, chain: string[]) => any) {
		return new Proxy(() => {
			let ptr = this.textContentObj.get();
			for (let i = 0; i < chain.length && ptr; i++) {
				ptr = ptr[chain[i]];
			}
			if (typeof ptr === "object") {
				console.error(ptr);
				throw new Error(`Can not resolve text path to string: ${chain.join(".")}`)
			}
			return !returnFn ? ptr : returnFn(ptr as any, chain);
		}, {
			get: (_, key) => {
				if (typeof key === "string") {
					return this.createProxy([...chain, key], returnFn);
				}
				console.log(key);
			}
		})
	}

	protected startLangDeriver() {
		deriveAnchorless(async () => {
			const lang = this.lang;
			// no deps passed this point
			const resp = await fetch(`${DotEarthApi.host}/text-content`);
			const allText = await resp.json();
			this.textContentObj.set(allText[lang]);
		})
	}
}
