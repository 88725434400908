import "./editMode.scss";

import { div, SourceMap, button } from "helium-ui";
import { App } from "MapApp/state/AppState";


export function renderEditModePanel() {
	return div("EditModePanel", {
		ddxClass: () => App.edits.countOpenEdits() > 0 ? "--show" : "",
	},[
		button("Discard", {
			onPush: () => App.edits.cancelAll(),
			innards: "Discard"
		}),
		button("Save", {
			onPush: () => App.edits.saveAll(),
			innards: "Save Changes"
		}),
	])
}
