import { SourceRepoController } from "helium-source-repo";
import { SourceRepoControllersManager, SourceRepo, jit } from "helium-source-repo";
import { IAction, IActionArgs, LanguageCode } from "dot-earth-middleware";
import { RoleEntity, RoleManager } from "./Role.ents";
import { DotEarthClient } from "../Client";
import { repoApiArgs } from "./EntityCommon";


export class ActionManager<TYPE extends ActionEntity>
	extends SourceRepoControllersManager<TYPE>
{
	protected getDefaultArgs() {
		return {
			repo: new SourceRepo<IAction, "id", IActionArgs>({
				repoId: "Action",
				idPropName: "id",
				...repoApiArgs("action"),
			}),
			createController: (data) => new ActionEntity(data),
		} as any;
	}
}


export class ActionEntity
	extends SourceRepoController<IAction, "id">
	implements Record<keyof IAction, any>
{
	public _noHeliumProxy = true;

	public get lang(): LanguageCode { return "en"; }

	protected getMultiLang<KEY extends keyof IAction>(key: KEY): undefined | null | string {
		const value = this.getProp(key);
		if (!value) { return value as any; }
		return value[this.lang];
	}

	// public get idNoPrefix() { return this.id.replace("act-", ""); }

	public get id() { return this.getId(); }
	public get createdAt() { return this.getProp("createdAt"); }
	public get type() { return this.getProp("type"); }
	public get updatedAt() { return this.getProp("updatedAt"); }
	public get title() { return this.getMultiLang("title"); }
	public get briefDesc() { return this.getMultiLang("briefDesc"); }
	public get mission() { return this.getMultiLang("mission"); }
	public get vision() { return this.getMultiLang("vision")}
	public get gaps() { return this.getMultiLang("gaps") }

	public get memberRoles(): RoleManager<RoleEntity> {
		return jit(this, "memberRoles", () =>
			DotEarthClient.get().ents.role.createSlice({contextId: this.id, type: "member"})
		);
	}
}
