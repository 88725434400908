

export function createIdFromObject(obj?: object): string {
  const out = {};
  obj && Object.keys(obj).sort().map((key) => {
    const value = obj[key];
    switch (typeof value) {
      case "function": out[key] = "__FN__"; break;
      case "object": out[key] = createIdFromObject(value); break;
      default: out[key] = value;
    }
  });
  return JSON.stringify(out);
}


// // test
// console.log(createIdFromObject({
//   z: 1,
//   b: {
//     lala: "cecac",
//     fff: true
//   }
// }) === createIdFromObject({
//   b: {
//     fff: true,
//     lala: "cecac",
//   },
//   z: 1,
// }))
