import { SourceMap } from "helium-sdx";


export type EditContextOutline<T = any> = {
	cancelEdits(): boolean;
	batching: undefined | {
		group: string | undefined;
		name?: string | undefined;
	};
	currentValue: T | undefined;
	save?: () => Promise<boolean>;
	editing: boolean;
	hasEdits: boolean;
}


export type GroupUpdateFn = (edits: Array<[string, EditContextOutline]>) => Promise<true | {
	updated: EditContextOutline[];
	errs: Array<{ err: any; edits: EditContextOutline[]}>
}>;

const GLOBAL_STORE: any = typeof window !== "undefined" ? window : global;

export class EditingState {
	public static get() {
		if (!GLOBAL_STORE._singleton) {
			throw new Error(`No EditingState has been created yet`)
		}
		return GLOBAL_STORE._singleton;
	}
	public static set(val: EditingState) {
		if (GLOBAL_STORE._singleton) { throw new Error(`Editing state is a singleton, and has already been constructed.`)}
		GLOBAL_STORE._singleton = val;
	}

	constructor() {
		EditingState.set(this);
	}

	protected openEdits = new SourceMap<EditContextOutline, void>();
	protected groupUpdateFns = new Map<string, GroupUpdateFn>();

	public countOpenEdits(group?: string) {
		if (!group) { return this.openEdits.size(); }
		return this.openEdits.keys().filter((edit) => edit.batching?.group === group).length;
	}

	public setOpenEdit(edit: EditContextOutline, state: boolean) {
		const { batching, save } = edit;
		if (!batching && !save) {
			throw new Error(`Edits without batching or a save function can not be registered`)
		}
		state ? this.openEdits.set(edit) : this.openEdits.delete(edit);
	}

	public hasOpenEdit(edit: EditContextOutline) {
		return this.openEdits.has(edit);
	}

	public cancelAll() {
		this.openEdits.keys().forEach((edit) => {
			console.log("cancelling", edit);
			edit.cancelEdits();
			// this.openEdits.delete(edit);
		});
		// this.groupUpdateFns.clear();
	}

	public async saveAll(targetGroup?: string) {
		const grouped = new Map<string, EditContextOutline[]>()
		const ungrouped: EditContextOutline[] = [];
		this.openEdits.forEach((_, edit) => {
			const { group } = edit.batching || {};
			if (targetGroup && group !== targetGroup) { return; }
			if (group) {
				if (grouped.has(group) === false) { grouped.set(group, []); }
				grouped.get(group)!.push(edit);
			} else {
				ungrouped.push(edit);
			}
		});

		for (const group of grouped.keys()) {
			const edits = grouped.get(group)!;
			const updateFn = this.groupUpdateFns.get(group);
			if (!updateFn) { throw new Error(`No update fn defined for group "${group}"`); }
			const resp = await updateFn(edits.map((edit) => [edit.batching!.name!, edit]));
			if (!resp) {
				throw Error(`missing response from updateFn`);
			}
			if (typeof resp === "object") {
				resp.updated.forEach((edit) => edit.editing = false);
				console.error(resp.errs);

			} else {
				edits.forEach((edit) => edit.editing = false);
			}
		}
		for (const edit of ungrouped) {
			await edit.save!();
		}
	}

	public setGroupUpdateFn(group: string, updateFn: GroupUpdateFn) {
		this.groupUpdateFns.set(group, updateFn);
	}
}
