import "./coachMarks.scss";

import { append, div, noDeps, onPush, onToucherEnterExit, SourceMap } from "helium-ui";
import { ToucherEnterExitEvent } from "helium-ui";
import { Sourcify } from "helium-ui";




export class CoachMarks {
	public showingMarks = new SourceMap<CoachMark, void>();

	constructor() {
		document.body.addEventListener("mousedown", ({target}) => {
			noDeps(() => this.showingMarks.forEach((_, mark) => {
				if (mark.displayMode === "click-out" || mark.displayMode === "click-in-out") {
					mark.domNode?.contains(target as any) || mark.remove();
				}
			}))
		})
	}

	public add(args: Omit<ICoachMarkArgs, "manager">) {
		return new CoachMark({
			...args,
			manager: this,
		})
	}

	public clearGrouping(grouping: string) {
		noDeps(() => this.showingMarks.forEach((_, mark) => {
			if (mark.grouping === grouping) {
				mark.remove();
			}
		}))
	}

	protected _domNode: HTMLElement;
	public get domNode() {
		return this._domNode = this._domNode || div("CoachMarks");
	}
}


export interface ICoachMarkArgs {
	manager: CoachMarks;
	target: HTMLElement;
	mark: HTMLElement | (() => HTMLElement);
	container?: "padded-card-small",
	displayMode?: "hover" | "click-out" | "click-in-out",
	grouping?: string;
	positioning?: "center" | "top" | "bottom" | "bottom-two-thirds";
	keepOnPage?: boolean;
}

export class CoachMark {
	public domNode: HTMLElement | undefined;

	// protected translationState = Sourcify({
	// 	x: "0%",
	// 	y: "0%"
	// });

	constructor(public args: ICoachMarkArgs) {
		const { displayMode } = this;

		if (displayMode === "click-out") {
			this.show();
			return;

		} else if (displayMode === "click-in-out") {
			onPush(args.target, () => this.show() as any);

		} else if (displayMode === "hover") {
			let touchCount = 0;
			let leaveTimeout: any;
			const keepOpenOnTouch = (ev: ToucherEnterExitEvent) => {
				const target = ev.target as HTMLDivElement;
				if (target !== ev.currentTarget) { return; }
				touchCount += ev.isEnterEvent ? 1 : -1;
				touchCount = Math.max(0, touchCount);

				if (leaveTimeout) { clearTimeout(leaveTimeout); }
				leaveTimeout = setTimeout(() => {
					leaveTimeout = undefined;
					if (touchCount < 1) {
						this.remove()
					} else {
						const createdMark = !this.domNode;
						this.show();
						createdMark && onToucherEnterExit(this.domNode!, keepOpenOnTouch);
					}
				}, ev.isEnterEvent ? 0 : 100)
			}
			onToucherEnterExit(args.target, keepOpenOnTouch);
		}
	}

	public get displayMode() { return this.args.displayMode || "click-out"; }
	public get grouping() { return this.args.grouping; }
	public get manager() { return this.args.manager; }

	public get showing() { return this.args.manager.showingMarks.has(this); }

	public clearGroup() {
		this.grouping && this.manager.clearGrouping(this.grouping);
	}

	public remove() {
		const { domNode } = this;
		if (domNode) {
			domNode.remove();
			if (typeof this.args.mark === "function") {
				this.domNode = undefined;
			}
		}
		this.manager.showingMarks.delete(this);
	}

	public show() {
		let mark = this.domNode;
		if (!mark) {
			mark = this.domNode = typeof this.args.mark === "function" ? this.args.mark() : this.args.mark;
			if (this.args.container === "padded-card-small") {
				mark = this.domNode = div("CoachMarkContainer " + this.args.container, mark);
			}
		}

		let translateX = "-50%";
		let translateY: string;
		if (document.body.contains(this.domNode!) === false) {
			this.clearGroup();
			append(this.manager.domNode, mark);
			const { target } = this.args;

			mark.style.position = "absolute";

			const positioning = this.args.positioning || "bottom-two-thirds";
			let { left, width, top, height } = target.getBoundingClientRect();
			left += window.scrollX;
			top += window.scrollY;

			mark.style.left = (left+ width/2) + "px";


			mark.style.right = mark.style.bottom = "";

			if (positioning === "top") {
				mark.style.top = top + "px"
				translateY = "-100%";

			} else if (positioning === "center") {
				mark.style.top = (top + (2 * height/2)) + "px"
				translateY = "-50%"

			} else if (positioning === "bottom") {
				mark.style.top = (top + height) + "px"
				translateY = "0%";

			} else if (positioning === "bottom-two-thirds") {
				mark.style.top = (top + height/3) + "px";
				translateY = "0%";
			}

			mark.style.transform = `translate(${translateX}, ${translateY!})`;
		}
		this.manager.showingMarks.set(this);

		mark = mark!;
		if (this.args.keepOnPage !== false) {
			let shifted = false;
			if (mark.offsetTop < 0) {
				mark.style.top = "0px";
				translateY = "0%";
			} else if (mark.offsetTop + mark.offsetHeight > document.body.offsetHeight) {
				mark.style.top = "";
				mark.style.bottom = "0px";
				translateY = "0%";
			}

			if (mark.offsetLeft < 0) {
				mark.style.left = "0px";
				translateX = "0%";
			} else if (mark.offsetLeft + mark.offsetWidth > document.body.offsetWidth) {
				mark.style.left = "";
				mark.style.right = "0px";
				translateX = "0%";
			}

			mark.style.transform = `translate(${translateX}, ${translateY!})`;
		}
	}

}
