import "./currentUser.scss";

import { anchor, br, button, div } from "helium-ui";
import { App } from "MapApp/state/AppState";
import { renderLangChooser } from "../LangChooser/LangChooser";


export function renderCurrentUserWidget() {
	return div("CurrentUserWidget", {
		innards: () => {
			const email =  App.api.currentUser?.email;
			return email ? email.charAt(0) : App.icon.getIconSpan("user-front-profile");
		},
		ref: (ref) => {
			App.coachMarks.add({
				target: ref,
				displayMode: "hover",
				positioning: "bottom",
				mark: div("CurrentUserDropdown", [
					div("Email", () => {
						const email =  App.api.currentUser?.email;
						if (!email) {
							return [
								"Not currently logged in",
								br(),
								button({
									innards: "Sign up/in",
									onPush: () => App.route.followUrlToRoute("sign-in"),
								})
							]
						}
						return [
							App.api.currentUser?.displayName,
							br(),
							anchor({
								innards: "View Account",
								href: () => App.route.getUrlForRoute("user") + `/${App.api.currentUser?.getId()}`
							})
						];
					}),
					renderLangChooser(),
					() => App.api.currentUser && button("Logout", {
						innards: "Log Out",
						onPush: () => App.api.logout(),
					})
				])
			})
		}
	})
}
