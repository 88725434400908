import { anchor, br, button, div, h2 } from "helium-ui";
import { App } from "MapApp/state/AppState";
import { renderSignInPage } from "../SignInPage/SignInPage";
import { renderSliderToggle } from "../SliderToggle/SliderToggle";



export function renderAdminPage() {
	return div("AdminPage", () => {
		const isAdmin = App.api.hasToken();
		if (!isAdmin) {
			return div("NoAuth", [
				`You are not authorized to use this page.  Please try logging in:`,
				renderSignInPage(),
			])
		}
		return [
			h2(["Admin Page"]),
			"(Thank you!)",
			br(3),
			anchor({
				href: `${App.route.getUrlForRoute("org-details")}/new`,
				innards: "Create new Organization"
			}),
			br(),
			anchor({
				href: `${App.route.getUrlForRoute("action-details")}/new`,
				innards: "Create new Action"
			}),
			br(),
			renderSliderToggle({
				storage: {
					get: () => !!App.custom.state.translatorTooltips,
					set: (val) => App.custom.state.translatorTooltips = val,
				},
				label: () => "Translator Tooltips"
			})
		]
	})
}
