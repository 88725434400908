import { jit } from "helium-source-repo";
import {
	ActionManager,
	LocationManager,
	LocationUseManager,
	OrganizationManager,
	RoleManager,
	TagManager,
	TagUseManager,
	UserManager
} from "./entities";
import { DotEarthApi } from "./tools/Api";



export class DotEarthClient {
	protected static _client: DotEarthClient;
	public static get() { return this. _client; }
	public static set(client: DotEarthClient) { this._client = client; }

	constructor() {
		DotEarthClient.set(this);
	}

	public get api() {
		return jit(this, "api", () => new DotEarthApi());
	}

	// public repo = {
	// 	poi: new PoiRepo(),
	// 	tag: new TagRepo(),
	// 	action: new ActionRepo(),
	// 	// orgs: new PoiRepo(),
	// 	benchmark: new BenchmarkRepo(),
	// }

	public get ents() {
		return jit(this, "DotE_ents", () => ({
			// poi: new PoiManager(),
			role: new RoleManager(),
			org: new OrganizationManager(),
			tag: new TagManager(),
			tagUse: new TagUseManager(),
			act: new ActionManager(),
			loc: new LocationManager(),
			locUse: new LocationUseManager(),
			user: new UserManager(),
			// benchmark: new BenchmarkManager(),
		}));
	}
}
