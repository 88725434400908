import "./orgPage.scss";

import { anchor, br, button, ddxClass, div, h2, img, noDeps, setInnards, Source, span } from "helium-ui";
import { GroupUpdateFn, SourceRepoController } from "helium-source-repo";


import { OrganizationEntity } from "MapApp/state/entities/Organization";
import { App } from "MapApp/state/AppState";
import { Modal } from "../Modals/Modals";
import { renderSliderToggle } from "../SliderToggle/SliderToggle";
import { renderTagUseList, TagUseEditContext } from "../TagUseList/TagUseList";
import { LocationUseEditor, renderLocationUseList } from "../LocationUseList/LocationUseList";
import { EditableText } from "../Editors/EditableText";
import { LocationEntity, LocationUseEntity } from "dot-earth-client";
import { renderCommonPage } from "../CommonPage/CommonPage";
import { renderPageEditButton } from "../PageEditMenu/PageEditMenu";
import { renderTranslateButton, shouldHaveTranslateButton } from "../Editors/Translate/Translate";
import { inLang } from "../InLang/InLang";


export function renderOrgPage(orgOrId?: OrganizationEntity | string) {
	return renderCommonPage({
		innards: renderOrgPanel(orgOrId),
		back: { lastPage: true},
	})
}

export function renderOrgPanel(orgOrId?: OrganizationEntity | string) {
	const org = getOrg();
	if (!org || org.state.loading) { return "Loading..."}
	if (org.isDeleted()) {
		return "Organization has been deleted";
	}
	const groupingId = () => `org-page-${org.id}`;

	const editLock = () => org.isTemplate() ? "ON" : null;
	const editMode = new Source(noDeps(editLock) === "ON");

	let _editor: OrganizationEntity | undefined;
	const editor = () => {
		return _editor = _editor || (org.isTemplate() ? org : org.getEditor(groupingId()));
	}

	return div("OrgPanel", {
		ddx: () => {
			editMode.get() && App.edits.setGroupUpdateFn(groupingId(), createUpdateFn());
		}
	},[
		// () => App.api.hasToken() && editLock() !== "ON" && div("Settings", {
		// 	ref: (ref) => {
		// 		const coachMark = App.coachMarks.add({
		// 			target: ref,
		// 			displayMode: "hover",
		// 			positioning: "bottom",
		// 			container: "padded-card-small",
		// 			mark: () => div("SettingsDropdown", [
		// 				div("Edit", renderSliderToggle({
		// 					label: () => "Edit",
		// 					storage: editMode,
		// 				})),
		// 				br(),
		// 				button("Delete redAction wide", {
		// 					onPush: () => App.modals.add({
		// 						container: "tightCard",
		// 						clickOutClose: true,
		// 						domNode: (modal) => renderDeleteModal(modal),
		// 					}),
		// 					innards: "Delete"
		// 				}),
		// 			]),
		// 		})
		// 		ddxClass(ref, () => coachMark.showing ? "--active" : "")
		// 	},
		// }),
		() => App.api.hasToken() && editLock() !== "ON" && renderPageEditButton({
			items: [
				{ type: "edit",
					storage: editMode
				},
				{	type: "delete",
					doAction: async () => {
						try {
							await org.delete.confirm();
							return true;
						} catch(err) {
							console.error(err);
							return false
						}
					},
					renderConfirm: () => "Are you sure you want to delete this Organization?",
					renderSuccess: () => "Organization successfully deleted"
				}
			]
		}),
		renderEditableLogo,
		h2(editableText("name")),
		br(0.5),
		div("ShortDesc", editableText("shortDesc")),
		br(1.5),
		() => renderTagUseList({ target: org, edits: {
			allowEditing: () => editMode.get(),
			editLock,
			register: {
				group: groupingId()
			}
		} }),
		br(1.5),
		() => renderLocationUseList({
			target: org,
			edits: {
				allowEditing: () => editMode.get(),
				editLock,
				register: {
					group: groupingId(),
					name: groupingId() + "_location-use-list",
				}
			}
		}),
		br(0.5),
		() => renderEditableWebsite(),
	]);















	// ----------------------------
	//		Helper Functions
	// ----------------------------

	function getOrg() {
		let org: OrganizationEntity;
		orgOrId = orgOrId || App.route.getPathSlice(1);
		if (typeof orgOrId === "string") {
			if (orgOrId === "new") {
				org = App.ents.org.createTemplateController({
					register: { group: `org-page-create`, name: "org" }
				});
				org.stageEdits({ defaultLang: "en" });
			} else {
				// if (orgOrId.startsWith("org-") === false) {
				// 	orgOrId = "org-" + orgOrId;
				// }
				org = App.ents.org.get(orgOrId)
			}
		} else {
			org = orgOrId;
		}
		return org;
	}



	function renderEditableLogo() {
		if (!org.logoUrl && !editMode.get()) { return; }
		const out = div("Logo");
		setInnards(out, () => {
			const getUnmodifiedValue = () => inLang(org.logoUrl) || `${App.api.hostMode === "live" ? "/dev" : ""}/assets/img/illustration/your-logo-here.png`;
			const edit = !editMode.get() ? undefined : new EditableText({
				getUnmodifiedValue,
				editLock,
				storage: {
					get: () => editor().getProp("logoUrl") ? editor().getProp("logoUrl")!.en : undefined,
					set: (val) => editor().stageEdits({ logoUrl: { en: val }}),
				}
			})
			return [
				() => img({
					ddxClass: () => edit?.editing ? "--editingHighlight" : "",
					src: edit ? (edit.currentValue || "") : getUnmodifiedValue()
				}),
				edit && [
					edit.editIcon({
						type: "neverTrash",
						noHide: true,
						onPush: () => {
							edit.editing = true;
							App.coachMarks.add({
								positioning: "top",
								container: "padded-card-small",
								target: out,
								mark: () => edit.domNode(),
							})
						}
					}),
					edit.editIcon({ type: "trashOnly" }),
				]
			];
		});
		return out;
	}


	function editableText(prop: "name" | "shortDesc") {
		return () => {
			if (editMode.get() === false) {
				return span(() => inLang(org[prop]));
			}
			const edit = new EditableText({
				getUnmodifiedValue: () => inLang(org.getProp(prop)),
				placeholder: App.txtRaw.org.placeholder[prop],
				// group,
				// name: prop,
				editLock,
				validation: {
					minLength: 5,
					maxLength: 250
				},
				storage: {
					get: () => {
						const value = editor().getProp(prop);
						console.log("get", value);
						return inLang(value);
					},
					set: (val) => {
						console.log("set", val);
						editor().stageEdits({ [prop]: {
							en: val
						} })
					}
				}
			});
			const ed = editor();
			return [
				edit.domNode(),
				edit.editIcon(),
				() => shouldHaveTranslateButton(ed.getProp(prop)) && renderTranslateButton({
					ent: ed,
					prop
				})
			];
		}
	}

	// function renderDeleteModal(modal: Modal) {
	// 	return div("DeleteModal", () => {
	// 		if (org.isDeleted() === false) {
	// 			return [
	// 				"Are you sure you want to delete this Organization?",
	// 				br(),
	// 				div({ style: { display: "flex", justifyContent: "space-around"}}, [
	// 					button({
	// 						onPush: () => modal.close(),
	// 						innards: "Cancel"
	// 					}),
	// 					button("redAction", {
	// 						onPush: async () => {
	// 							try {
	// 								await org.delete.confirm();
	// 							} catch(err) {
	// 								console.error(err);
	// 							}
	// 						},
	// 						innards: "Delete"
	// 					}),
	// 				])
	// 			]
	// 		}
	// 		return ["Organization successfully deleted"]
	// 	})
	// }

	function renderEditableWebsite() {
		if(!editMode.get() && !org.website) { return}
		const edit = !editMode.get() ? undefined : new EditableText({
			getUnmodifiedValue: () => inLang(org.website),
			placeholder: () => "full url to website",
			// group,
			// name: "website",
			editLock,
			storage: {
				get: () => editor().getProp("website") ? editor().getProp("website")!.en : undefined,
				set: (val) => editor().stageEdits({ website: { en: val }}),
			}
		})

		const href = () => (edit?.editing ? edit.currentValue : inLang(org.website)) || "";

		let out: HTMLElement;
		return out = div({
			ddxClass: () => edit?.editing ? "--editingHighlight" : "",
			style: { textAlign: "center "},
			innards: () => [
				anchor({
					target: "_blank",
					href,
					innards: () => href().replace(/https?:\/\/|www\.|\/.*$/g, "") || (editMode && "site-for.org"),
				}),
				edit && [
					edit.editIcon({
						type: "neverTrash",
						noHide: true,
						onPush: () => {
							edit.editing = true;
							App.coachMarks.add({
								target: out,
								positioning: "bottom",
								container: "padded-card-small",
								mark: () => edit.domNode(),
							})
						}
					}),
					edit.editIcon({ type: "trashOnly" }),
				]
			],
		})
	}


	function createUpdateFn(): GroupUpdateFn {
		return async (updates) => {
			let orgEdit: OrganizationEntity | undefined;
			let tagUseEditor: TagUseEditContext | undefined;
			let locUseEditor: LocationUseEditor | undefined;
			const miscItems: SourceRepoController<any, any>[] = [];
			for (const [name, edit] of updates) {
				if (edit instanceof OrganizationEntity) {
					if (orgEdit) { throw new Error(`Only one org expected to get updates on OrgPage`) }
					orgEdit = edit;
				} else if (edit instanceof TagUseEditContext) {
					if (tagUseEditor) { throw new Error(`Only one tagUseEditor expected to get updates on OrgPage`) }
					tagUseEditor = edit;
				} else if (edit instanceof LocationUseEditor) {
					if (locUseEditor) { throw new Error(`Only one locUseEditor expected to get updates on OrgPage`) }
					locUseEditor = edit;
				} else if (edit instanceof LocationUseEntity || edit instanceof LocationEntity) {
					miscItems.push(edit);
				}
			}
			if (orgEdit) {
				const wasTemplate = orgEdit.isTemplate();
				await orgEdit.save();
				if (wasTemplate) {
					history.pushState({}, "Organization Page", `/org/${orgEdit.id}`);
				}
			}
			if (tagUseEditor) {
				await tagUseEditor.pushUpdatesForCtx(org.id);
			}
			if (locUseEditor) {
				locUseEditor.setTarget(org.id);
				await locUseEditor.save()
			}
			for (const miscItem of miscItems) {
				try {
					await miscItem.save();
				} catch(err) {
					console.error(`Item not saved`, miscItem);
				}
			}
			// const data: Partial<IOrganization> = {};
			// let tagUseEditor: TagUseEditContext | undefined;
			// let dataEditors: EditContextOutline<any>[] = [];
			// updates.forEach(([prop, editor]) => {
			// 	if (prop === "tagUseList") {
			// 		tagUseEditor = editor as any;
			// 	} else {
			// 		data[prop] = { en: noDeps(() => editor.currentValue) }
			// 		dataEditors.push(editor);
			// 	}
			// });
			// try {
			// 	await org.save(data as any);
			// } catch (err) {
			// 	return {
			// 		updated: [],
			// 		errs: [ { err, edits: dataEditors }]
			// 	}
			// }
			// console.log(org.id);
			// history.pushState({}, "Organization Page", `/org/${org.id}`);

			// if (tagUseEditor) {
			// 	await tagUseEditor.pushUpdatesForCtx(org.id);
			// }
			editMode.set(false);
			_editor = undefined;
			return true;
			// // return {
			// // 	updated: dataEditors,
			// // 	errs: [ { err: "No tag use update function exists", edits: [tagUseEditor!] }]
			// // }
		}
	}
}
