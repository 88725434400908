import { ISelectionFilter } from "../Selections";


export type GqlLayer<T extends object, PROPS extends {} = {}> = GqlSelectionFilter<T> & {
	join?: GqlLayerMap<PROPS>;
} & PROPS;

export type GqlLayerMap<PROPS extends {} = {}> = { [key: string]: GqlLayer<any, PROPS>; }

export type GqlSelectionFilter<T extends object> = ISelectionFilter<T> & {
	props: Array<keyof T | string>;
}

export function createGqlString(joins: GqlLayerMap): string {
	return Object.entries(joins).map(([name, specs]) => {
		const whereString = specs.where && Object.entries(specs.where).map(([key, value]) => `${key}: "${value}"`).join(", ");
		return [
			name + (whereString ? ` (${whereString})` : "") + ` {`,
			...specs.props.map((prop) => `  ${prop as string}`),
			!specs.join ? undefined : createGqlString(specs.join).split("\n").map((line) => "  " + line).join("\n"),
			`}`
		]
	}).flat().filter((it) => !!it).join("\n");
}



export type ParseItem<T> = {
	parseItem?: (data: T[]) => Promise<any>;
}
export type GqlRequestMap = GqlLayerMap<ParseItem<any>>

export async function gqlRequest(
	request: GqlRequestMap,
	props: {
		apiCall: (qqlQuery: string) => Promise<object>;
		queryKeyword?: string;
	}
) {
	const queryString = [
		`${props.queryKeyword || "query"} {`,
		"  " + createGqlString(request),
		"}"
	].join("\n");
	const respObj = await props.apiCall(queryString);
	return await parseResponse(request, respObj);

	async function parseResponse(req: GqlRequestMap, data: object) {
		const out = {};
		const promises = Object.entries(req).map(([name, specs]) => {
			const isList = Array.isArray(data[name]);
			const joinData: object[] = isList ? data[name] : [data[name]];
			const joinSpecs = specs.join;
			const parseItem = specs.parseItem || (async (val) => val);
			if (!joinSpecs) {
				return parseItem(joinData).then((resp) => {
					out[name] = isList ? resp : resp[0];
				});
			}
			const list = out[name] = [] as any[];
			return joinData.map((item) => {
				const outItem = {
					item: null as any
				};
				list.push(outItem);
				return Object.keys(joinSpecs).map(async (joinName) => {
					const dataCopy = { ...item };
					delete item[joinName];
					const parsedJoin = await parseResponse(joinSpecs, dataCopy);
					Object.keys(parsedJoin).forEach((key) => outItem[key] = parsedJoin[key]);
					outItem.item = (await parseItem([item]))[0];
				})
			})
		}).flat(Infinity);
		await Promise.all(promises as any);
		return out;
	}
}














let test = {
	roles: [] as any[],
	groups: [] as any[],
};
const request: GqlRequestMap = {
	Roles: {
		where: { ownerId: "usr-SoTYdnr-2B"},
		props: ["id"],
		parseItem: async (roles) => {
			roles.forEach(role => test.roles.push(role))
			return roles
		},

		join: {
			Group: {
				props: ["id"],
				parseItem: async (it) => { it.forEach((g) => test.groups.push(g)); return it},
			}
		}
	}
}



gqlRequest(request, {
	apiCall: async (queryString) => {
		console.log(queryString);
		return {
			"Roles": [
				{
					"id": "role-Sr9AlAN-M0",
					"type": "admin",
					"Group": [{
						"id": "grp-Sr9Al9v-dQ",
						"name": "test"
					}]
				}
			]
		}
	}
}).then((resp) => console.log(test, JSON.stringify(resp, undefined, 2)))
