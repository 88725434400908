import { ITextValidation } from "dot-earth-middleware";
import { EditablePrimitive, EditablePrimitiveArgs } from "helium-source-repo";
import { SourceMap, deriveDomAnchored, noDeps, setInnards, span } from "helium-ui";
import { IEditIconArgs, renderEditIcon } from "../Editors/EditIcon";

export type EditableTextArgs = EditablePrimitiveArgs<string> & {
	placeholder?: () => string;
	validation?: ITextValidation;
}

export class EditableText extends EditablePrimitive<string | undefined>{
	public static renderNodeAndEditIcon(args: EditableTextArgs) {
		const out = new EditableText(args);
		return [out.domNode(), out.editIcon()];
	}

	constructor(protected args: EditableTextArgs) {
		super(args)
	}

	protected issueMap = new SourceMap<string, void | boolean>();

	public get placeholder() {
		return this.args.placeholder ? this.args.placeholder() : null;
	}

	public domNode() {
		return span("EditableText", {
			ddxClass: [
				() => {
					console.log("isEditing", this.editing, this);
					return this.editing ? "--editable" : ""
				},
				() => this.issueMap.isEmpty() ? "" : "--hasIssues"
			],
			ref: (ref) => {
				ref.addEventListener("input", () => {
					this.currentValue = ref.textContent || "";
				});
				ref.addEventListener("blur", () => {
					if (noDeps(() => this.hasEdits) === false) {
						this.editing = false;
					}
				});

				this.args.validation && deriveDomAnchored(ref, {
					batching: 250,
					fn: () => {
						const text = this.currentValue;
						const { minLength, maxLength} = this.args.validation!;
						if (typeof minLength === "number" && (!text || minLength > text.length)) {
							this.issueMap.set(`The minimum length for this field is ${minLength}`, true)
						}
						if (typeof maxLength === "number" && text && maxLength < text.length) {
							this.issueMap.set(`The maximum length for this field is ${maxLength}`, true)
						}
						noDeps(() => this.issueMap.forEach((value, key) => {
							if (value !== true) {
								this.issueMap.delete(key);
							} else {
								this.issueMap.set(key);
							}
						}))
					}
				});

				deriveDomAnchored(ref, () => {
					ref.contentEditable = String(this.editing);
					this.editing && this.editLock !== "ON" && setTimeout(() => ref.focus(), 100);
				})
				deriveDomAnchored(ref, () => {
					ref.style.setProperty("--editableText-placeholder", `"${this.placeholder || "Text goes here"}"`);
				});
				deriveDomAnchored(ref, () => {
					const expected = this.editing ? this.currentValue : this.args.getUnmodifiedValue()
					if (ref.textContent !== expected) {
						setInnards(ref, expected);
					}
				});
			},
		})
	}

	public editIcon(args: Omit<IEditIconArgs, "context"> = {}) {
		return renderEditIcon({
			...args,
			context: this
		});
	}
}
