import { jit } from "helium-source-repo";
import { deriverSwitch } from "helium-ui";
import { App } from "MapApp/state/AppState";
import { RouteId } from "MapApp/state/RouteState";
import { DotEarthMap } from "../Map/Map";
import { renderOrgCard } from "../OrgCard/OrgCard";
import { MapExploreConnectionState } from "./MapExploreConnectionState";
import { renderMapExploreSidebar } from "./MapExploreSidebar";



export function buildMapExplorePageKit() {
	return jit(window, "MapExplorePageKit", () => {
		const connections = new MapExploreConnectionState();
		deriverSwitch<RouteId | "NO_ROUTE">({
			watch: () => App.route.id,
			responses: [
				{ match: "map.organizations",
					action: () => connections.filterMode.set("tag")
				},
				{ match: "map.actions",
					action: () => connections.filterMode.set("action")
				},
			],
			ddxProps: { anchor: "NO_ANCHOR" }
		});

		const map = new DotEarthMap({
			connections,
			getLoc: (org) => org.findOneLoc(),
			createPopup: {
				renderPopup: (org) => renderOrgCard({
					org,
					connections,
					onTagSelect: "set-connection-filter"
				}),
			}
		});
		const contentArea = () => map.domNode;
		const sidebar = () => renderMapExploreSidebar({ connections });

		return {
			connections,
			map,
			contentArea,
			sidebar,
		}
	})
}
