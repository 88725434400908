import { DerivationManager, derive, DeriverScope, noDeps, Source } from "helium-sdx";
import { ISelectionLogic } from "../SelectionLogicChain";
import { matchesRequirements } from "../Utils/match";


export type DdxSpecialResponse = "VERIFIED" | "UPDATED" | ErrResponse;
export type ErrResponse = { err: any }


export interface IResponseHandlerArgs<T> {
	scopeless?: boolean;
	cache?: {
		get(): T;
		checkSame?(cache: T, newVal: T): boolean;
	};
	logic?: ISelectionLogic;
	request: {
		async(): Promise<T>
	} | {
		ddx(): T | DdxSpecialResponse
	};
}



export class ResponseHandler<T> {
	public static get<T>(id: string, create: () => IResponseHandlerArgs<T>) {
		const scope = DerivationManager._getCurrentDeriver();

		if (!!scope) {
			const ddxStore = scope.getStore() as {
				heSdxClientRespHandler: Record<string, ResponseHandler<any>>
			};
			const record = ddxStore.heSdxClientRespHandler = ddxStore.heSdxClientRespHandler || {};
			return record[id] || (record[id] = new ResponseHandler<T>(create()));
		}

		const args = create();
		if (args.scopeless !== true) {
			throw new Error(`A ResponseHandler which was not labeled "scopeless" has no scope`);
		}
		return new ResponseHandler<T>(args);
	}
	public static NO_CACHE = Symbol("NO_CACHE");

	protected _cache?: T | Symbol;
	protected _promise: Promise<T | ErrResponse>;
	protected _source: Source<T | undefined> | undefined;
	protected _deriver: DeriverScope | undefined;
	protected _status?: Source<"PENDING" | "RETRIEVED" | DdxSpecialResponse>;
	protected _err = new Source<undefined | ErrResponse>();
	protected ddxRuns = 0;
	protected resolve: (val: T) => any;
	protected promiseResponse?: T;

	constructor (protected args: IResponseHandlerArgs<T>) {
		this.runRequest();
	}

	public get requestMode() {
		return "async" in this.args.request ? "async" : "ddx";
	}

	public get promise() {
		if (!this._promise) {
			this._promise = new Promise((res) => this.resolve = res);
		}
		return this._promise;
	}

	public get cache() {
		if (this._cache === ResponseHandler.NO_CACHE) {
			return undefined;
		}
		if (this._cache === undefined) {
			this._cache = (this.args.cache && this.args.cache.get()) || ResponseHandler.NO_CACHE;
			return this.cache;
		}
		return this._cache;
	}

	public get status() {
		if (!this._status) {
			noDeps(() => {
				let statusValue: "PENDING" | "RETRIEVED" | DdxSpecialResponse;
				let errVal = this._err.get()
				if (errVal) {
					statusValue = errVal;
				} else {
					const newVal = this.requestMode === "async" ? this.promiseResponse : "ERR";
					if (newVal === "ERR") { throw new Error(); }
					statusValue = newVal ? this.getCacheStatusForUpdate(newVal) : "PENDING";
				}
				this._status = new Source(statusValue);
			})
		}
		return this._status!.get();
	}


	public get err() {
		return this._err.get();
	}

	public get source() {
		if (!this._source) {
			this._source = new Source(this.promiseResponse || this.cache);
		}
		if (this.deriver) {
			this.deriver.unfreeze();
		}
		return this._source;
	}

	public get phaseResponse() {
		const { status } = this;
		switch (status) {
			case "RETRIEVED":
			case "UPDATED":
			case "VERIFIED": return this.source.get()
			default: return status;
		}
	}

	public get deriver() { return this._deriver; }


	protected runRequest() {
		const { request } = this.args;
		if ("async" in request) {
			this._promise = request.async().then((val) => {
				this.setValue(val);
				return val;
			}).catch((err) => {
				const out = { err };
				this._err.set(out);
				this._status?.set(out);
				return out;
			});
		} else {
			throw new Error("Not complete");
			this._source = new Source();
			// this._deriver = derive(() => {
			// 	this.ddxRuns++;
			// 	const val = request.ddx();

			// })
		}
	}

	protected setValue(val: T) {
		if (this._source) {
			this._source.set(val);
		}
		this.requestMode === "async" && (this.promiseResponse = val);
		if (this._status) {
			this._status.set(this.getCacheStatusForUpdate(val))
		}
	}

	protected getCacheStatusForUpdate(newVal: T) {
		if (this.args.cache) {
			const verified = this.cacheCheck(newVal);
			return verified ? "VERIFIED" : "UPDATED";
		}
		return "RETRIEVED";
	}

	protected cacheCheck(newVal: T) {
		if (!this.args.cache) {
			return false;
		}
		const { cache } = this;
		if (!cache) { return false; }
		if (this.args.cache.checkSame) {
			return this.args.cache.checkSame(cache, newVal);
		}

		return matchesRequirements(cache, newVal, { itemKeys: "no-extras" });
	}
}
