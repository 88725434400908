import "./pageEditMenu.scss";

import { br, button, DdxClassFn, div, Innards, Source } from "helium-ui";
import { App } from "MapApp/state/AppState";
import { renderSliderToggle } from "../SliderToggle/SliderToggle";

export interface IPageEditMenuArgs {
	items: Array<
		HTMLElement
		| {
			type: "edit",
			storage: {
				set: (val: boolean) => any;
				get: () => boolean;
			}
		}
		| ({ type: "delete", } & IDeleteModalArgs)
	>
}


export function renderPageEditButton(args: IPageEditMenuArgs) {
	return div("PageEditButton", {
		ref: (ref) => {
			App.coachMarks.add({
				target: ref,
				displayMode: "hover",
				positioning: "bottom",
				mark: () => renderPageEditMenu(args),
				container: "padded-card-small",
			})
		}
	})
}

export function renderPageEditMenu(args: IPageEditMenuArgs) {
	return div("PageEditMenu", args.items.map((it) => {
		if (it instanceof HTMLElement) {
			return it;
		}
		if (it.type === "edit") {
			return renderSliderToggle({
				storage: it.storage,
				label: () => "Edit",
				props: {
					class: "EditModeToggle"
				}
			})
		}
		if (it.type === "delete") {
			return button("Delete redAction wide", {
				innards: "Delete",
				onPush: () => renderDeleteModal(it)
			})
		}
	}))
}



export interface IDeleteModalArgs {
	doAction: () => Promise<boolean>,
	renderConfirm: () => Innards,
	renderSuccess: () => Innards,
}
export function renderDeleteModal(args: IDeleteModalArgs) {
	App.modals.add({
		container: "tightCard",
		clickOutClose: true,
		domNode: (modal) => {
			const success = new Source(false);
			return div("DeleteModal", () => {
				if (!success.get()) {
					return [
						args.renderConfirm,
						br(),
						div({ style: { display: "flex", justifyContent: "space-around"}}, [
							button({
								onPush: () => modal.close(),
								innards: "Cancel"
							}),
							button("redAction", {
								onPush: async () => {
									try {
										await args.doAction();
										success.set(true);
									} catch(err) {
										console.error(err);
									}
								},
								innards: "Delete"
							}),
						])
					]
				}
				return args.renderConfirm;
			})
		},
	});
}
