import { IRepoDataSourceArgs, RepoDataSource } from './RepoDataSource';
import { SourceRepoSlice } from './SourceRepoSlice';



export class SourceRepo<
  T extends object,
  ID extends keyof T,
  INSERTABLE extends Partial<T> = T
> extends SourceRepoSlice<T, ID, INSERTABLE> {
	constructor(args: IRepoDataSourceArgs<T, ID, INSERTABLE>) {
		super({
			dataSource: new RepoDataSource<T, ID, INSERTABLE>(args),
      whereRules: {}
    });
  }
}
