import { shallowMatch } from "../filterArray";

export interface ISubscription {
  subscriberId: string | null;
  repoId: string;
  filter: string | object;
}

export interface IRepoSubscriptions {
  idPropName: string;
  idSubs: Map<string, ISubscription[]>;
  querySubs: Map<string, ISubscription[]>;
  // querySubIdsSorted: string[];
};

export interface ISubscriber {
  id: string;
  subs: ISubscription[];
  updateFn: (args: { repoId: string, updates: object[]}) => Promise<void>;
}

export class ServerSubscriptionHub {
  protected clientMap = new Map<string, ISubscriber>();
  protected repos = new Map<string, IRepoSubscriptions>();

  public registerRepo(args: {
    repoId: string;
    idPropName: string;
  }) {
    const { repoId } = args;
    if (this.repos.has(repoId)) {
      throw new Error(`Repo "${repoId}" has not yet been registered`);
    }
    this.repos.set(repoId, {
      idPropName: args.idPropName,
      idSubs: new Map(),
      querySubs: new Map(),
      // querySubIdsSorted: [],
    });
  }

  public registerSub(sub: ISubscription) {
    const tableSubs = this.repos.get(sub.repoId);
    if (!tableSubs) {
      throw new Error(`Table "${sub.repoId}" has not been registered`);
    }

    const client = sub.subscriberId && this.clientMap.get(sub.subscriberId);
    if (!client) {
      throw new Error(`Client "${sub.subscriberId}" has not been registered`);
    }

    client.subs.push(sub);
    
    if (typeof sub.filter === "string") {
      const { idSubs } = tableSubs;
      if (idSubs.has(sub.filter) === false) {
        idSubs.set(sub.filter, []);
      }
      idSubs.get(sub.filter)!.push(sub);

    } else {
      // TODO: make sure small differences in order don't mess this up
      const queryString = JSON.stringify(sub.filter);
      const { querySubs } = tableSubs;
      if (querySubs.has(queryString) === false) {
        querySubs.set(queryString, []);
      }
      querySubs.get(queryString)!.push(sub);
      // const targetIndex = querySubIdsSorted.findIndex((it) => it.length < queryString.length);
      // querySubIdsSorted.splice(targetIndex, 0, queryString);
    }
  }

  public dropSub(sub: ISubscription) {
    sub.subscriberId = null;
  }

  protected nextClientId = 0;
  public registerClient(client: Pick<ISubscriber, "updateFn">) {
    const id = `clnt_${this.nextClientId++}`;
    const addMe = {
      id,
      subs: [],
      ...client
    };
    this.clientMap.set(id, addMe);
    return addMe;
  }

  public dropClient(clientId: string) {
    const client = this.clientMap.get(clientId);
    if (!client) { 
      return; 
    }
    client.subs.forEach((sub) => this.dropSub(sub));
    this.clientMap.delete(clientId);
  }


  public updateClients<T extends object = any>(args: {
    repoId: string;
    updates: T[];
  }) {
    const clientUpdates = new Map<string, Map<T, void>>();
    const addClientUpdate = (clientId: string, update: T) => {
      if (clientUpdates.has(clientId) === false) {
        clientUpdates.set(clientId, new Map());
      }
      clientUpdates.get(clientId)!.set(update);
    }

    const table = this.repos.get(args.repoId);
    if (!table) { throw new Error(`Table of id "${args.repoId}" has not been registered as a client`)};
    args.updates.forEach((update) => {
      const id = update[table.idPropName];
      const idSubs = table.idSubs.get(id);
      if (idSubs) {
        table.idSubs.set(id, idSubs.filter((sub) => {
          if (!sub.subscriberId) { return false; }
          addClientUpdate(sub.subscriberId, update);
          return true;
        }))
      }
      Array.from(table.querySubs.entries()).forEach(([queryString, subList]) => {
        if (!subList.length) { return; }

        const query = subList[0].filter;
        if (shallowMatch(query, update)) {
          table.querySubs.set(queryString, subList.filter((sub) => {
            if (!sub.subscriberId) { return false; }
            addClientUpdate(sub.subscriberId, update)
            return true;
          }))
        }
      });
    });

    const clientUpdateList = Array.from(clientUpdates.entries());
    clientUpdateList.forEach(([clientId, updateMap]) => {
      const client = this.clientMap.get(clientId)!;
      const updates = Array.from(updateMap.keys());
      client.updateFn({
        repoId: args.repoId,
        updates
      });
    })
  }
}