const jitMaps = new Map<any, Map<string, any>>();

export function jit<OUT>(ctx: any, name: string, cb: () => OUT): OUT {
	let map = jitMaps.get(ctx);
	if (!map) { jitMaps.set(ctx, map = new Map())}

	if (!map.has(name)) { map.set(name, cb()); }
	return map.get(name);
}

export function jitHas<OUT>(ctx: any, name: string): boolean {
  const map = jitMaps.get(ctx);
  return !!map && map.has(name);
}