import "../Shared/shared.scss"
import "../Shared/css-vars/DotEarth.scss";
// import "../Shared/css-vars/SUMA.scss";

import { AsciiName } from "dot-earth-middleware";
import { append } from "helium-ui";
import { renderMapApp } from "./MapApp";


console.log(AsciiName);

document.addEventListener("mousemove", () =>
  document.body.classList.remove("focus-visible")
);

document.addEventListener("keydown", ({key}) =>
  (key === "Tab") && document.body.classList.add("focus-visible")
);

const app = renderMapApp();

document.addEventListener("DOMContentLoaded", () => {
	append(document.body, app);
});
