import { SourceRepoController } from "helium-source-repo";
import { SourceRepoControllersManager, SourceRepo } from "helium-source-repo";
import { ILocation, ILocationArgs } from "dot-earth-middleware";
import { repoApiArgs } from "./EntityCommon";


export class LocationManager<TYPE extends LocationEntity>
	extends SourceRepoControllersManager<TYPE>
{
	protected getDefaultArgs() {
		return {
			repo: new SourceRepo<ILocation, "id", ILocationArgs>({
				repoId: "Location",
				idPropName: "id",
				...repoApiArgs("location"),
			}),
			createController: (data) => new LocationEntity(data),
		} as any;
	}
}


export class LocationEntity
	extends SourceRepoController<ILocation, "id", ILocationArgs>
	implements Record<keyof ILocation, any>
{
	public _noHeliumProxy = true;

	public get id() { return this.getId(); }
	public get createdAt() { return this.getProp("createdAt"); }
	public get type() { return this.getProp("type"); }
	public get updatedAt() { return this.getProp("updatedAt"); }
	public get lat() { return this.getProp("lat"); }
	public get lng() { return this.getProp("lng"); }
	public get name() { return this.getProp("name"); }
	public get desc() { return this.getProp("desc"); }
}
