import { ISelectionFilter } from "./Selections/SelectionFilter";


export function filterArray<T extends Object>(
  filterMe: Array<T>,
  filter: ISelectionFilter<T>
): Array<T> {
  const mustHave = Object.entries(filter.where || {});
  if (mustHave.length === 0) { return filterMe.map(it => it); }
  return filterMe.filter((it) => {
    return mustHave.every(([key, value]) => it[key] === value);
  });
}

export function shallowMatch<T extends Object>(
  target: T,
  requirements: Partial<T>
) {
  const mustHave = Object.entries(requirements);
  return mustHave.every(([key, value]) => target[key] === value);
}
