


export const LanguageCodeList = [
	"en",
	"es"
] as const;


export type LanguageCode = typeof LanguageCodeList[number];

export type MultiLang<T = string> = { [key in LanguageCode]?: T }
