import { LocationEntity } from "dot-earth-client";
import { ddxClass, DdxClassFn, deriveDomAnchored, onPush } from "helium-ui";
import * as mapboxgl from "mapbox-gl";

export interface ICreateMarkerArgs {
	location: mapboxgl.LngLatLike | (() => LocationEntity | undefined);
	ddxClass?: DdxClassFn<HTMLElement>;
	onPush?: () => any;
}


export function createMarker(args: ICreateMarkerArgs) {
	const marker = new mapboxgl.Marker({
		scale: 0.5,
		color: "inherit",
	});
	const domNode = marker.getElement();

	args.ddxClass && ddxClass(domNode, args.ddxClass);
	if (args.onPush) {
		onPush(domNode, args.onPush);
		domNode.style.cursor = "pointer";
	}


	const { location } = args;

	if (typeof location === "function") {
		marker.setLngLat([0,0]);
		deriveDomAnchored(domNode, () => {
			const loc = location();
			if (loc && typeof loc.lat === "number" && typeof loc.lng === "number") {
				marker.setLngLat({lng: loc.lng, lat: loc.lat});
			}
		})
	} else {
		marker.setLngLat(location);
	}

	return marker;
}
