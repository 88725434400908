import { PickRequired } from "../PickRequired";
import { LanguageCode, MultiLang } from "../consts/LanguageCodes";
import { Validators } from "../consts/Validators";

export const OrganizationTypeList = [
	"grass-seed",
	"grass-root",
	"grass-top",
	"large-ngo",
	"funder",
	"academia",
	"local-government",
	"multi-region-government",
] as const;

export type OrganizationType = typeof OrganizationTypeList[number];

export interface IOrganization {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	name: MultiLang;
	defaultLang: LanguageCode;
	type: null | OrganizationType;
	shortDesc: null | MultiLang;
	website: null | MultiLang;
	logoUrl: null | MultiLang;
	nameIsInLogo: null | MultiLang<boolean>;
}

export type IOrganizationArgs = PickRequired<IOrganization, "name" | "defaultLang">



export const OrganizationValidators: Partial<Record<keyof IOrganization, Array<{
	test: RegExp;
	message: string;
}>>> = {
	name: Validators.lengthRange(2, 50),
	shortDesc: Validators.lengthRange(25, 250),
	website: [
		Validators.maxLength(350),
		...Validators.url(),
	],
	logoUrl: [
		Validators.maxLength(450),
		...Validators.url(),
	],
}
