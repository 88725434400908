import { ISubscriber, ServerSubscriptionHub } from "../LiveSocket";


export class DefaultWebSocketMod {
  constructor(protected args: {
    webSocket: WebSocket,
    subHub: ServerSubscriptionHub,
  }) {}

  public get ws() { return this.args.webSocket; }
  public get subHub() { return this.args.subHub; }

  public fullSetup() {
    this.registerClient();
    this.setupMessageParser();
  }

  protected subscriber: ISubscriber;
  public registerClient() {
    this.subscriber = this.args.subHub.registerClient({
      updateFn: async (updates) => {
        this.ws.send(JSON.stringify({
          updates
        }))
      }
    });
    this.ws.send(JSON.stringify({ subscriberId: this.subscriber.id }));

    this.ws.addEventListener("close", () => {
      this.subHub.dropClient(this.subscriber.id);
    })
  }

  public setupMessageParser(subscriberId?: string) {
    subscriberId = subscriberId || this.subscriber.id;
    if (!subscriberId) {
      throw new Error(`Subscriber id must exist`);
    }
    this.ws.addEventListener("message", (msg) => {
      try {
        const data = JSON.parse(String(msg.data));
        if ("startSub" in data) {
          this.subHub.registerSub({
            subscriberId: this.subscriber.id,
            repoId: "city",
            filter: data.startSub
          })
        }
      } catch(err) {
        console.error(err);
      }
    });
  }

}