import { LanguageCode } from "dot-earth-middleware";
import { Sourcify, syncWithLocalStorage } from "helium-ui";


export class CustomizationState {
	public readonly state = Sourcify<{
		lang: LanguageCode;
		translatorTooltips?: boolean;
	}>({
		lang: "en",
	});

	constructor() {
		syncWithLocalStorage("CustomizationState", this.state);
	}
}
