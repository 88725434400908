import { Sourcify, UrlState, noDeps, HeliumRouteState } from "helium-ui";
import { IconType } from "./Icons";

UrlState.preventRefreshWarningGiven = true;

export type RouteParamId = "sidebar";

// export type RouteSection = "map" | "admin" | "account"

export interface IRouteConfig {
	id: string;
	test: null | string | RegExp;
	paramId?: RouteParamId;
	url?: string | (() => string);
	icon?: IconType;
}


const RouteConfigs = {
	"root": {
		test: /^\/?$/
	},
	// "pois": {
	// 	test: null,
	// 	paramId: "sidebar"
	// },
	// "tags": {
	// 	test: null,
	// 	paramId: "sidebar"
	// },
	"action-details": {
		test: "/action",
		icon: "megaphone",
	},
	"org-details": {
		test: "/org",
		icon: "graph-nodes",
	},
	"map": {
		test: "/map",
		icon: "map-pin"
	},
	"map.organizations": {
		test: "/map/organizations",
		icon: "map-pin"
	},
	"map.actions": {
		test: "/map/actions",
		icon: "map-pin"
	},
	"login": {
		test: "/login",
		icon: "user-front-profile"
	},
	"admin": {
		test: "/admin",
		icon: "shield-user",
	},
	"sign-in": {
		test: "/sign-in",
		icon: "user-front-profile"
	},
	"user": {
		test: "/user",
		icon: "user-front-profile"
	}
// } as Record<string, Omit<IRouteConfig, "id">>;
} as const;

export type RouteId = keyof typeof RouteConfigs;

export const knownBESitePaths = ["/dev", "/app", "/embed/collab"];

export class RouteState extends HeliumRouteState<RouteId, IRouteConfig> {
	public readonly history: URL[] = [];
	public readonly UrlState = UrlState;



	constructor() {
		super(RouteConfigs);
		UrlState.onUrlChange(({ newUrl }) => {
			console.log(newUrl);
			this.history.push(newUrl);
		})
	}

	public get pathname() {
		if (UrlState.host === "boulder.earth") {
			const subpath = knownBESitePaths.find((it) => UrlState.pathname.startsWith(it));
			if (!subpath) {
				throw new Error(`Unknown boulder.earth subpath`);
			}
			return super.pathname.replace(subpath, "");
		}
		return super.pathname;
	}

	public get lastUrl() {
		return this.history.slice(-2)[0];
	}

	public getConfig(routeId: RouteId) {
		return this.configMap.get(routeId);
	}

	public getUrlForRoute(routeId: RouteId) {
		const url = super.getUrlForRoute(routeId);
		if (UrlState.host === "boulder.earth") {
			const subpath = knownBESitePaths.find((it) => UrlState.pathname.startsWith(it));
			if (!subpath) {
				throw new Error(`Not on a boulder.earth subpath`);
			}
			return subpath + url;
		}
		return url;
	}

	public getRouteConfigForUrl(url: string | URL) {
		const pathname = typeof url === "string" ? (new URL(url)).pathname : url.pathname;
		return this.configList.find((config) => {
			switch (typeof config.test) {
				case "string": return pathname.toLowerCase().startsWith(config.test.toLowerCase());
				case "function": return (config.test as any)(pathname);
				case "object": return config.test!.test(pathname);
			}
		});
	}
}


// class HeliumRouteState<
// 	ROUTE_ID extends string,
// 	CONFIG extends IRouteConfig = IRouteConfig
// > {
// 	protected configMap = new Map<ROUTE_ID, CONFIG & {id: ROUTE_ID}>();
// 	protected configList = new  Array<CONFIG & {id: ROUTE_ID}>();
// 	protected state = Sourcify({
// 		currentConfig: null as null | (CONFIG & {id: ROUTE_ID})
// 	});

// 	constructor(configs: Record<ROUTE_ID, Omit<CONFIG, "id">>) {
// 		Object.entries(configs).forEach(([routeId, config]: [ROUTE_ID, CONFIG]) => {
// 			config.id = routeId;
// 			this.configMap.set(routeId, config as any);
// 			this.configList.push(config as any);
// 		});

// 		this.configList.sort((a, b) => {
// 			if (!a.test) { return 1; }
// 			if (!b.test) { return -1; }
// 			const aLength = typeof a.test === "string" ? a.test.length : a.test.source.length;
// 			const bLength = typeof b.test === "string" ? b.test.length : b.test.source.length;
// 			return bLength - aLength;
// 		});

// 		console.log(this.configList);

// 		this.initUrlObserver();
// 	}

// 	public get id(): ROUTE_ID | "NO_ROUTE" {
// 		return this.config ? (this.config.id as ROUTE_ID) : "NO_ROUTE";
// 	}

// 	public get config() {
// 		return this.state.currentConfig;
// 	}

// 	public getPathSlice(sliceNum: number) {
// 		const slices = this.pathname.split(/\/+/g).filter((it) => !!it);
// 		return slices[sliceNum - 1];
// 	}

// 	public getUrlForRoute(routeId: ROUTE_ID) {
// 		const {test, url } = this.configMap.get(routeId)!;
// 		if (typeof test === "string") {
// 			return test;
// 		}
// 		if (!url) {
// 			throw new Error(`The "path" property is not defined in the config for "${routeId}"`);
// 		}
// 		return typeof url === "string" ? url : url();
// 	}

// 	public followUrlToRoute(routeId: ROUTE_ID) {
// 		const url = this.getUrlForRoute(routeId);
// 		UrlState.followUrl(url);
// 	}

// 	public replaceUrlToRoute(routeId: ROUTE_ID) {
// 		const url = this.getUrlForRoute(routeId);
// 		UrlState.replaceUrl(url);
// 	}

// 	public get pathname() { return UrlState.pathname; }

// 	protected initUrlObserver() {
// 		UrlState.onUrlChange((ev) => {
// 			if (ev.newUrl.host === location.host) {
// 				ev.preventPageRefresh();
// 			}
// 		});

// 		UrlState.observeUrl((ev) => {
// 			const configForUrl = this.configList.find((config) =>
// 				this.configMatchesCurrentUrl(config)
// 			);

// 			this.state.set("currentConfig", configForUrl || null);
// 		})
// 	}

// 	protected configMatchesCurrentUrl(config: CONFIG) {
// 		if (!config.test) { return false; }
// 		if (typeof config.test === "string") {
// 			return this.pathname.toLowerCase().startsWith(config.test.toLowerCase());
// 		}
// 		return config.test.test(this.pathname);
// 	}

// }







// export class RouteState {
// 	protected configs: Record<RouteId, IRouteConfigs & { id: RouteId }> = (() => {
// 		const out = {};
// 		Object.entries(RouteConfigs).forEach(([key, value]) => {
// 			out[key] = value;
// 			out[key].id = key;
// 		})
// 		return out as any;
// 	})()

// 	protected state = Sourcify<{
// 		pathRouteId: RouteId | null,
// 		paramRouteId: Record<RouteParamId, RouteId | null>;
// 	}>({
// 		pathRouteId: null,
// 		paramRouteId: {
// 			"sidebar": null
// 		}
// 	});

// 	constructor() {
// 		UrlState.onUrlChange((ev) => {
// 			if (ev.newUrl.host === location.host) {
// 				ev.preventPageRefresh();
// 			}

// 			const route =
// 		})
// 	}
// }
