import "./locationUseList.scss";

import { br, button, div, noDeps, Source } from "helium-ui";
import { OrganizationEntity } from "MapApp/state/entities/Organization";
import { App } from "MapApp/state/AppState";
import { isPhaseResponse } from "helium-source-repo";
import { renderLocationChooser } from "../LocationChooser/LocationChooser";
import { LocationEntity } from "MapApp/state/entities/Location";
import { ListEditArgs, ListEditContext } from "../Editors/ListEditor";
import { LocationUseEntity } from "dot-earth-client";
import { renderEditIcon } from "../Editors/EditIcon";
import { DotEarthMap } from "../Map/Map";
import { ConnectionState } from "MapApp/state/ConnectionState";
import { createEntTextFieldEditor, renderEntTextEditor } from "../Editors/EntTextfieldEditor";


export interface ILocationUseListArgs {
	target: string | OrganizationEntity;
	edits?: Omit<ListEditArgs<LocationUseEntity>, "anchor" | "getItems"> & {
		allowEditing: () => boolean;
	};
}

export function renderLocationUseList(args: ILocationUseListArgs) {
	const { edits } = args;
	let _editorSrc = new Source<LocationUseEditor | undefined>();
	let rootDomNode: HTMLElement;
	const connections = new ConnectionState<LocationUseEntity>({
		getItemsDdx: () => {
			const items = getHeldItemsDdx();
			return Array.isArray(items) ? items : [];
		},
		deriveConnections: "ALL_ITEMS"
	});

	return div("LocationUseList", {
		ref: (ref) => rootDomNode = ref,
		ddxClass: () => editing() && "--editing",
		ddx: () => {
			if (_editorSrc.peek() || !args.edits || !args.edits.allowEditing()) { return; }
			const editor = new LocationUseEditor({
				...args.edits,
				anchor: rootDomNode,
				getItems: getLocUseList,
			});
			_editorSrc.set(editor);
		}
	},[
		div("LocationList cardList-contained", [
			() => {
				const editor = _editorSrc.get()
				if (!editor || !edits || !edits.allowEditing()) { return; }
				return renderEditIcon({
					context: editor,
				});
			},
			() => {
				const locUses = getAllItemsDdx();
				if (isPhaseResponse(locUses)) { return "Loading..."; }
				if (locUses.length === 0) {
					return "This organization currently has no geographic orientation"
				}

				return [
					div("cardList-Title", "Locations"),
					locUses.map((locUse) => () => {
						const loc = locUse!.locEnt.get() as LocationEntity;
						if (!loc) { return; }
						const state = editing() && _editorSrc.get()!.getEditStateForItem(loc);
						if (state === "DROP") {
							return div("UndoDrop cardList-Item", {
								innards: "Undo Removal",
								onPush: () => _editorSrc.get()!.addItem(loc)
							})
						}
						const editArgs = {
							item: loc,
							multiLang: true,
							getEditor: () => editing() ? loc.getEditor(args.edits?.register) : false,
						}
						return div("Location cardList-Item", {
							onPush: () => connections.focus = locUse,
							ddxClass: () => connections.focus === locUse && "--focused",
							innards: [
								() => editing() && div("Delete", {
									onPush: () => _editorSrc.get()!.dropItem(loc),
									innards: "DELETE",
								}),
								() => renderEntTextEditor({...editArgs, prop: "name"}),
								() => renderEntTextEditor({...editArgs, prop: "desc"}),
								() => `type: ${locUse.type}`,
							]
						})
					})
				]
			},
			() => {
				const editor = _editorSrc.get();
				return editor?.editing && button("AddLocation", {
					innards: "Add a location",
					onPush: () => {
						console.log("Adding modal");
						let modal = App.modals.add({
							clickOutClose: true,
							container: {
								type: "tightCard",
								resizable: true,
								// onResize: () => App.map.mapbox.resize()
							},
							domNode: () => renderLocationChooser({
								onLocationConfirm: (loc) => {
									console.log("addItem", loc, loc.data?.toObject());
									editor.addItem(loc);
									modal.close();
									editor.editing = true;
								},
								edits: args.edits
							}),
							onClose: reclaimMap,
						})
					}
				})
			}
		]),
		div("Map", {
			onUnfreeze: reclaimMap,
			innards: () => {
				const map = new DotEarthMap({
					connections,
					getLoc: (item) => item.locEnt.get()
				});
				return map.domNode;
			}
			// [
			// 	button("ShowMap", {
			// 		onPush: reclaimMap,
			// 		innards: "Show Map"
			// 	})
			// ]
		}),
	])

	function editing() {
		return _editorSrc.get() && _editorSrc.get()!.editing;
	}

	function reclaimMap() {
		// append(map, App.map.domNode);
		// App.map.mapbox.resize();
	}

	function getAllItemsDdx() {
		const editor = _editorSrc.get();
		return editor?.editing ? editor.currentValue : getLocUseList();
	}

	function getHeldItemsDdx() {
		const editor = _editorSrc.get();
		return editor?.editing ? editor.heldItemList : getLocUseList();
	}

	function getLocUseList() {
		const targetId = typeof args.target === "string" ? args.target : args.target.id;
		return App.ents.locUse.find.phased({ where: { targetId }});
	}
}














export type LocOrId = LocationEntity | string;
export class LocationUseEditor extends ListEditContext<LocationUseEntity, LocOrId> {

	public findItem(idOr: LocOrId) {
		const id = typeof idOr === "string" ? idOr : idOr.getId();
		const target = noDeps(() => this.itemMap.keys().find((item) => item.locationId === id));
		target && (void this.itemMap.has(target));
		return target;
	}

	public createItem(idOr: LocOrId) {
		const template = App.ents.locUse.createTemplateController();
		const loc = typeof idOr === "string" ? App.ents.loc.get(idOr) : idOr;
		template.locEnt.set(loc);
		return template;
	}

	protected targetId: string;
	public setTarget(id: string) {
		this.targetId = id;
	}

	public save = async () => {
		const { targetId } = this;
		if (!targetId) {
			throw new Error(`Must use setTarget() before save`);
		}

		const drops = this.itemMap.keys().filter((key) => this.itemMap.get(key) === "DROP");
		for (const item of drops) {
			await item.delete.confirm();
			this.itemMap.delete(item);
		}

		const adds = this.itemMap.keys().filter((key) => this.itemMap.get(key) === "ADD");
		for (const item of adds) {
			const loc = item.locEnt.get();
			if (loc?.hasEdits) {
				await loc.save();
				item.stageEdits({ locationId: loc.id })
			}
			item.stageEdits({ targetId, type: "main-hq" })
		}
		await Promise.all(adds.map((item) => item.save()));
		return true;
	};
}
