import "./sidebar.scss";

import { isPhaseResponse } from "helium-source-repo";
import { div, anchor, br, img, addClass } from "helium-ui";
import { OrganizationEntity } from "MapApp/state/entities/Organization";
import { App } from "MapApp/state/AppState";
import { RouteId } from "MapApp/state/RouteState";
import { renderTagExplorer } from "../TagExplorer/TagExplorer";
import { MapExploreConnectionState } from "./MapExploreConnectionState";
import { inLang } from "../InLang/InLang";




export function renderMapExploreSidebar(args: {
	connections: MapExploreConnectionState
}) {
	const { connections } = args;
	const txt = App.txt.mapSidebar;
	const txtGeneral = App.txt.general;
	return div("MapExploreSidebar", [
		div("ModeNav sidebar-TabArea em-0-85", ([
			["map.actions", txtGeneral.collabList],
			["map.organizations", txtGeneral.orgList],
		] as [RouteId, () => HTMLSpanElement][]).map(([routeId, name]) =>
			anchor("sidebar-Tab", {
				ddxClass: () => App.route.id === routeId && "--selected",
				innards: name,
				href: App.route.getUrlForRoute(routeId),
			})
		)),
		div("ModeView", () => {
			const routeId = App.route.id;
			if (routeId.startsWith("map") === false) {
				return `Error: no view set for route "${routeId}"`
			}
			switch (routeId) {
				case "map.organizations": return renderSidebarOrgView();
				case "map.actions": return renderSidebarActionsView();
				default: App.route.replaceUrlToRoute("map.organizations");
			}
		})
	]);



	function renderSidebarOrgView() {
		return [
			div([
				div("cardList-Title", txt.orgs.label.tags),
				renderTagExplorer({ connections }),
			]),
			br(2),
			div([
				div("cardList-Title", [
					() => connections.filter.tag && div("TagTitleFilter", [
						txt.orgs.label.selectedTag,
						": ",
						() => inLang(connections.filter.tag?.name)
					]),
					txtGeneral.orgList,
					() => connections.connectedItems.length === 0 ? null : ` (${connections.connectedItems.length})`
				]),
				div("OrganizationList cardList-contained", {
					ddxClass: () => connections.filter.tag && "--showOnlyConnections",
					innards: () => {
						const items = App.ents.org.getAllPhased();
						if (isPhaseResponse(items)) { return; }
						return items
							.sort((a, b) => a.name! < b.name! ? -1 : 1)
							.map((item) => item.name && item.website && addClass("cardList-Item", renderOrganizationListItem(item)))
					}
				}),
				() => connections.filter.tag && div("ClearFilter btn", {
					onPush: () => connections.filter.tag = undefined,
					innards: txt.orgs.btn.clearTagSelection
				})
			]),
			br(3)
		];
	}


	function renderOrganizationListItem(item: OrganizationEntity) {
		return div("Organization", {
			onPush: () => connections.focus = item,
			ddxClass: () => [
				connections.focus === item && "--focused",
				connections.isConnected(item) && "--connected"
			] as any,
			ddx: (ref) => {
				if (connections.focus === item) {
					ref.scrollIntoView({ behavior: "smooth", block: "center" });
				}
			},
			innards: [
				anchor("Details", {
					href: () => `${App.route.getUrlForRoute("org-details")}/${item.id}`,
				}),
				div("LogoContainer", () => {
					if (inLang(item.logoUrl)) {
						return img("Logo", { src: inLang(item.logoUrl) });
					}
					return div("PlaceholderLogo", inLang(item.name) ? inLang(item.name).charAt(0) : "?");
				}),
				!inLang(item.nameIsInLogo) && div("Name", {
					class: inLang(item.nameIsInLogo) ? "subLogo" : "",
					innards: inLang(item.name)
				})
			]
		})
	}




	function renderSidebarActionsView() {
		return [
			div([
				div("cardList-Title", txtGeneral.collabList),
				div("ActionList cardList-contained", () => {
					const actions = App.ents.act.getAllPhased();
					if (actions === "PENDING") { return "Loading Actions..."; }
					if ("error" in actions) { throw actions.error; }

					return actions.map((act) => div("Action cardList-Item", {
						ddxClass: () => connections.filter.action === act ? "--focused" : "",
						onPush: () => connections.filter.action = act,
						innards: act.title
					}))
				}),
				br(2),
			]),
			div([
				div("cardList-Title", txt.actions.label.actionInfo),
				div("ActionDetails", () => {
					const act = connections.filter.action;
					if (!act) {
						return txt.actions.label.noActSelected();
					}
					return [
						div("ActionTitle text-center em-1-25", act.title),
						br(0.5),
						div("BriefDesc em-0-9", act.briefDesc),
						br(),
						div("text-all-caps em-0-9 text-bold text-underline text-center", txtGeneral.orgList),
						br(0.5),
						div("CollaboratorList", () => {
							const roles = act.memberRoles.getAllPhased();
							if (isPhaseResponse(roles)) { return; }
							return roles.map((role) => () => {
								if (!role.holderId) { return; }
								const org = App.ents.org.get(role.holderId);
								if (org.state.loading) { return; }
								return div("Collaborator", {
									title: inLang(org.name)!,
									onPush: () => connections.focus = org,
									ddxClass: () => connections.focus === org && "--focused",
									innards: div("Icon", {
										style: {
											backgroundImage: `url("${inLang(org.logoUrl)}")`,
										},
										innards: !org.logoUrl && inLang(org.name)!.charAt(0)
									}),
								});
							})
						}),
						br(),
						anchor("btn margin-auto", {
							innards: txt.actions.btn.moreInfo,
							href: `${App.route.getUrlForRoute("action-details")}/${act.id}`
						})
					]
				})
			])
		]
	}
}
