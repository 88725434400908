import { LocationEntity } from "dot-earth-client";
import { deriveDomAnchored } from "helium-ui";
import * as mapboxgl from "mapbox-gl";

export interface ICreatePopupArgs {
	location: mapboxgl.LngLatLike | (() => LocationEntity | undefined);
	renderPopup: () => HTMLElement;
}


export function createPopup(args: ICreatePopupArgs) {
	const popup = new mapboxgl.Popup({offset: 25});
	const domNode = args.renderPopup();
	popup.setDOMContent(domNode);

	const { location } = args;

	if (typeof location === "function") {
		popup.setLngLat([0,0]);
		deriveDomAnchored(domNode, () => {
			const loc = location();
			if (loc && typeof loc.lat === "number" && typeof loc.lng === "number") {
				popup.setLngLat({lng: loc.lng, lat: loc.lat});
			}
		})
	} else {
		popup.setLngLat(location);
	}

	return popup;
}
