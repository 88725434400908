import { PickRequired } from "../PickRequired";


export const RoleTypeList = [
	"owner",
	"friend",
	"member",
] as const;

export type RoleType = typeof RoleTypeList[number];

export interface IRole {
	id: string;
	createdAt: Date;
	holderId: string;  // usually usr_  but could be org_
	contextId: string;  // usr_, org_, act_
	type: RoleType;
}

export type IRoleArgs = PickRequired<IRole, "contextId" | "holderId" | "type">;
