import "./entities.scss";

import { OrganizationEntity as _ent, OrganizationManager as _manager, TagEntity } from "dot-earth-client";
import { App } from "../AppState";
import * as mapboxgl from "mapbox-gl";
import { noDeps, derive, haveClass, deriveAnchorless, anchor, img, div } from "helium-ui";
import { renderOrgCard } from "MapApp/components/OrgCard/OrgCard";
import { ISourceRepoControllerArgs, jit, jitHas } from "helium-source-repo";
import { IOrganization } from "dot-earth-middleware";
import { inLang } from "MapApp/components/InLang/InLang";


export class OrganizationManager extends _manager<OrganizationEntity> {
	protected getDefaultArgs() {
		return {
			repo: () => super.getDefaultArgs().repo,
			createController: (args) => new OrganizationEntity(args)
		}
	}

	// public get focused() {
	// 	return App.connections.focusedOrg;
	// }
}


export class OrganizationEntity extends _ent {
	constructor(args: ISourceRepoControllerArgs<IOrganization, "id", IOrganization>) {
		super(args);
		noDeps(() => {
			// derive(() => {
			// 	const focused = this.focused;
			// 	if (jitHas(this, "marker")) {
			// 		haveClass(this.marker.getElement(), "--focused", focused);
			// 	}
			// });
			// derive(() => {
			// 	const connected = this.connected;
			// 	if (jitHas(this, "marker")) {
			// 		haveClass(this.marker.getElement(), "--connected", connected);
			// 	}
			// });
			// derive(() => {
			// 	// const {lat, lng} = this;
			// 	const { lngLat } = this;
			// 	if (lngLat && this.mustCenter) {
			// 		this.mustCenter = false;
			// 		this.centerMapOn();
			// 	}
			// })
			// derive(() => {
			// 	const tagName = App.connections.focusedTag;
			// 	this.connected = this.hasTag(tagName);
			// })
		})
	}

	// public get tagEnts() {
	// 	return this.jitItems.get("tagEnts", () => {
	// 		const tags = App.ents.tag.getAll();
	// 		const tagEnts = this.tags.map((tagName) => {
	// 			const out = tags.find((tag) => tag.name.en === tagName);
	// 			if (!out) { console.warn(`not a real tag ${tagName}`)};
	// 			return out;
	// 		}).filter((it) => !!it);
	// 		return tagEnts;
	// 	})
	// }

	// public focus() {
	// 	App.connections.focusedOrg = this;
	// }

	// public get connected() {
	// 	return !!App.connections.web[this.id];
	// }

	// // public set connected(value) {
	// // 	App.connections.web[this.id] = value;
	// // }

	// public get focused() {
	// 	return App.connections.focusedOrg === this;
	// }

	public getFaviconUrl() {
		const url = this.getWebsiteUrl();
		return !url ? null: url.origin + "/favicon.ico";
	}

	public getWebsiteUrl() {
		if (!this.website) { return null; }
		try {
			return new URL(inLang(this.website));
		} catch {
			return null;
		}
	}

	// protected mustCenter = false;
	// public centerMapOn(
	// 	args: {
	// 		type?: "snap" | "pan" | "fly"
	// 	} = {
	// 		type: "pan"
	// 	}
	// ) {
	// 	noDeps(() => {
	// 		if (this.state.loading || !this.lngLat) {
	// 			return this.mustCenter = true;
	// 		}
	// 		if (args.type === "pan") {
	// 			App.map.mapbox.panTo(this.lngLat)
	// 		} else {
	// 			App.map.mapbox.setCenter(this.lngLat)
	// 		}
	// 	});
	// }


	// public get popup(): mapboxgl.Popup {
	// 	return jit(this, "popupEl", () => {
	// 		const popup = new mapboxgl.Popup({offset: 25});
	// 		popup.setDOMContent(renderOrgCard({ org: this }));
	// 		deriveAnchorless(() => {
	// 			const { lngLat } = this;
	// 			lngLat && popup.setLngLat(lngLat);
	// 		})
	// 		return popup;
	// 	})
	// }

	// public get marker(): mapboxgl.Marker {
	// 	return jit(this, "marker", () => {
	// 		const marker = new mapboxgl.Marker({
	// 			scale: 0.5,
	// 			color: "inherit",
	// 		});
	// 		noDeps(() => {
	// 			haveClass(marker.getElement(), "--focused", this.focused);
	// 			haveClass(marker.getElement(), "--connected", this.connected);
	// 		});
	// 		marker.setLngLat([0,0]);
	// 		deriveAnchorless(() => {
	// 			const { lngLat } = this;
	// 			lngLat && marker.setLngLat(lngLat);
	// 		})
	// 		const markerEl = marker.getElement();
	// 		markerEl.style.cursor = "pointer";
	// 		markerEl.addEventListener("click", () => this.focus())
	// 		return marker;
	// 	})
	// }

	public get detailPageUrl() {
		return App.route.getUrlForRoute("org-details") + `/${this.id}`;
	}

	public renderLink() {
		return !this.website ? null : anchor("Website", {
			target: "_blank",
			href: () => inLang(this.website!),
			innards: () => this.getWebsiteUrl() ? this.getWebsiteUrl()!.hostname.replace("www.", "") : inLang(this.website)
		});
	}

	public renderLogo() {
		return div("LogoContainer", () => {
			if (inLang(this.logoUrl)) {
				return img("Logo", { src: inLang(this.logoUrl) });
			}
			return div("PlaceholderLogo", inLang(this.name).charAt(0));
		});
	}

	public renderTitleBox() {
		return div("OrgTitleBox", [
			this.renderLogo(),
			inLang(this.nameIsInLogo) ? null : inLang(this.name)
		]);
	}
}
