import "./orgCard.scss";

import { div, span, img, anchor, h4, noDeps, br } from "helium-ui";
import { App } from "src/MapApp/state/AppState";
import { OrganizationEntity } from "MapApp/state/entities/Organization";
import { isPhaseResponse } from "helium-source-repo";
import { OnTagSelectArg, renderTagUseList } from "../TagUseList/TagUseList";
import { MapExploreConnectionState } from "../MapExplorePage/MapExploreConnectionState";
import { inLang } from "../InLang/InLang";

export interface IOrgCardProps {
	org: OrganizationEntity;
	connections?: MapExploreConnectionState;
	onTagSelect?: OnTagSelectArg;
	components?: {
		activeCollabs?: boolean;
	}
}

export function renderOrgCard(props: IOrgCardProps) {
	const { org } = props;
	return div("OrgCard", [
		anchor("TitleArea insetArea", {
			href: App.route.getUrlForRoute("org-details") + `/${org.id}`
		},[
			div("ImageContainer",() => {
				if (org.logoUrl) {
					return img("Logo", { src: inLang(org.logoUrl) });
				}
				return div("PlaceholderLogo", inLang(org.name).charAt(0));
			}),
			!org.nameIsInLogo && div("Name", inLang(org.name)),
		]),

		br(0.5),


		() => {
			if (props.components?.activeCollabs === false) { return; }
			const roles = org.roles.find.phased({where: { type: "member"}});
			if (isPhaseResponse(roles) || !roles.length) { return; }
			return [
				div("Actions insetAreaPadded", [
					h4([App.txt.orgCard.label.activeActs]),
					div("ActionList", roles.map(({contextId}) => () => {
						const act = contextId && App.ents.act.get(contextId);
						return act && anchor("Action", {
							href: App.route.getUrlForRoute("action-details") + "/" + act.id,
							innards:() =>  act.title,
						})
					}))
				]),
				br(0.5),
			]
		},

		div("DescriptionArea insetAreaPadded", [
			() => org.shortDesc && div("ShortDesc", inLang(org.shortDesc).charAt(0).toLocaleUpperCase() + inLang(org.shortDesc).slice(1)),
			br(0.5),
			renderTagUseList({
				target: org,
				connections: props.connections as any,
				onTagSelect: props.onTagSelect,
			}),
		]),

		org.renderLink(),
	])
}
