import { derive, Source } from "helium-sdx";

export class OneToOneJoin<T> {
	protected isUnset = new Source(true);
	protected value = new Source<T>();

	constructor(
		protected getItemDdx: () => T,
		updateDdx?: (it: T) => any,
	) {
		derive(() => {
			if (this.isUnset.get() === false) { return; }
			this.value.set(getItemDdx());
		});
		updateDdx && derive(() => {
			updateDdx(this.get())
		})
	}

	public get() {
		return this.value.get();
	}

	public set(val: T) {
		this.value.set(val);
		this.isUnset.set(false);
	}

	public unset() {
		this.isUnset.set(true);
	}
}
