import { br, button, div, h2, inputTextbox, italic, Sourcify, span } from "helium-ui";
import { App } from "MapApp/state/AppState";


export function renderSignInPage() {
	const state = Sourcify({
		email: null as string | null,
		pass: null as string | null,
		viewPass: false,
		accountCreated: false,
	})
	return div("SignInPage", [
		h2(["Sign-up / Log-in"]),
		div("Content", () => {
			const boss = App.api.currentUser;
			if (!boss) {
				return renderSignInContent();
			}
			return renderSignOutContent();
		})

	])


	function renderSignInContent() {
		return [
			italic(["Note from dev: Please write down your password, as there is no user facing recovery system yet."]),
			br(),
			div([
				"email: ",
				inputTextbox({
					onInput: (val) => state.email = val,
					innards: () => state.email
				}),
			]),
			div([
				"password: ",
				inputTextbox({
					onInput: (val) => state.pass = val,
					innards: () => state.pass,
					ddx: (ref) => {
						const type = state.viewPass ? "text" : "password";
						setTimeout(() => ref.setAttribute("type", type), 0);
					}
				}),
				button({
					onPush: () => state.viewPass = !state.viewPass,
					innards: () => state.viewPass ? "Hide Password" : "View Password"
				})
			]),
			button({
				ddxClass: () => (state.email && state.pass) || "--disabled",
				innards: "Sign in",
				onPush: async () => {
					const {email, pass} = state;
					if (!email || !pass) { return; }
					try {
						const { wasCreated } = await App.api.signIn({
							email,
							password: pass
						});
						wasCreated && (state.accountCreated = true);
					} catch(err) {
						App.modals.add({
							clickOutClose: true,
							domNode: () => span([err + ""]),
							container: {
								type: "error"
							}
						})
					}
				}
			}),
		]
	}

	function renderSignOutContent() {
		return [
			() => state.accountCreated && ["New Account Created!", br()],
			`You are currently logged in.`,
			br(),
			button({
				innards: "Log out",
				onPush: () => App.api.logout()
			})
		]
	}
}
