import { SourceRepoController } from "helium-source-repo";
import { SourceRepoControllersManager, SourceRepo } from "helium-source-repo";
import { IRole, IRoleArgs } from "dot-earth-middleware";
import { repoApiArgs } from "./EntityCommon";


export class RoleManager<TYPE extends RoleEntity>
	extends SourceRepoControllersManager<TYPE>
{
	protected getDefaultArgs() {
		return {
			repo: new SourceRepo<IRole, "id", IRoleArgs>({
				repoId: "Role",
				idPropName: "id",
				...repoApiArgs("role"),
			}),
			createController: (data) => new RoleEntity(data),
		} as any;
	}
}


export class RoleEntity
	extends SourceRepoController<IRole, "id">
	implements Record<keyof IRole, any>
{
	public _noHeliumProxy = true;

	public get id() { return this.getId(); }
	public get createdAt() { return this.getProp("createdAt"); }
	public get holderId() { return this.getProp("holderId"); }
	public get contextId() { return this.getProp("contextId"); }
	public get type() { return this.getProp("type"); }
}
