import { DeriverScope, noDeps, SourceArray } from "helium-sdx";
import { IdType } from "../SelectionArgs";
import { ISelectionFilter, matchesFilter } from "../Selections/SelectionFilter";
import { createIdFromObject } from "../Utils/createIdFromObject";

export interface IFilterCollectionArgs<T extends object> {
	filterId?: string;
	filter?: ISelectionFilter<T>;
	deps: DeriverScope[];
	startItems: T[];
	getId(item: T): string | number;
}

export class FilterCollection<T extends object> {
	public readonly list = new SourceArray<T>();
	protected idMap = new Map<string | number, void>();
	protected deps: DeriverScope[] = [];

	constructor(protected args: IFilterCollectionArgs<T>) {
		args.startItems.forEach(this.upsertItem.bind(this));
		args.deps.forEach(this.addDep.bind(this));
		this._id = args.filterId;
	}

	public upsertItem(item: T) {
		const id = this.args.getId(item);
		if (this.idMap.has(id)) {
			const targetIndex = noDeps(() => this.list.findIndex((it) => this.args.getId(it) === id));
			if (targetIndex !== -1) {
				this.list.splice(targetIndex, 1, item);
				return;
			}
		}
		this.idMap.set(id);
		this.list.push(item);
	}

	public tryUpsertItem(item: T) {
		if (!this.args.filter || matchesFilter(item, this.args.filter)) {
			this.upsertItem(item);
		}
	}

	public removeItemById(id: any) {
		if (this.idMap.has(id)) {
			this.idMap.delete(id);
			noDeps(() => {
				const targetIndex = this.list.findIndex((it) => this.args.getId(it) === id);
				this.list.splice(targetIndex, 1);
			})
		}
	}

	protected _id: string | undefined;
	public get id() {
		return this._id = this._id || createIdFromObject(this.args.filter || {});
	}

	public addDep(dep: DeriverScope) {
		this.deps.includes(dep) || this.deps.push(dep);
	}
}
