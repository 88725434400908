import { MultiLang } from "../consts/LanguageCodes";
import { PickRequired } from "../PickRequired";

export interface IPointOfInterest {
	id: string;
	name: string;
	lat: number;
	lng: number;
	tags: string[];
	short_desc: string;
	website: string;
	logo_url: string;
	logo_is_name: boolean;
}




export const LocationTypeList = [
	"natural-landmark",
	"park",
	"garden",
	"parking-lot",
	"building",
	"house",
	"campus",
	"stadium",
	"station",
	"intersection"
] as const;

export type LocationType = typeof LocationTypeList[number];


export interface ILocation {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	lat: number;
	lng: number;
	type: LocationType;//"branch" | "hq" | "local-hq" | "general-center" | "landmark";
	name: MultiLang;
	desc: null | MultiLang;
}

export type ILocationArgs = PickRequired<ILocation, "lat" | "lng" | "type" | "name">;
