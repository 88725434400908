import "./commonPage.scss";

import { addClass, anchor, Innards, setStyle } from "helium-ui";
import { div } from "helium-ui";
import { App } from "MapApp/state/AppState";

export function renderCommonPage(props: {
	innards: Innards;
	back?: { lastPage: true | string } | Innards;
}) {
	return div("CommonPage", [
		// props.back && (() => {
		// 	if (props.back && typeof props.back === "object" && "lastPage" in props.back) {
		// 		const lastPage = typeof props.back === "string" ? props.back : App.route.lastUrl;
		// 		if (!lastPage) {
		// 			return;
		// 		}
		// 		const routeConf = App.route.getRouteConfigForUrl(lastPage);
		// 		console.log(routeConf);
		// 		return anchor("Back", {
		// 			href: () => typeof lastPage === "string" ? lastPage : lastPage.href,
		// 		},[
		// 			addClass("Arrow", setStyle(App.icon.getIconSpan("right-arrow"), {
		// 				transform: "scaleX(-1)"
		// 			})),
		// 			routeConf && routeConf.icon && addClass("bubbled", App.icon.getIconSpan(routeConf.icon)),
		// 		])
		// 	}
		// 	return div("Back", props.back);
		// }),
		div("PaperContainer", props),
	]);
}
