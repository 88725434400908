import { AnchorNode, AnchorStatus, DerivationManager } from "helium-sdx";
import { SourceRepo } from "../../Repos";
import { WhereFilter } from "../../Selections/SelectionFilter";


export type AnchorsForFilter = Map<string, Map<SubscriptionAnchor, void>>;
export type SubsForRepo = Map<SourceRepo<any, any, any>, AnchorsForFilter>;



export type DataFromRepo<B> = B extends SourceRepo<infer T, any, any> ? T : never;
export type IdNameFromRepo<B> = B extends SourceRepo<any, infer T, any> ? T : never;
// export type IdTypeFromRepo<B> = B extends SourceRepo<any, any> ? DataFromRepo<B>[IdNameFromRepo<B>] : never;

export class ClientSubscriptionHub {

  constructor(protected args: { wsUrl: string }) {

  }

  protected repoSubscriptions: SubsForRepo = new Map();

  protected _subscriberId: string;
  public get subscriberId() {
    return this._subscriberId;
  }

  public createAnchor<
    REPO extends SourceRepo<T, ID, any>,
    T extends object = DataFromRepo<REPO>,
    ID extends keyof T = IdNameFromRepo<REPO>,
  >(args: {
    repo: REPO;
    filter: string | WhereFilter<T>;
  }) {
    const anchor = new SubscriptionAnchor({
      repo: args.repo,
      filter: args.filter,
      startSub: (anchor) => this.registerAnchor(anchor),
      endSub: (anchor) => this.releaseAnchor(anchor),
    })
  }

  protected registerAnchor(anchor: SubscriptionAnchor) {
    if (this.repoSubscriptions.has(anchor.repo) === false) {
      this.repoSubscriptions.set(anchor.repo, new Map());
    }
    const repoSubs = this.repoSubscriptions.get(anchor.repo)!;
    const subId = anchor.filterString;
    if (repoSubs.has(subId) === false) {
      repoSubs.set(subId, new Map());
      this.startSubscription(subId);
    }
    repoSubs.get(subId)!.set(anchor);
  }

  protected releaseAnchor(anchor: SubscriptionAnchor) {

  }

  protected openSocketPromise: Promise<WebSocket>;
  protected async getOpenSocket() {
    if (!this.openSocketPromise) {
      this.openSocketPromise = new Promise((resolve) => {
        const ws = new WebSocket(this.args.wsUrl);
        ws.addEventListener("open", () => {
          resolve(ws);
        });
        ws.addEventListener("message", (msg) => {
          try {
            const data = JSON.parse(String(msg.data));
            if ("subscriberId" in data) {
              this._subscriberId = data.subscriptionId;
            }
            console.log(data);
          } catch(err) {
            console.error(err, msg);
          }
        })
      });
    }
    return this.openSocketPromise;
  }

  protected async startSubscription(sub: string | object) {
    const ws = await this.getOpenSocket();
    ws.send(JSON.stringify({ startSub: sub }));
  };
  // protected abstract endSubscription(sub: any): void;
}


export class SubscriptionAnchor extends AnchorNode {
  constructor(protected args: {
    repo: SourceRepo<any, any, any>;
    filter: string | object;
    startSub: (anchor: SubscriptionAnchor) => any;
    endSub: (anchor: SubscriptionAnchor) => any;
  }) {
    super();
    this.args.startSub(this);
  }

  public get repo() { return this.args.repo; }
  public get filterString() {
    if (typeof this.args.filter === "string") {
      return this.args.filter;
    }
    return JSON.stringify(this.args.filter);
  }

  public setStatus(status: AnchorStatus, notifyChildren?: boolean) {
    const out = super.setStatus(status, notifyChildren);
    if (status === AnchorStatus.FROZEN) {
      this.args.startSub(this);
    } else {
      this.args.endSub(this);
    }
    return out;
  }

  public anchorToScope() {
    DerivationManager._getCurrentDeriver().addChild(this);
  }
}


const subHub = new ClientSubscriptionHub({ wsUrl: "ws://localhost:3000/live-sync" })


// const subHub = new ClientSubscriptionHub<{ws: WebSocket}>({
//   assertSocketOpen: async (subStore) => {
//     subStore.ws = new WebSocket("ws://localhost:3000/live-sync");
//     return true;
//   },
//   startSubscription: null as any,
//   endSubscription: null as any,
// })
