import { span } from "helium-ui";
import { App } from "./AppState";

export const IconTypeList = [
	"button-block-grid",
	"external-link",
	"gear",
	"graph-nodes",
	"info",
	"map-pin",
	"megaphone",
	"multi-lang",
	"pencil",
	"right-arrow",
	"right-arrow-in-circle",
	"shield-user",
	"trash",
	"user-front-profile",
] as const;

export type IconType = typeof IconTypeList[number];


export class IconState {
	public getIconUrl(icon: IconType) {
		return `${this.assetsUrl}/img/icon/${icon}.svg`;
	}

	public getIconSpan(icon: IconType) {
		return span("StandardIcon", {
			style: {
				backgroundImage: `url(${this.getIconUrl(icon)})`
			}
		})
	}

	public get assetsUrl() {
		return `${App.api.hostMode === "live" ? "/dev" : ""}/assets`;
	}
}
