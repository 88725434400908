import "./tagExplorer.scss";

import { div, Sourcify } from "helium-ui";
import { App } from "MapApp/state/AppState";
import { TagEntity } from "dot-earth-client";
import { ConnectionState } from "MapApp/state/ConnectionState";
import { MapExploreConnectionState } from "../MapExplorePage/MapExploreConnectionState";
import { inLang } from "../InLang/InLang";

const localState = Sourcify({
	showMore: false
})


const topLevelTags = [
	"tag-2",  //Equity,TRUE,#ffbe40,https://boulder.earth/wp-content/uploads/2019/10/equity-button.pngg
	"tag-10",  //Circular Economy,TRUE,#377085,https://boulder.earth/wp-content/uploads/2019/10/circular-materials-economy.png
	"tag-11",  //Resilience,TRUE,#58ccfa,https://boulder.earth/wp-content/uploads/2019/10/resilience-1.png
	"tag-12",  //Land Use,TRUE,#ad8a50,https://boulder.earth/wp-content/uploads/2019/10/land-use-1.png
	"tag-15",  //Finance,TRUE,#af88d7,https://boulder.earth/wp-content/uploads/2019/10/finance-1.pngg
	"tag-16",  //Ecosystems,TRUE,#b9e164,https://boulder.earth/wp-content/uploads/2019/10/ecosystems-1.pngg
	"tag-17",  //Energy Systems,TRUE,#f05846,h
]

function topLevel(tag: TagEntity) { return topLevelTags.includes(tag.id)};

export function renderTagExplorer(args: {
	connections: MapExploreConnectionState
}) {
	const txt = App.txt.tagExplorer;
	const { connections } = args;
	return div("TagExplorer", [
		// list all tags
		// select multiple
		// show more tags
		// reorder results
		div("TagList", {
			ddxClass: () => connections.filter.tag ? "--hasSelection" : "",
		},() => {
			const tags = App.ents.tag.getAllPhased();
			if (tags === "PENDING") { return "Loading tags..."; }
			if ("error" in tags) { throw tags; }
			const out = tags
			.sort((a, b) => (topLevel(a) && !topLevel(b) && -1) || 0)
			.map((tag) => {
				if (!topLevel(tag) && !localState.showMore) { return; }
				return div("Tag", {
					onPush: () => {
						connections.filter.tag = tag;
						// if (connections.filter.tag === tag) {
						// 	connections.filter.tag = undefined;
						// } else {
						// }
					},
					ddxClass: () => connections.filter.tag === tag ? "--selected" : "",
					innards: () => inLang(tag.name),
				})
			});
			out.unshift(div("Tag showAll", {
				onPush: () => connections.filter.tag = undefined,
				ddxClass: () => !connections.filter.tag ? "--selected": "",
				innards: txt.btn.clearSelection
			}))
			return out;
		}),
		div("ShowHide btn margin-auto", {
			ddxClass: () => {
				const tag = connections.filter.tag;
				if (tag && !topLevel(tag) && !localState.showMore) {
					return "--hidingSelection";
				}
				return "";
			},
			onPush: () => localState.showMore = !localState.showMore,
			innards: () => localState.showMore ? txt.btn.showLess() : txt.btn.showMore()
		})
	]);
}
