import { div } from "helium-ui";
import { App } from "MapApp/state/AppState";


export function renderLangChooser() {
	return div("btn LangChooser", {
		onPush: () => {
			const text = App.textContent;
			if (text.lang === "en_US") {
				text.setLang("es");
			} else {
				text.setLang("en_US");
			}
		},
		innards: () => App.textContent.lang,
	})
}
