import "./sliderToggle.scss";

import { div, IElProps, onPush, Source, span } from "helium-ui";


export interface SliderToggleArgs {
	storage: {
		get: () => boolean;
		set: (val: boolean) => any;
	};
	label?: () => HTMLElement | string;
	props?: IElProps
}

export function renderSliderToggle(args: SliderToggleArgs) {
	let out = div("SliderToggle", {
		ddxClass: () => isActive() ? "--active" : "",
		innards: [
			div("Nub")
		]
	});

	out = !args.label ? out : div("SliderToggleContainer", [
		span("SliderToggleLabel", args.label),
		out
	]);

	onPush(out, () => {
		toggle();
		return null as any;
	})

	return out;

	function isActive() {
		return args.storage.get();
	}

	function toggle() {
		args.storage.set(!args.storage.get());
	}
}
