import { ILocationUse, ILocationUseArgs, LanguageCode } from "dot-earth-middleware";
import { jit, SourceRepo, SourceRepoController } from "helium-source-repo";
import { SourceRepoControllersManager, OneToOneJoin } from "helium-source-repo";
import { DotEarthClient } from "../Client";
import { repoApiArgs } from "./EntityCommon";


export class LocationUseManager<TYPE extends LocationUseEntity = LocationUseEntity> extends SourceRepoControllersManager<TYPE> {
	protected getDefaultArgs() {
		return {
			repo: new SourceRepo<ILocationUse, "id", ILocationUseArgs>({
				repoId: "LocationUse",
				idPropName: "id",
				...repoApiArgs("location-use"),
			}),
			createController: (data) => new LocationUseEntity(data),
		} as any;
	}
}


export class LocationUseEntity
	extends SourceRepoController<ILocationUse, "id">
	implements Record<keyof ILocationUse, any>
{
	public _noHeliumProxy = true;

	protected get lang(): LanguageCode { return "en"; }

	public get id() { return this.getId(); }
	public get updatedAt() { return this.getProp("updatedAt"); }
	public get locationId() { return this.getProp("locationId"); }
	public get type() { return this.getProp("type"); }
	public get targetId() { return this.getProp("targetId"); }
	public get createdAt(){ return this.getProp("createdAt"); }

	public get locEnt() {
		return jit(this, "locEnt", () => new OneToOneJoin(
			() => !this.locationId ? undefined : DotEarthClient.get().ents.loc.get(this.locationId),
			(loc) => {
				if (loc && loc.getId() !== this.locationId) {
					this.stageEdits({locationId: loc.getId()})
				}
			}
		))
	}

	// public get loc() { return !this.locationId ? undefined : DotEarthClient.get().ents.loc.get(this.locationId); }
}
