import { ActionEntity, TagEntity } from "dot-earth-client";
import { DerivationAnchor } from "helium-sdx";
import { isPhaseResponse } from "helium-source-repo";
import { Source, SourceMap } from "helium-ui";
import { Sourcify, noDeps, deriveAnchorless, derive } from "helium-ui";
import { matchesRequirements } from "../../../../../helium/helium-source-repo/src/Utils/match";
import { LocationEntity } from "./entities/Location";
import { OrganizationEntity } from "./entities/Organization";
import { App } from "./AppState";


// export interface IConnectionStateArgs<T> {
// 	getItemsDdx?: () => T[];
// 	anchor?: DerivationAnchor;
// }


export class ConnectionState<
	T,
	FLTR extends object = {},
	IT_STATE extends {
		connected?: boolean;
		selected?: boolean;
	} = {
		connected?: boolean;
		selected?: boolean;
	}
> {
	public _focusedItem = new Source<T | undefined>();
	public itemStates = new SourceMap<T, Partial<IT_STATE>>();

	public filter = Sourcify<Partial<FLTR>>({});

	// public readonly state = Sourcify<{
	// 	mode: "tag-net" | "collaboration" | "org-locations";
	// 	outOfWeb: "small" | "hide";
	// 	focusedOrg?: OrganizationEntity;
	// 	focusedTag?: TagEntity;
	// 	focusedAction?: ActionEntity;
	// 	focusedLocation?: LocationEntity;
	// 	web: {[key: string]: boolean};
	// }>({
	// 	outOfWeb: "hide",
	// 	mode: "tag-net",
	// 	web: {}
	// });


	constructor(args?: {
		getItemsDdx: () => T[];
		deriveConnections?: "ALL_ITEMS" | ((connectionState: ConnectionState<T, FLTR>) => null | T[]);
	}) {
		// this.setupFocusLimitsDeriver();
		// this.setupWebModeDeriver();
		args && deriveAnchorless(() => {
			const items = args.getItemsDdx();
			noDeps(() => {
				const shouldHave = new Map<T, void>();
				items.forEach((it) => shouldHave.set(it));
				this.items.forEach((it) => shouldHave.has(it) === false && this.itemStates.delete(it))
				items.forEach((it) => this.itemStates.has(it) || this.itemStates.set(it, {}));
				if (this.focus && shouldHave.has(this.focus) === false) {
					this.focus = undefined;
				}
			});
		})
		if(args && args.deriveConnections) {
			const { deriveConnections } = args;
			if (deriveConnections === "ALL_ITEMS") {
				deriveAnchorless(() => {
					this.items.forEach((it) => this.setConnected(it, true));
				});
			} else {
				deriveAnchorless(() => deriveConnections!(this));
			}
		}
	}

	public upsertItemState(item: T, state: Partial<IT_STATE>) {
		noDeps(() => {
			const current = this.itemStates.get(item);
			if (matchesRequirements(current, state)) { return }
			this.itemStates.set(item, {
				...current,
				...state
			});
		})
	}

	public isConnected(item: T) {
		return this.itemStates.get(item) && this.itemStates.get(item).connected;
	}

	public setConnected(item: T, connected: boolean) {
		this.upsertItemState(item, { connected } as any)
	}

	public get connectedItems() {
		return this.items.filter((it) => this.isConnected(it));
	}

	public isSelected(item: T) {
		return this.itemStates.get(item) && this.itemStates.get(item)!.selected;
	}

	public setSelected(item: T, selected: boolean) {
		this.upsertItemState(item, { selected } as any)
	}

	public get items() { return this.itemStates.keys(); }

	public get focus() { return this._focusedItem.get(); }
	public set focus(item) { this._focusedItem.set(item); }

	// public get focusedOrg() { return this.state.focusedOrg; }
	// public set focusedOrg(val) { this.state.focusedOrg = val; }

	// public get focusedTag() { return this.state.focusedTag; }
	// public set focusedTag(val) { this.state.focusedTag = val; }

	// public get focusedAction() { return this.state.focusedAction; }
	// public set focusedAction(val) { this.state.focusedAction = val; }

	// public get focusedLocation() { return this.state.focusedLocation; }
	// public set focusedLocation(val) { this.state.focusedLocation = val; }

	// // public get focusedAction() { return (this.focusedCollab && "action" in this.focusedCollab) ? this.focusedCollab.action : undefined; }
	// // public set focusedAction(val) { this.focusedCollab = Sourcify({action: val}); }

	// public get web() { return this.state.web; }
	// public set web(val) { this.state.web = val; }


	// protected setupFocusLimitsDeriver() {
	// 	let lastTag = noDeps(() => this.focusedTag);
	// 	let lastAction = noDeps(() => this.focusedAction);

	// 	deriveAnchorless(() => {
	// 		const mode = this.state.mode;
	// 		const { focusedOrg } = this;
	// 		if (mode === "tag-net") {
	// 			if (
	// 				focusedOrg && this.focusedTag
	// 				&& focusedOrg.hasTag(this.focusedTag) === false
	// 			) {
	// 				const tagChanged = lastTag !== this.focusedTag;
	// 				if (tagChanged) {
	// 					this.focusedOrg = undefined;
	// 				} else {
	// 					this.focusedTag = undefined;
	// 				}
	// 			}
	// 			lastTag = this.focusedTag;

	// 		} else if (mode === "collaboration") {
	// 			if (
	// 				focusedOrg && this.focusedAction
	// 				&& focusedOrg.hasActionRole(this.focusedAction, "member") === false
	// 			) {
	// 				const actionChanged = lastAction !== this.focusedAction;
	// 				if (actionChanged) {
	// 					this.focusedOrg = undefined;
	// 				} else {
	// 					this.focusedAction = undefined;
	// 				}
	// 			}
	// 			lastAction = this.focusedAction;
	// 		}
	// 	});
	// }

	// protected setupWebModeDeriver() {
	// 	// change mode
	// 	deriveAnchorless(() => {
	// 		if (App.route.id === "action-details") {
	// 			this.state.mode = "collaboration";
	// 			this.state.outOfWeb = "hide";
	// 			// this.state.outOfWeb = "small";

	// 		} else if (App.route.id === "map.actions") {
	// 			this.state.mode = "collaboration";
	// 			this.state.outOfWeb = "small";

	// 		// } else if (App.route.id === "org-details") {
	// 		// 	this.state.mode = "org-locations";
	// 		// 	this.state.outOfWeb = "hide";

	// 		} else {
	// 			this.state.mode = "tag-net";
	// 			this.state.outOfWeb = "small";
	// 		}
	// 	});

	// 	// display mode
	// 	deriveAnchorless(() => {
	// 		switch(this.state.mode) {
	// 			case "tag-net": return this.updateWebForTagNet();
	// 			case "collaboration": return this.updateWebForCollab();
	// 			case "org-locations": return this.updateWebForOrgLocations();
	// 		}
	// 	});
	// }

	// protected updateWebForTagNet() {
	// 	const focusedTag = this.focusedTag;
	// 	if (!focusedTag) {
	// 		noDeps(() => this.state.set("web", {}));
	// 		return;
	// 	}
	// 	const uses = App.ents.tagUse.find.phased({where: { tagId: focusedTag.id }});
	// 	if (Array.isArray(uses)) {
	// 		noDeps(() => this.state.set("web", {}));
	// 		uses.map((it) => derive(() => {
	// 			const org = it!.targetId && App.ents.org.get(it!.targetId);
	// 			org && noDeps(() => this.web[org.id] = true);
	// 		}));
	// 	}
	// }

	// protected updateWebForCollab() {
	// 	const action = this.state.focusedAction;
	// 	if (!action) {
	// 		noDeps(() => this.state.set("web", {}));
	// 		return;
	// 	}
	// 	const members = action.memberRoles.getAllPhased();
	// 	if (isPhaseResponse(members)) { return; }
	// 	noDeps(() => this.state.set("web", {}));
	// 	members.forEach((member) => derive(() => {
	// 		const org = member.holderId && App.ents.org.get(member.holderId);
	// 		org && noDeps(() => this.web[org.id] = true);
	// 	}))
	// 	// action.organizations.forEach((orgName) => {
	// 	// 	const poi = App.ents.poi.find.one({ where: { name: orgName }});
	// 	// 	if (!poi || !poi.id) { return; }
	// 	// 	noDeps(() => this.web[poi.id] = true);
	// 	// });
	// 	// if ("action" in collab) {
	// 	// 	const action = App.ents.action.get(collab.action);
	// 	// 	if (!action) { return; }
	// 	// 	// action.organizations.forEach((orgName) => {
	// 	// 	// 	const poi = App.ents.poi.find.one({ where: { name: orgName }});
	// 	// 	// 	if (!poi || !poi.id) { return; }
	// 	// 	// 	noDeps(() => this.web[poi.id] = true);
	// 	// 	// });
	// 	// }
	// }

	// protected updateWebForOrgLocations() {
	// 	const focusedOrg = this.focusedOrg;
	// 	if (!focusedOrg) {
	// 		noDeps(() => this.state.set("web", {}));
	// 		return;
	// 	}
	// 	const locUses = focusedOrg.locUses.getAllPhased();
	// 	if (Array.isArray(locUses)) {
	// 		noDeps(() => this.state.set("web", {}));
	// 		locUses.map((it) => derive(() => {
	// 			const loc = it.locEnt.get();
	// 			loc && noDeps(() => this.web[loc.id] = true);
	// 		}));
	// 	}
	// }
}
