import { Source, derive, noDeps } from "helium-sdx";
import { SourceRepoController } from "../Controllers";
import { EditContext, IEditContextArgs } from "./EditContext";

export type EditablePrimitiveArgs<T> = IEditContextArgs & {
	getUnmodifiedValue: () => T | undefined;
	storage?: Source<T | undefined> | {
		get: () => T | undefined;
		set: (val: T | undefined) => any;
	} | {
		ent: () => SourceRepoController<any, any>,
		propPath: string,
	};
	save?: () => Promise<boolean>
}



export class EditablePrimitive<T> extends EditContext<T> {

	public save: undefined | (() => Promise<boolean>);

	constructor(protected editCtxArgs: EditablePrimitiveArgs<T>) {
		super(editCtxArgs);
		editCtxArgs.storage = editCtxArgs.storage || new Source();
		this.currentValue = noDeps(() => this.unmodifiedValue);
		derive({
			anchor: editCtxArgs.ddxAnchor,
			fn: () => {
				if (this.editing) {
					this.currentValue = noDeps(() => this.unmodifiedValue);
				}
			}
		});
		this.save = editCtxArgs.save;
	}

	public get unmodifiedValue() {
		return this.editCtxArgs.getUnmodifiedValue();
	}

	public get currentValue() {
		const storage = this.editCtxArgs.storage!
		if ("ent" in storage) {
			const pathProps = storage.propPath.split(".");
			let ptr = storage.ent().getProp(pathProps[0]);
			for (let i = 1; i < pathProps.length; i++) {
				ptr = ptr[pathProps[i]];
			}
			return ptr;
		}
		return storage.get();
	}
	public set currentValue(val) {
		const storage = this.editCtxArgs.storage!
		if ("ent" in storage) {
			const pathProps = storage.propPath.split(".");
			let edits = val;
			for (let i = pathProps.length - 1; i >= 0; i--) {
				edits = { [pathProps[i]]: edits }
			}
			if (val === undefined) {
				storage.ent().unstageEdits(edits);
			} else {
				storage.ent().stageEdits(edits);
			}
		}
		"set" in storage && storage.set(val);
	}

	public updateHasEdits() {
		this.hasEdits = (this.editing && this.currentValue !== this.unmodifiedValue);
	}


	public _cancelEdits() {
		if (noDeps(() => this.hasEdits === false)) {
			console.warn("has no edits to cancel");
			return true;
		}
		const oldValue = noDeps(() => this.unmodifiedValue);
		this.currentValue = oldValue;
		// if (this.editLock === "ON" || oldValue) {
		// }
		console.log(oldValue, this.currentValue);
		this.editing = false;
		return true;
	}
}
