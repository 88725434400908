import { SourceMap, noDeps, DerivationManager, DeriverScope, SourceArray } from "helium-sdx";
import { ClientSubscriptionHub } from "../LiveSocket";
import {  IdType } from '../SelectionArgs';
import { CacheUse, ISelectionLogic } from "../SelectionLogicChain";
import { ISelectionFilter, matchesFilter } from "../Selections/SelectionFilter";
import { createIdFromObject } from "../Utils/createIdFromObject";
import { FilterCollection } from "./FilterCollection";



// export type SplitOrBundledAsync<OUT> = Promise<

export type GetIdListFn<T extends object, ID_TYPE = string> = (
	( filter: ISelectionFilter<T>,
		args?: {
			withDate?: boolean;
			splitCalls?: boolean;
		}
	) => Promise<[ID_TYPE, Date][] | ID_TYPE[]> | Array<Promise<ID_TYPE | [ID_TYPE, Date]>>
);

export type GetItemsFn<T extends object, ID extends keyof T> =(
	( ids: IdType<T, ID>[],
		args?: {
			splitCalls?: boolean;
		}
	) => Promise<T[]>
);

export type CreateItemsFn<T extends object, T_ARGS extends Partial<T>> = (items: T_ARGS[]) => Promise<T[]>;
export type SyncItemsFn<T extends object> = (items: T[]) => Promise<T[]>;
export type DeleteIdsFn<ID_TYPE> = (ids: ID_TYPE[]) => Promise<{deleted: ID_TYPE[]}>;


// export type UpsertItemsFn<
// 	T extends object,
// 	ID extends keyof T,
// 	T_ARGS extends Partial<T>,
// > = (
// 	(	items: Array<T_ARGS | Identifiable<T, ID>>,
// 		args: {
// 			create: UpsertCreationMode,
// 			subscriberId?: string,
// 		}
// 	) => Promise<Array<T | ["error", any]>>
// );


export type SmartFetchFilterArg<T extends object, ID extends keyof T> = {
	mode: "id-only";
	fn: (ids: IdType<T, ID>[]) => IdType<T, ID>[];
} | {
	mode: "id-and-mod-date";
	fn: (ids: [IdType<T, ID>, Date][]) => IdType<T, ID>[];
} | {
	mode: "last-modified-check";
	lastModifiedPropName: string;
}


export interface IRepoDataSourceArgs<
	T extends object,
	ID extends keyof T,
	T_ARGS extends Partial<T>,
> {
	repoId: string;
	idPropName: ID;
	getIdList: string | GetIdListFn<T, IdType<T, ID>>;
	getItems: string | GetItemsFn<T, ID>;
	createItems?: string | CreateItemsFn<T, T_ARGS>;
	syncItemsToServer?: string | SyncItemsFn<Partial<T>>;
	cacheUseDefault?: CacheUse;
	deleteIds?: string | DeleteIdsFn<IdType<T, ID>>;
	// upsertItems?: string | UpsertItemsFn<T, ID, T_ARGS>;
	smartFetchFilter?: SmartFetchFilterArg<T, ID>;
	subscriptionHub?: ClientSubscriptionHub;
	batchingMs?: number;
}


const GLOBAL: {
	HeliumSourceRepoDataSource: {
		cacheUseDefault: CacheUse;
		batchingTimeoutDefault: number;
		modifyRequest: Array<(repoId: string, headers: RequestInit) => void>;
	}
} = (typeof window !== undefined ? window : global) as any;

GLOBAL.HeliumSourceRepoDataSource = GLOBAL.HeliumSourceRepoDataSource || {
	cacheUseDefault: { maxAge: 350 },
	batchingTimeoutDefault: 50,
	modifyRequest: []
}

/** Stores all data which is accessed by SourceRepoSlices
 * Handles updates and retrievals
 */
export class RepoDataSource<
	T extends object,
	ID extends keyof T,
	T_ARGS extends Partial<T>,
> {
	public static get GLOBAL_CACHE_USE_DEFAULT() { return GLOBAL.HeliumSourceRepoDataSource.cacheUseDefault; }
	public static set GLOBAL_CACHE_USE_DEFAULT(val) { GLOBAL.HeliumSourceRepoDataSource.cacheUseDefault = val; }

	public static get GLOBAL_BATCHING_TIMEOUT_DEFAULT() { return GLOBAL.HeliumSourceRepoDataSource.batchingTimeoutDefault; }
	public static set GLOBAL_BATCHING_TIMEOUT_DEFAULT(val) { GLOBAL.HeliumSourceRepoDataSource.batchingTimeoutDefault = val; }

	public static get MODIFY_HEADERS() {
		return GLOBAL.HeliumSourceRepoDataSource.modifyRequest;
	}

	protected dataHistory = new Map<IdType<T, ID>, number>();
	protected data = new SourceMap<IdType<T, ID>, T>();

	protected filterCollections = new Map<string, FilterCollection<T>>()

	constructor (protected args: IRepoDataSourceArgs<T, ID, T_ARGS>) {
		this._batchingMs = args.batchingMs;
	}


	public get idPropName() { return this.args.idPropName || ("id" as any); }
	public get cacheUseDefault() { return this.args.cacheUseDefault || RepoDataSource.GLOBAL_CACHE_USE_DEFAULT; }
	public get repoId() { return this.args.repoId; }


	protected _batchingMs: number | undefined;
	public set batchingTimeoutDefault(ms: number) { this._batchingMs = ms; }
	public get batchingTimeoutDefault() { return this._batchingMs || RepoDataSource.GLOBAL_BATCHING_TIMEOUT_DEFAULT; }


	public setItems(items: T[]) {
		items.forEach((it) => this.setItem(it));
	}

	public setItem(item: T) {
		this.upsertItem(item, "NO_MERGE");
	}

	public upsertItem(item: T, mode: "NO_MERGE"): void;
	public upsertItem(item: Partial<T>): void;
	public upsertItem(item: T | Partial<T>, mode?: "NO_MERGE") {
		const id = item && item[this.idPropName];
		if (!id) {
			throw new Error(`Can not upsertItem to dataSource which has no id: ${JSON.stringify(item)}`);
		}

		this.dataHistory.set(id, Date.now());
		let existing = this.data.peek(id);
		if (existing) {
			const existingKeys = Object.keys(existing);
			if (existingKeys.length === Object.keys(item).length) {
				const same = existingKeys.every((key) => existing[key] === item[key]);
				if (same) { return; }
			}
		}
		const fullItem: T = mode === "NO_MERGE" ? (item as T) : {
			...existing,
			...item
		}
		this.data.set(id, fullItem);

		for (const collection of this.filterCollections.values()) {
			collection.tryUpsertItem(fullItem);
		}
	}

	public removeItem(id: IdType<T, ID>) {
		this.data.delete(id);
		for (const collection of this.filterCollections.values()) {
			collection.removeItemById(id);
		}
	}

	public getItemsByIds(ids: IdType<T, ID>[]) {
		return ids.map((id) => this.data.get(id));
	}


	// will return whatever its it has, does not run api calls
	public getItems(
		filter?: ISelectionFilter<T>,
		// args: {
		// 	noDeps?: boolean
		// } = {}
	) {
		if (filter && filter.where && this.idPropName in filter.where) {
			return [this.data.get(filter.where[this.idPropName])];
		}
		const getItems = () => {
			const allItems = this.data.keys().map((key) => this.data.get(key));
			if (!filter) {
				return allItems;
			}

			let count = 0;
			let limit = (filter.limit && filter.limit > 0) ? filter.limit : allItems.length;
			const items = [] as T[];
			for (let i = 0; i < allItems.length && count < limit; i++) {
				const item = allItems[i];
				if (matchesFilter(item, filter)) {
					items.push(item);
				}
			}
			return items;
		}

		const scope = DerivationManager._getCurrentDeriver();
		if (!scope) { return getItems(); }

		const filterId = createIdFromObject(filter || {});
		let collection = this.filterCollections.get(filterId);
		if (!collection) {
			this.filterCollections.set(filterId, collection = new FilterCollection({
				filterId,
				filter,
				startItems: noDeps(() => getItems()),
				deps: [scope],
				getId: (it) => it[this.idPropName],
			}));
		} else {
			collection.addDep(scope);
		}
		return collection.list.toObject();
	}


	protected syncHistory = new Map<string, {
		time: number;
		promise: Promise<void>
	}>()

	// default function used for getting an idList to match a filter
	public async syncFromServer(
		filter: ISelectionFilter<T>,
		args: Partial<ISelectionLogic> = {}
	) {
		let { cacheUse } = args;
		if (cacheUse === "default") {
			cacheUse = this.cacheUseDefault;
		}
		if (cacheUse === "only") { return; }
		if (cacheUse === "if-any" || cacheUse === "if-items") {
			const existing = this.getItems({
				...filter,
				limit: 1
			});
			if (existing.length) { return; }
			cacheUse = this.cacheUseDefault;
		}

		const syncId = createIdFromObject(filter);
		let history = this.syncHistory.get(syncId);
		if (history) {
			if (cacheUse === "if-any" || cacheUse === "if-query") { return; }
			if (typeof cacheUse === "object") {
				const age = Date.now() - history.time;
				if (cacheUse.maxAge > age) { return; }
			}
		} else {
			this.syncHistory.set(syncId, history = {} as any)
		}
		if (!history) { throw "ts issue"; }

		history.time = Date.now();
		return history.promise = this.runItemFetch(args, async () => {
			// this function returns a list of ids and optionally dates
			if ("limit" in filter && filter.limit! < 1) {
				delete filter.limit;
			}

			if (filter.where && filter.where[this.idPropName]) {
				return [filter.where[this.idPropName]];  // TODO: what if where includes more than an ID?
			}

			if (args.cacheUse === "if-fresh") {
				throw new Error(`Cache use "if-fresh" is not yet implemented`);
				// const smartFilter = this.args.smartFetchFilter;
				// if (typeof smartFilter === "object" && smartFilter.mode === "id-and-mod-date") {
				// 	const out = await this.getIdListFromServer(filter, {
				// 		withDate: true
				// 	});
				// 	if (out.length && Array.isArray(out.length[0]) === false) {
				// 		throw new Error(`getIdList(ids, { withDate: true }) returned no dates with ids`);
				// 	}
				// 	return out;
				// }
			}

			const out = await this.getIdListFromServer(filter);
			if (out.length && Array.isArray(out.length[0])) {
				throw new Error(`getIdList(ids, { withDate: false }) returned with dates`);
			}
			return out;
		});
	}


	// Reused batching properties for runItemFetch
	protected activeIdPulls = new Map<IdType<T, ID>, void>();
	protected activePullPromise: Promise<void> | undefined;

	// typically used by syncFromServer, but separated so users can supply their own id lists
	public async runItemFetch(
		args: Partial<ISelectionLogic> = {},
		getIdList: () => Promise<Array<IdType<T, ID> | [IdType<T, ID>, Date]>>,
	) {
		// typically idList is retrieved from server
		let itemList = await getIdList();
		let idList: IdType<T, ID>[];
		const isDated = Array.isArray(itemList[0]);

		// never update items already waiting for update
		itemList = itemList.filter((item) => {
			const id = isDated ? item[0] : item;
			return this.activeIdPulls.has(id) === false;
		});

		let { cacheUse } = args;
		if (cacheUse === "default") { cacheUse = this.cacheUseDefault; }
		let wasSmart = false;
		if (cacheUse === "if-fresh") {
			wasSmart = true;
			throw new Error(`Can not use if-fresh yet`);

		// 	const smartFilter = this.args.smartFetchFilter;
		// 	if (!smartFilter) {
		// 		fetchMode = "lazy";

		// 	} else if (typeof smartFilter === "string") {
		// 		fetchMode = smartFilter;

		// 	} else if (smartFilter.mode === "last-modified-check") {
		// 		const propName = smartFilter.lastModifiedPropName;
		// 		idList = itemList.map(([id, date]: [IdType<T, ID>, Date]) => {
		// 			const item = this.data.get(id);
		// 			if (!item) { return id; }
		// 			const lastModified = item[propName];
		// 			if (lastModified < date) {
		// 				return id;
		// 			}
		// 			return null;
		// 		}).filter(it => !!it);

		// 	} else {
		// 		if (isDated !== (smartFilter.mode === "id-and-mod-date")) {
		// 			throw new Error(`getIdList() returned wrong type for smartFetchFilter mode`);
		// 		}
		// 		idList = smartFilter.fn(itemList as any);
		// 	}
		}
		// idList = idList || (isDated ? itemList.map((it) => it[0]) : itemList);

		idList = isDated ? itemList.map((it) => it[0]) : itemList;
		if (cacheUse === "only") {
			if (!wasSmart) { throw new Error(`This function should have been avoided sooner`); }
			return;

		} else if (cacheUse === "ignore" || cacheUse === "if-any" || cacheUse === "if-query" || cacheUse === "if-items") {
			// no items will be skipped

		} else if (typeof cacheUse === "object") {
			const { maxAge } = cacheUse;
			if (maxAge <= -1) {
				// don't refresh any items which have a cached version
				idList = idList.filter((id) => this.data.peekHas(id) === false);
			} else {
				idList = idList.filter((id) => {
					const lastFetch = this.dataHistory.get(id);
					const tooOld = !lastFetch || (Date.now() - lastFetch > maxAge)
					return tooOld;
				});
			}

		}
		// else if (!wasSmart) {
		// 	throw new Error(`Unknown fetch mode "${fetchMode}"`);
		// }

		if (idList.length === 0) {
			return;
		}

		idList.forEach((id) => this.activeIdPulls.set(id));
		if (this.activePullPromise) {
			return this.activePullPromise;
		}

		return this.activePullPromise = new Promise((res, rej) => {
			setTimeout(async () => {
				const ids = Array.from(this.activeIdPulls.keys());
				this.activeIdPulls = new Map();
				this.activePullPromise = undefined;
				try {
					const items = await this.getItemsFromServer(ids);
					this.setItems(items);
					res();

				} catch (err) {
					rej(err);
				}
			}, this.batchingTimeoutDefault)
		});
	}


	// --------------------------------------------------------------------

	public async syncToServer(args: Partial<T>[] | {
		create: T_ARGS[],
	}) {
		let items: Partial<T>[];
		if ("create" in args) {
			items = await this.createItemsOnServer(args.create);
		} else {
			items = await this.syncItemsToServer(args);
		}
		items.forEach((it) => this.upsertItem(it));
		return items;
	}





	public async delete(id: IdType<T, ID>): Promise<"DELETED"> {
		const resp = await this.deleteIdsFromServer([id]);
		if ("deleted" in resp && resp.deleted.includes(id)) {
			this.removeItem(id);
			return "DELETED";
		}
		throw new Error(`Unable to delete`);
	}










	// --------------------------------------------------------------------



	protected async post(url: string, body: object) {
		const args: RequestInit = {
			method: "POST",
			headers: { 'Content-Type': 'application/json;charset=utf-8' },
			body: JSON.stringify(body)
		};
		RepoDataSource.MODIFY_HEADERS.forEach((fn) => fn(this.repoId, args));
		const resp = await fetch(url, args);
		return resp.json();
	}


	// when an id list is asked for from server, this is how the request is sent
	protected getIdListFromServer: GetIdListFn<T, ID> = (async (filter, args) => {
		const arg = this.args.getIdList;
		return typeof arg === "function" ? arg(filter, args) : this.post(arg, filter);
	});

	protected getItemsFromServer: GetItemsFn<T, ID> = (async (ids) => {
		const arg = this.args.getItems;
		return typeof arg === "function" ? arg(ids) : this.post(arg, ids);
	});



	protected createItemsOnServer: CreateItemsFn<T, T_ARGS> = async (items) => {
		const arg = this.args.createItems;
		if (!arg) {
			throw new Error(`Property "createItems" must be defined in constructor for Repo "${this.repoId}"`);
		}
		return typeof arg === "function" ? arg(items) : this.post(arg, items);
	}

	protected syncItemsToServer: SyncItemsFn<Partial<T>> = async (items) => {
		const arg = this.args.syncItemsToServer;
		if (!arg) { throw new Error(`Property "syncItemsToServer" must be defined in constructor for Repo "${this.repoId}"`) }
		return typeof arg === "function" ? arg(items) : this.post(arg, items);
	}


	protected deleteIdsFromServer: DeleteIdsFn<IdType<T, ID>> = async (ids) => {
		const arg = this.args.deleteIds;
		if (!arg) { throw new Error(`Property "deleteIds" must be defined in constructor for Repo "${this.repoId}"`) }
		return typeof arg === "function" ? arg(ids) : this.post(arg, ids);
	}

	// public async syncToServer<
	// 	IT extends (Identifiable<T, ID> | T_ARGS)
	// >(items: IT[], logic: IUpsertLogicArgs) {
	// 	// if (logic.cacheOnly) {
	// 	// 	throw new Error(`This function should never have been called`);
	// 	// }
	// 	// const { upsertItems } = this.args;
	// 	// if (!upsertItems) {
	// 	// 	throw new Error(`upsertItems() was not given as a constructor argument for the repo "${this.args.repoId}"`);
	// 	// }
	// 	// const idList = items.map((it) => it[this.idPropName]).filter((it) => !!it);
	// 	// const releaseIds = () => idList.forEach((id) => this.activeIdPulls.delete(id));
	// 	// try {
	// 	// 	idList.forEach((id) => this.activeIdPulls.set(id));
	// 	// 	let subscriberId: string;
	// 	// 	const subHub = this.args.subscriptionHub;
	// 	// 	if (subHub) {
	// 	// 		subscriberId = subHub.subscriberId;
	// 	// 	}
	// 	// 	const out = await this.upsertItemsToServer(items as any, { create: logic.creation, subscriberId });
	// 	// 	this.addItems(out.map((it) => Array.isArray(it) ? null : it));
	// 	// 	releaseIds();
	// 	// 	return out;

	// 	// } catch (err) {
	// 	// 	releaseIds();
	// 	// 	throw err;
	// 	// }
	// }

	// protected upsertItemsToServer: UpsertItemsFn<T, ID, T_ARGS> = async (items, args) => {
	// 	throw "TODO";
	// 	return null as any;
	// 	// const arg = this.args.upsertItems;
	// 	// if (typeof arg === "function") {
	// 	// 	return arg(items, args);
	// 	// }
	// 	// const response = await fetch(arg, {
	// 	// 	method: 'POST',
	// 	// 	headers: { 'Content-Type': 'application/json;charset=utf-8' },
	// 	// 	body: JSON.stringify(items)
	// 	// });
	// 	// return response.json();
	// }
}
