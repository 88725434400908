export const AsciiName = String.raw`
 _____     ______    ______
/\  __-.  /\  __ \  /\__  _\
\ \ \/\ \ \ \ \/\ \ \/_/\ \/
 \ \____-  \ \_____\   \ \_\
  \/____/   \/_____/    \/_/
      ______     ______     ______    ______   __  __
     /\  ___\   /\  __ \   /\  == \  /\__  _\ /\ \_\ \
     \ \  __\   \ \  __ \  \ \  __<  \/_/\ \/ \ \  __ \
      \ \_____\  \ \_\ \_\  \ \_\ \_\   \ \_\  \ \_\ \_\
       \/_____/   \/_/\/_/   \/_/ /_/    \/_/   \/_/\/_/
`;
